
import React from 'react';
import { withStyles } from '@material-ui/core/styles';
import { MDBContainer, MDBRow, MDBCol } from "mdbreact";
import { MDBModal, MDBModalBody, MDBModalDialog, MDBModalContent } from 'mdb-react-ui-kit';
import IconTextField from '../../../components/SharedComponents/IconTextField/IconTextField';
import AutoCompleteSearchField from '../../../components/SharedComponents/AutoCompleteSearch/AutoCompleteSearch';
import CancelIcon from '@material-ui/icons/Cancel';
import BasicButton from '../../../components/SharedComponents/BasicButton/BasicButton';
import { useStyles } from './Config';
import MessageModal from '../../../components/SharedComponents/MessageModal';
import './Modal.scss';
import { withUserContext } from "../../../../contexts/UserContext";
import NewProduction from '../../../components/Production/NewProduction';
import ProjectNewCrew from '../../../components/Project/ProjectNewCrew'
import NotificationComponent from '../../../Common/NotificationComponent/NotificationComponent'
import AddProjectLocation from '../../../components/Location/AddProjectLocation';
import EditRepresentative from '../../../components/Performers/EditRepresentative';
import EditPerformerContact from '../../../components/Performers/EditPerformerContact';
import PerformerConfirmationModal from '../../../components/Performers/PerformerConfirmationModal';
import EditSendPaymentsTo from '../../../components/Performers/EditSendPaymentsTo';
import BillingNotes from '../../../components/Project/BillingNotes'
import WorkWeekModal from '../../../components/Project/WorkWeekModal';
import StatusDatesModal from '../../../components/Project/StatusDatesModal';
import TalentPerformer from '../../../components/Performers/TalentPerformer';
import ESignManager from '../../../components/Performers/Compensation/ESignManager';
import EditRider from '../../../components/Performers/Compensation/EditRiders';
import EditInfoAndText from '../../../components/Performers/Compensation/EditInfoAndText';
import AddInfoAndText from '../../../components/Performers/Compensation/AddInfoAndText';
import NewUser from '../../../components/NewUser/NewUser';
import LookupValues from '../../../components/LookupValue/LookupValues';
import NewCrewModal from '../../../Common/NewCrew/NewCrew';

class MainViewModalComponent extends React.Component {
    constructor(props) {
        super(props);
        this.state = {
            list: [],
            fieldChanged: false,
            showError: false,
            showSuccess: false,
            showFail: false,
            isPosting: false,
            saveTitle: "Success",
            saveEditMessage: "The changes are saved successfully!",
            failTitle: "Failure",
            failMessage: "Saving changes failed!",
            unsavedTitle: "Unsaved Changes",
            unsavedMessage: "Are you sure you want to discard your changes?",
            saveFlag: false,
            showNotifSuccess: false,
            showNotifFail: false,
            saveNotifFlag: false,

        }
        this.submitButtonRef = React.createRef();
    }

    setPostFlag = (value) => {
        this.setState({ isPosting: value });
    }

    onClose = () => {
        if (this.state.fieldChanged) {
            this.setState({ showError: true });
        } else {
            this.closPopup()
        }
    }
    closPopup = () => {
        this.setState({ showError: false, showFail: false, showSuccess: false });
        //if (this.state.saveFlag) {
        this.props?.handleClose();
        //}
    }
    deletePost = (save_flag) => {
        this.setState({ saveFlag: save_flag });
    }
    handleSubmit = () => {
        //this.submitButtonRef.current.click();
        this.props?.handleSubmit();
    }
    fieldChanged = (value, mode = "error") => {
        console.log("from fieldChanged");
        switch (mode) {
            case "change": return this.setState({ fieldChanged: value });
            case "error": return this.setState({ showError: value });
            case "success": return this.setState({ showSuccess: value });
            case "fail": return this.setState({ showFail: value });
        }
    }
    notificationComponent = (value, mode = "fail") => {
        switch (mode) {
            case "success": return this.setState({ showNotifSuccess: value, saveNotifFlag: true });
            case "fail": return this.setState({ showNotifFail: value });
        }
    }
    handleResetNotify = () => {
        this.setState({ showNotifFail: false, showNotifSuccess: false });
        if (this.state?.saveNotifFlag) {
            this.props?.handleClose();
        }
    }
    getModalContent = () => {
        switch (this.props?.modalName) {
            case 'Project': return <NewProduction
                onClose={this.props?.handleClose}
                primaryButtonText={this.props?.mode === "Edit" ? 'Save' : 'Create'}
                secondaryButtonText={'Cancel'}
                mode={this.props?.mode}
                id={this.props?.id}
                toggleRefreshPage={this.props?.toggleRefreshPage}
                notificationComponent={this.notificationComponent.bind(this)}
                {...this.props}
            />
            case 'Add Project Crew': return <ProjectNewCrew
                onClose={this.props?.handleClose}
                primaryButtonText={this.props?.mode == 'new' ? 'Save' : 'Save'}
                secondaryButtonText={'Cancel'}
                mode={this.props?.mode}
                id={this.props?.id}
                project_id={this.props?.project_id}
                crewSelected={this.props?.crewSelected}
                notificationComponent={this.notificationComponent.bind(this)}
                toggleRefreshPage={this.props?.toggleRefreshPage}
                origin={this.props?.origin}
            />
            case 'Add New Project Crew': return <NewCrewModal
                isDashboard={this.props?.isDashboard}
                id={'simple-popover'}
                open={this.props?.openCrewPopOver}
                setPopOver={this.props?.setCrewPopOver}
                handleClose={this.props?.handleClose}
                crewData={this.props?.selectedCrew}
                getObjectDetails={this.props?.getObjectDetails.bind(this)}
                setObjectSelected={this.props?.setObjectSelected.bind(this)}
                setNewObjectSelected={this.props?.setNewObjectSelected.bind(this)}
                origin={this.props?.origin}
            />
            case 'Add Project Location': return <AddProjectLocation
                onClose={this.props?.handleClose}
                primaryButtonText={this.props?.mode == 'new' ? 'Add' : 'save'}
                secondaryButtonText={'Cancel'}
                mode={this.props?.mode}
                id={this.props?.id}
                project_id={this.props?.project_id}
                notificationComponent={this.notificationComponent.bind(this)}
                locationSelected={this.props?.locationSelected}
                toggleRefreshPage={this.props?.toggleRefreshPage}
            />
            case 'Billing Notes': return <BillingNotes
                {...this.props}
                notificationComponent={this.notificationComponent.bind(this)}
            />
            case 'Work Week': return <WorkWeekModal
                {...this.props}
                onClose={this.onClose}
                fieldChanged={this.fieldChanged}
                notificationComponent={this.notificationComponent.bind(this)}
            />
            case 'Status Date': return <StatusDatesModal
                {...this.props}
                onClose={this.onClose}
                fieldChanged={this.fieldChanged}
                notificationComponent={this.notificationComponent.bind(this)}
            />
            case 'Edit Representative': return <EditRepresentative
                onClose={this.props?.handleClose}
                repId={this.props?.repId}
                dealId={this.props?.dealId}
                mode={'edit'}
                primaryButtonText={'Save'}
                fieldChanged={this.fieldChanged}
                setPostFlag={this.setPostFlag}
                toggleRefreshPage={this.props?.toggleRefreshPage}
                secondaryButtonText={'Cancel'} />

            case 'Edit Performer Contact': return <EditPerformerContact
                talent_name={this.props?.talent_name}
                performerList={this.props?.performerList}
                onClose={this.props?.handleClose}
                guardianList={this.props?.guardianList}
                age={this.props?.age}
                monthsAge={this.props?.monthsAge}
                birth_date={this.props?.birth_date}
                getContactDetails={this.props?.getContactDetails}
                getGuardianDetails={this.props?.getGuardianDetails}
                getTalentPerformerDetails={this.props?.getTalentPerformerDetails}
                getPeformerDetailsFlags={this.props?.getPeformerDetailsFlags}
            />

            case 'Performer Confirmation Modal': return <PerformerConfirmationModal
                confirmationMessage={this.props?.confirmationMessage}
                primaryButtonText={this.props?.primaryButtonText}
                secondaryButtonText={this.props?.secondaryButtonText}
                onClose={this.props?.handleClose}
                updateTalentProfile={this.props?.updateTalentProfile}
                updateRecord={this.props?.updateRecord}
            />
            case 'Send Payments To': return <EditSendPaymentsTo
                onClose={this.onClose}
                deal_id={this.props?.deal_id}
                toggleRefreshPage={this.props?.toggleRefreshPage}
                mode={'edit'}
                fieldChanged={this.fieldChanged}
                notificationComponent={this.notificationComponent.bind(this)}
                sendToPaymentList={this.props?.sendToPaymentList}
                project_id={this.props?.project_id} />

            case 'Talent Performer': return <TalentPerformer
                // open={this.state.openTalentPerformerModal}
                age={this.props?.age}
                getAge={this.props?.getAge}
                selectedTalent={this.props?.selectedTalent}
                project_id={this.props?.project_id}
                onClose={this.onClose}
                primaryButtonText={"Create"}
                isNew={this.props?.isNew}
                headerName={this.props?.performerHeader}
                fieldChanged={this.fieldChanged}
                getTalentPerformerDetails={this.props?.getTalentPerformerDetails}
                getPerformerTableDetails={this.props?.getPerformerTableDetails}
                viewPerformerData={this.props?.viewPerformerData}
                performerDetails={this.props?.performerDetails}
                existingTalent={this.props?.existingTalent}
                performer_deal_id={this.props?.performerDetails?.performer_deal_id}
                loanoutOptions={this.props?.loanoutOptions}
                alias_options={this.props?.alias_options}
                projectProdCompanies={this.props?.projectProdCompanies}
                legalList={this.props?.legalList}
                {...this.props}
            />
            case 'Esign Modal': return <ESignManager
                handleClose={this.props?.handleClose}
                performer_deal_contract_id={this.props?.performer_deal_contract_id}
                performer_deal_id={this.props?.performer_deal_id}
                loanout_present={this.props?.loanout_present}
                contract_info={this.props?.contract_info}
                project_id={this.props?.project_id}
                getCompensationTabData={this.props?.getCompensationTabData}
                boxURL={this.props?.boxURL}
                file_name={this.props?.file_name}
                monthsAge={this.props?.monthsAge}
                age={this.props?.age}
                birth_date={this.props?.birth_date}
            />
            case 'Edit Riders': return <EditRider
                performer_deal_id={this.props?.performer_deal_id}
                loanout_present={this.props?.loanout_present}
                performer_deal_contract_id={this.props?.performer_deal_contract_id}
                contract_info={this.props?.contract_info}
                boxURL={this.props?.boxURL}
                contract_type={this.props?.contract_type}
                project_id={this.props?.project_id}
                handleClose={this.props?.handleClose}
                fieldChanged={this.fieldChanged}
                notificationComponent={this.notificationComponent.bind(this)}
                rider_type={this.props?.rider_type}
                rider_info={this.props?.rider_info}
                deal_contract={this.props?.deal_contract}
                getCompensationTabData={this.props?.getCompensationTabData}
            />
            case 'Edit Info and text': return <EditInfoAndText
                {...this.props}
            />
            case 'Add Info and text': return <AddInfoAndText
                {...this.props}
            />
            case 'NewUser': return <NewUser
                onClose={this.onClose}
                mode={this.props.mode}
                fieldChanged={this.fieldChanged}
                handleSubmit={this.props?.handleSubmit}
                handleOnChange={this.props?.handleOnChange}
                toggleRefresh={this.props?.toggleRefresh}
                userId={this.props?.userId}
            />
            case "Lookup Values": return <LookupValues
                onClose={this.onClose}
                mode={this.props.mode || "New"}
                fieldChanged={this.fieldChanged}
            />
        }
    }

    getWidthForModal = () => {
        switch (this.props?.modalName) {
            case 'New Season': return 'newEditSeason';
            case 'Episode': return 'newEditEpisode';
            case 'Add Performer': return 'newEditPerformer';
            case 'Roles': return 'newEditRoles';
            case 'Data Query': return 'dataQuerySearch';
            case 'Manage Lookups': return 'managelookup';
            case 'Project': return 'newProduction';
            case 'Edit Representative': return 'editRepresentativeContainer';
            case 'Edit Performer Contact': return 'editPerformerContactContainer';
            case 'Performer Confirmation Modal': return 'performerConfirmationContainer';
            case 'Send Payments To': return 'editSendPaymentsTo';
            case 'Talent Performer': return 'talentPerformerContainer';
            case 'Esign Modal': return 'esignManagerContainer';
            case 'Edit Riders': return 'editRidersContainer';
            case 'Add Project Crew': return 'addProjectCrew';
            default: return '';

        }
    }


    getClasses = () => {
        const { classes } = this.props;
        switch (this.props?.modalName) {
            // case 'Add Work Week': return classes.addWorkweek;
            case 'Episode': return classes.newEpisode;
            case 'New Season': return classes.newSeason;
            case 'New Show': return classes.newShow;
            case 'Add Staff Member': return classes.staffMember;
            case 'Add Location': return classes.addLocation;
            case 'Main Production': classes.MainProduction;
            // case 'New Audition': return classes.NewAudition;
            case 'Edit Rider': return classes.EditRider;
            case 'Roles': return classes.roles;
            case 'Add Performer': return classes.AddPerformer;
            case 'Add New Performer': return classes.AddNewPerformer;
            case 'Add Performer to Episode': return classes.CopyPerformer;
            case 'Manage Lookups': return classes.ManageLookup;
            case 'Data Query': return classes.DataQuery;
            case 'Send Contract': return classes.SendContract;
            default: return '';
        }
    }
    getClassesForModal = () => {
        const { classes } = this.props;
        switch (this.props?.modalName) {
            case 'Status Date': return 'sm';
            case 'Add Work Week': return 'sm';
            case 'New Episode': return 'md';
            case 'New Season': return 'md';
            case 'Roles': return 'lg';
            case 'New Audition': return 'sm';
            case 'Main Production': return 'md';
            case 'Edit Work Activity': return 'sm';
            case 'Add New Performer': return 'sm';
            case 'Add Performer to Epiosde': return 'sm';
            case 'Data Query': return 'lg';
            case 'EditRider': return 'lg';
            case 'Project': return 'lg';
            // case 'New Show': return classes.newShow;
            // case 'Add Staff Member': return classes.staffMember;
            // case 'Change Location': return classes.ChangeLocation;
            case 'Add Location': 'md';
            case 'Edit Representative': return 'lg';
            case 'Edit Performer Contact': return 'lg';
            case 'Performer Confirmation Modal': return 'sm';
            case 'Talent Performer': return 'lg';
            case 'Esign Modal': return 'lg';
            case 'Edit Riders': return 'lg';
            // case 'Add Location': return classes.addLocation;
            case 'NewUser': return 'sm';
            case 'Lookup Values': return 'sm';
            case 'Add New Project Crew': return 'sm';
            default: return 'md';
        }
    }

    handlePermissions = () => {
        let userPermissions = this.props?.userContext?.active_tenant?.permissions;
        let showSave = false;
        switch (this.props?.modalName) {
            case 'Roles':
                showSave = userPermissions?.isCreateRoles ? userPermissions?.isCreateRoles : false
                break;
            case 'Main Production':
                showSave = userPermissions?.isModifyShows ? userPermissions?.isModifyShows : false
                break;
            case 'Episode':
                showSave = userPermissions?.isModifyEpisodes ? userPermissions?.isModifyEpisodes : false
                break;
            case 'Status Date':
                showSave = userPermissions?.isModifyStatusDate ? userPermissions?.isModifyStatusDate : false
                break;
            case 'Add Location':
                showSave = userPermissions?.isModifyLocation ? userPermissions?.isModifyLocation : false
                break;
            case 'Add Staff Member':
                showSave = userPermissions?.isModifyStaffCrew ? userPermissions?.isModifyStaffCrew : false
                break;
            case 'Add Work Week':
                showSave = userPermissions?.isModifyWorkWeek ? userPermissions?.isModifyWorkWeek : false
                break;
            case 'New Season':
                showSave = userPermissions?.isModifySeasons ? userPermissions?.isModifySeasons : false
                break;
            case 'New Show':
                showSave = userPermissions?.isModifyShows ? userPermissions?.isModifyShows : false
                break;
            case 'Change Location':
                showSave = userPermissions?.isModifyLocation ? userPermissions?.isModifyLocation : false
                break;
            case 'New Audition':
                showSave = userPermissions?.isModifyAudition ? userPermissions?.isModifyAudition : false
                break;
            case 'Edit Rider':
                showSave = userPermissions?.isModifyAudition ? userPermissions?.isModifyAudition : false
                break;
            case 'Add Performer':
                showSave = userPermissions?.isModifyPerformer ? userPermissions?.isModifyPerformer : false
                break;
            case 'Add New Performer':
                showSave = userPermissions?.isModifyPerformer ? userPermissions?.isModifyPerformer : false
                break;
            case 'Add Performer to Episode':
                showSave = userPermissions?.isModifyPerformer ? userPermissions?.isModifyPerformer : false
                break;
            case 'Manage Lookups':
                showSave = userPermissions?.isManageLookups ? userPermissions?.isManageLookups : false
                break;
            case 'Data Query':
                showSave = userPermissions?.isManageDataQuery ? userPermissions?.isManageDataQuery : false
                break;
            case 'Edit Work Activity':
                showSave = userPermissions?.isModifyAudition ? userPermissions?.isModifyAudition : false
                break;
            case 'Send Contract':
                showSave = true
                break;
        }
        return showSave;
        // this.setState({ showSave: showSave });
    }

    render() {
        let severity = this.state.showNotifFail ? "error" : this.state.showNotifSuccess ? "success" : this.state.showRemoveProfile ? "success" : "";
        let messageNotification = this.state.showNotifSuccess ? "The changes are saved successfully!" : this.state.showNotifFail ? "Saving changes failed!" : "";
        const { classes } = this.props;
        let message = this.state.showError ? this.state.unsavedMessage : this.state.showFail ? this.state.failMessage :
            (this.state.showSuccess && (this.props.mode === "Edit")) ? this.state.saveEditMessage :
                (this.state.showSuccess && (this.props.mode === "New")) ? `New ${this.props?.headerText} has been created!` :
                    (this.state.showSuccess && (this.props.mode === "Both")) ? this.state.saveEditMessage : "";
        let comfirmModalTitle = this.state.showError ? this.state.unsavedTitle : this.state.showFail ? this.state.failMessage :
            this.state.showSuccess ? this.state.saveTitle : "";
        return (
            <div className={"MainViewModalContainer MainViewModal " + this.getWidthForModal()}>
                {/* <Modal
                    open={this.props?.open ? this.props?.open : false}
                    onClose={this.onClose}
                    className={classes.modal}

                > */}
                <NotificationComponent open={this.state.showNotifSuccess || this.state.showNotifFail} message={messageNotification} severity={severity}
                    handleResetNotify={this.handleResetNotify.bind(this)} />
                <MDBModal staticBackdrop closeOnEsc={false} tabIndex='-1'
                    show={this.props?.open ? this.props?.open : false}>
                    <MDBModalDialog size={this.getClassesForModal()} className={this.getClasses()}>
                        <MDBModalBody>
                            <MDBModalContent>
                                <div>
                                    <MDBContainer fluid className={"padding-hor-12 " + this.props?.className}>
                                        <MessageModal
                                            open={this.state.showError || this.state.showFail || this.state.showSuccess || false}
                                            title={comfirmModalTitle}
                                            message={message}
                                            hideCancel={this.state.showFail || this.state.showSuccess || false}
                                            primaryButtonText={"OK"}
                                            secondaryButtonText={"Cancel"}
                                            onConfirm={() => this.closPopup()}
                                            handleClose={(e) => this.setState({ showError: false, showFail: false, showSuccess: false })} />
                                        <MDBRow className="ModalContent">
                                            <MDBCol>
                                                <MDBRow className="ModalHeader" id={"modal_header"}>
                                                    <MDBCol md={10} className="HeaderText" >{(this.props.mode === 'Both' ? "" : this.props.mode) + " " + this.props?.headerText}</MDBCol>
                                                    <MDBCol md={1} className="HeaderText" ></MDBCol>
                                                    <MDBCol md={1} className="closeIcon" onClick={this.onClose} ><CancelIcon style={{ fill: "white" }} /></MDBCol>
                                                </MDBRow>
                                                <MDBRow className="ModalBody">
                                                    <MDBCol md={12} className="Content"
                                                    // disabled={!this.handlePermissions()}
                                                    >
                                                        {this.getModalContent()}
                                                    </MDBCol>
                                                </MDBRow>
                                                <MDBRow className="footer">

                                                </MDBRow>
                                            </MDBCol>
                                        </MDBRow>
                                    </MDBContainer>
                                </div>
                                {/* </Modal> */}
                            </MDBModalContent>
                        </MDBModalBody>
                    </MDBModalDialog>
                </MDBModal>
            </div>
        );
    }
}

export default withUserContext(withStyles(useStyles)(MainViewModalComponent));
