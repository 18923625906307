import React from "react";
import { MDBContainer, MDBRow, MDBCol, MDBIcon, MDBInput } from "mdbreact";
import { withUserContext } from "../../../../contexts/UserContext";
import BasicLabel from "../../../components/SharedComponents/BasicLabel/BasicLabel";
import BasicButton from "../../../components/SharedComponents/BasicButton/BasicButton";
import BasicTextField from "../../../components/SharedComponents/BasicTextField/BasicTextField";
import './Contract.scss';
import DateField from "../../../components/SharedComponents/DateField/DateField";
import SelectField from "../../../components/SharedComponents/SelectField/SelectField";
import SearchSelectField from "../../../components/SharedComponents/SearchSelectField";
import BasicTextArea from '../../../components/SharedComponents/BasicTextArea/BasicTextArea';
import BasicCheckbox from '../../../components/SharedComponents/BasicCheckbox/BasicCheckbox';
import MessageModal from '../../../components/SharedComponents/MessageModal';
import MainViewModalComponent from '../../../Common/MainViewModalLayout/Modal/Modal';
import { formatDailyRate, validateNumDotOnly, downloadUrl, extractFilenameFromUrl, getFormattedDate } from "../../../Common/Helper";
import messages from '../../../Common/Messages.json';
import CasterService from '../../../../services/service';
import * as Constants from '../../../../constants/constants';
import { contractInitialConfig, contractEpisodeConfig, contractCharactersConfig } from '../Config';
import Radio from '@material-ui/core/Radio';
import RadioGroup from '@material-ui/core/RadioGroup';
import FormControlLabel from '@material-ui/core/FormControlLabel';
import FormControl from '@material-ui/core/FormControl';
import { CircularProgress } from '@material-ui/core';
import AddCircleIcon from "@material-ui/icons/AddCircle";
import RemoveCircleIcon from "@material-ui/icons/RemoveCircle";
import moment from 'moment';

let fileFormData = null;
class NewContract extends React.Component {
    constructor(props) {
        super(props);
        this.state = {
            contractDetails: this.formatContractList(JSON.parse(JSON.stringify({ ...contractInitialConfig }))),
            isPosting: false,
            unionOptions: [],
            contractTypes:[],
            productionCompanyOptions: [],
            seasonEpisodes: [],
            inActiveCharacters: [],
            sendCheckAddressOptions: [],
            sendCheckRepAgencyAddressOptions: [],
            repEmailOptions: [],
            episodeList: [],
            showAttached: false,
            isFileUploaded: false,
            isRiderDelete: false
        };
    }

    componentDidMount() {
        if (this.props?.contractId || this.props?.mode === 'Edit') {
            this.getContractDetails();
        } else {
            this.getLookupData('UNION', 'unionOptions');
            this.getEntityDetails('PRODUCTION_COMPANY', 'productionCompanyOptions');
            this.getEntityDetails('SEASON_EPISODES', 'seasonEpisodes');
            this.getEntityDetails('RATE_BREAKDOWN', 'rateOptions');
            this.getRepAgencyAddressDetails();            
            if (this.props?.union) {
                this.handleOnchange('union', this.props?.union)
            }
        }
        this.getContractTypes();
    }

    formatContractList = (response) => {
        if (response?.characters?.length === 0) {
            response?.characters.push(JSON.parse(JSON.stringify({ ...contractCharactersConfig })));
        }

        return response;
    }

    getRepAgencyAddressDetails = () => {
        let sendCheckRepAgencyAddressOptions = [...this.state.sendCheckRepAgencyAddressOptions];
        let repEmailOptions = [...this.state.repEmailOptions];
        let sendCheckAddressOptions = [...this.state.sendCheckAddressOptions];
        let address = {
            label: `${this.props?.talentDetails?.address_1 ? `${this.props?.talentDetails?.address_1}` : ''}${this.props?.talentDetails?.address_2 ? ` ${this.props?.talentDetails?.address_2}` : ''}${this.props?.talentDetails?.city ? ` ${this.props?.talentDetails?.city}` : ''}${this.props?.talentDetails?.state ? `${this.props?.talentDetails?.city ? ', ' : ''}${this.props?.talentDetails?.state}` : ''}${this.props?.talentDetails?.zip ? ` ${this.props?.talentDetails?.zip}` : ''}${(this.props?.talentDetails?.country && this.props?.talentDetails?.country !== 'United States') ? ` ${this.props?.talentDetails?.country}` : ''}`,
            value: this.props?.talentDetails?.talent_id
        }
        sendCheckAddressOptions?.push(address);
        this.props?.talentDetails?.representatives?.map(item => {
            let address = {
                label: `${item?.address_1 ? `${item?.address_1}` : ''}${item?.address_2 ? ` ${item?.address_2}` : ''}${item?.city ? ` ${item?.city}` : ''}${item?.state ? `${item?.city ? ', ' : ''}${item?.state}` : ''}${item?.zip ? ` ${item?.zip}` : ''}${(item?.country && item?.country !== 'United States') ? ` ${item?.country}` : ''}`,
                value: item?.representative_id
            }
            if (address?.label) {
                sendCheckRepAgencyAddressOptions?.push(address);
            }
            if (item?.representative_email) {
                let repEmail = {
                    label: item?.representative_email,
                    value: item?.representative_id
                }
                repEmailOptions?.push(repEmail);
            }
        })
        this.setState({
            sendCheckRepAgencyAddressOptions: sendCheckRepAgencyAddressOptions,
            repEmailOptions: repEmailOptions,
            sendCheckAddressOptions: sendCheckAddressOptions
        }, () => {
            let contractDetails = { ...this.state.contractDetails };
            if (contractDetails?.send_check_to === 'Performer') {
                if (this.props?.mode === 'New') {
                    contractDetails.send_check_to_address = this.state.sendCheckAddressOptions[0]?.value;
                } 
                else {
                    // contractDetails.send_check_to_address = this.state.sendCheckRepAgencyAddressOptions?.find(item =>
                    //     item?.label === contractDetails?.send_check_to_address)?.value;
                        contractDetails.send_check_to_address = this.state.sendCheckAddressOptions[0]?.value;
                }
            } else if (contractDetails.send_check_to = 'Representative') {
                if (contractDetails?.send_check_to_address) {
                    contractDetails.send_check_to_address = this.state.sendCheckRepAgencyAddressOptions?.find(item =>
                        item?.label.replace(/\s+/g,' ') === contractDetails?.send_check_to_address)?.value;
                } else {
                    contractDetails.send_check_to_address = this.state.sendCheckRepAgencyAddressOptions[0]?.value;
                }
            }

            // if (!contractDetails?.send_contract_to) {
            //     contractDetails.send_contract_to = 'Performer';
            // }
            if (contractDetails?.send_contract_to === 'Performer') {
                if (this.props?.mode === 'New') {
                    contractDetails.agency_address_on_contract = this.state.sendCheckAddressOptions[0]?.value
                    contractDetails.rep_email_on_contract = this.state.repEmailOptions[0]?.value
                } else {
                    contractDetails.agency_address_on_contract = this.state.sendCheckAddressOptions[0]?.value
                    // contractDetails.agency_address_on_contract = this.state.sendCheckRepAgencyAddressOptions?.find(item =>
                    //     item?.label === contractDetails.agency_address_on_contract)?.value;
                    contractDetails.rep_email_on_contract = this.state.repEmailOptions?.find(item =>
                        item?.label === contractDetails.rep_email_on_contract)?.value;
                }
            } else if (contractDetails.send_contract_to = 'Representative') {
                if (contractDetails.agency_address_on_contract) {
                    contractDetails.agency_address_on_contract = this.state.sendCheckRepAgencyAddressOptions?.find(item =>
                        item?.label.replace(/\s+/g,' ') === contractDetails.agency_address_on_contract)?.value;
                } else {
                    contractDetails.agency_address_on_contract = this.state.sendCheckRepAgencyAddressOptions[0]?.value;
                }
                if (contractDetails.rep_email_on_contract) {
                    contractDetails.rep_email_on_contract = this.state.repEmailOptions?.find(item =>
                        item?.label === contractDetails.rep_email_on_contract)?.value;
                } else {
                    contractDetails.rep_email_on_contract = this.state.repEmailOptions[0]?.value;
                }
            }
            this.setState({ contractDetails: contractDetails })
        })
    }

    getContractDetails = () => {
        this.setState({ isFetchingContractDetails: true })
        CasterService.getData(Constants.CasterServiceBaseUrl + `/animationGetContract?contractId=${this.props?.contractId}`,
            this.props?.userContext?.active_tenant?.tenant_id)
            .then(response => {
                let contractDetails = response?.data[0];
                contractDetails.contract_date = contractDetails.contract_date ? this.formatDate(getFormattedDate(contractDetails.contract_date, false)) : null;
                contractDetails.recording_date = contractDetails.recording_date ? this.formatDate(getFormattedDate(contractDetails.recording_date, false)) : null;
                contractDetails.sent_date = contractDetails.sent_date ? this.formatDate(getFormattedDate(contractDetails.sent_date, false)) : null;
                contractDetails.start_date = contractDetails.start_date ? this.formatDate(getFormattedDate(contractDetails.start_date, false)) : null;
                contractDetails.returned_date = contractDetails.returned_date ? this.formatDate(getFormattedDate(contractDetails.returned_date, false)) : null;
                contractDetails.send_check_to_address = contractDetails?.send_check_to_address?.replace(/\s+/g,' ').replace(/\n/g, ' ') || null;
                contractDetails.agency_address_on_contract = contractDetails?.agency_address_on_contract?.replace(/\s+/g,' ').replace(/\n/g, ' ') || null;
                this.setState({
                    contractDetails: contractDetails,
                    isFetchingContractDetails: false
                }, () => {
                    // this.getFiles(true);
                    this.setState({ showAttached: this.state.contractDetails?.is_rider == 1 ? true : false })
                    this.getLookupData('UNION', 'unionOptions');
                    this.getEntityDetails('PRODUCTION_COMPANY', 'productionCompanyOptions');
                    this.getEntityDetails('SEASON_EPISODES', 'seasonEpisodes');
                    this.getEntityDetails('RATE_BREAKDOWN', 'rateOptions');
                    this.getRepAgencyAddressDetails();
                })
            },
                (err) => {
                    console.log('Error is fetching Show' + err);
                    this.setState({ isFetchingContractDetails: false })
                });
    }

    formatDate(date) {
        return moment(date).format('YYYY-MM-DD');
    }

    getLookupData = (entityType, optionName) => {
        CasterService.getData(Constants.CasterServiceBaseUrl + `/animationGetLookupData?entityType=${entityType}`,
            this.props?.userContext?.active_tenant?.tenant_id)
            .then(response => {
                let formattedList = response.data?.map(item => ({ value: item.lookup_data_id, label: item.lookup_value, is_active: item?.is_active }));
                this.setState({ [optionName]: formattedList }, () => {
                    if (this.props?.mode === 'Edit') {
                        let contractDetails = { ...this.state.contractDetails };
                        contractDetails.union = this.state.unionOptions?.find(item => item?.label === contractDetails?.union)?.value;
                        this.setState({ contractDetails: contractDetails })
                    }
                    if (entityType === 'UNION' && this.props?.union) {
                        let union = this.state.unionOptions?.find(item => item.label === this.props?.union)?.value;
                        this.handleOnchange('union', union)
                    }                         
                })
            },
                (err) => {
                    console.log('Error is fetching details' + err);
                });
    }

    getContractTypes() {
        this.state.contractTypes = [{ label: "Day Player", value: "Day Player" }, { label: "Sag Daily", value: "Sag Daily" }];
        if (this.props?.mode === 'New') {
            this.state.contractDetails.contract_type = this.state.contractTypes[0]?.value;
        }
    }
    
    getEntityDetails = (entity, optionName) => {
        let url = null;
        if (entity === 'SEASON_EPISODES') {
            url = `/animationGetEntitySearch?entity=${entity}&searchString=null&showSeasonId=${this.props?.showSeasonId}`
        } else {
            url = `/animationGetEntitySearch?entity=${entity}&searchString=null`;
        }
        CasterService.getData(Constants.CasterServiceBaseUrl + url,
            this.props?.userContext?.active_tenant?.tenant_id)
            .then(response => {
                let formattedList = response.data;
                let episodeList = [...this.state.episodeList];
                if (entity === 'PRODUCTION_COMPANY') {
                    formattedList = formattedList?.map(item => ({ value: item.production_companies_id, label: item.production_company_name }));
                } else if (entity === 'RATE_BREAKDOWN') {
                    formattedList = formattedList?.map(item => ({ value: item.rate_breakdown, label: item.rate_breakdown, daily_rate: item.daily_rate, compensation: item.compensation }))
                } else {
                    if (this.props?.mode === 'Edit' && entity === 'SEASON_EPISODES') {
                        formattedList = formattedList?.map(item => ({ value: item.episode_id, label: item.episode_name + ' - ' + item.gl_code, gl_code: item.gl_code }));
                    } else {
                        formattedList = formattedList?.map(item => ({ value: item.episode_id, text: item.episode_name + ' - ' + item.gl_code, gl_code: item.gl_code }));
                    }
                    episodeList = response.data?.map(item => ({ value: item.episode_id, label: item.episode_name, gl_code: item.gl_code }));
                }
                this.setState({ [optionName]: formattedList, episodeList: episodeList }, () => {
                    if (this.props?.mode === 'Edit') {
                        let contractDetails = { ...this.state.contractDetails };
                        if (entity === 'PRODUCTION_COMPANY') {
                            contractDetails.production_company = this.state.productionCompanyOptions?.find(item => item?.label === contractDetails?.production_company)?.value;
                        } else if (entity === 'RATE_BREAKDOWN') {
                            contractDetails.rate_breakdown = this.state.rateOptions?.find(item => item?.label === contractDetails?.rate_breakdown)?.value ? this.state.rateOptions?.find(item => item?.label === contractDetails?.rate_breakdown)?.value : contractDetails?.rate_breakdown ;
                        }
                        this.setState({ contractDetails: contractDetails })
                    }
                    if (entity === 'PRODUCTION_COMPANY' && this.props?.productionCompany) {
                        let prodCompany = this.state.productionCompanyOptions?.find(item => item.label === this.props?.productionCompany)?.value;
                        this.handleOnchange('production_company', prodCompany)
                    }
                })
            },
                (err) => {
                    console.log('Error is fetching details' + err);
                });
    }

    handleOnchange = (field, value, index = null) => {
        const { contractDetails } = this.state;
        if (field === 'characters') {
            const updateCharacters = [...contractDetails?.characters];
            updateCharacters[index]['name'] = value;
            this.setState({
                contractDetails: {
                    ...contractDetails,
                    characters: updateCharacters,
                },
            });
        } else if (field === 'episodes') {
            let episodes = value?.map(item => ({ episode_id: item.value, episode_name: item.text }));
            episodes = episodes.filter((value, index, self) =>
                index === self.findIndex((t) => (
                    t.episode_id === value.episode_id && t.episode_name === value.episode_name
                ))
            )
            this.setState((prevState) => ({
                contractDetails: {
                    ...prevState.contractDetails,
                    [field]: episodes,
                },
            }));
        } else if (field === 'send_check_to') {
            this.setState((prevState) => ({
                contractDetails: {
                    ...prevState.contractDetails,
                    [field]: value,
                    send_check_to_address: value === 'Performer' ?
                        this.state.sendCheckAddressOptions[0]?.value :
                        (this.state.sendCheckRepAgencyAddressOptions[0]?.value ? this.state.sendCheckRepAgencyAddressOptions[0]?.value : null),
                },
            }));
        } else if (field === 'send_contract_to') {
            this.setState((prevState) => ({
                contractDetails: {
                    ...prevState.contractDetails,
                    [field]: value,
                    agency_address_on_contract: value === 'Performer' ?
                        this.state.sendCheckAddressOptions[0]?.value :
                        (this.state.sendCheckRepAgencyAddressOptions[0]?.value ? this.state.sendCheckRepAgencyAddressOptions[0]?.value : null),
                },
            }));
        } else if (field === 'rate_breakdown') {
            let selectedRateBreakdown = this.state.rateOptions?.find(item => item.value === value);
            this.setState((prevState) => ({
                contractDetails: {
                    ...prevState.contractDetails,
                    [field]: value,
                    'daily_rate': selectedRateBreakdown?.daily_rate,
                    'compensation': selectedRateBreakdown?.compensation
                },
            }));
        } else if (field === 'recording_date') {
            this.setState((prevState) => ({
                contractDetails: {
                    ...prevState.contractDetails,
                    [field]: value || null,
                    'contract_date': value || null,
                    'start_date': value || null
                },
            }));
        } else if (field === 'is_already_signed') {
            this.setState((prevState) => ({
                contractDetails: {
                    ...prevState.contractDetails,
                    [field]: value === true ? 1 : 0,
                },
            }));
        } else {
            this.setState((prevState) => ({
                contractDetails: {
                    ...prevState.contractDetails,
                    [field]: value || null,
                },
            }));
        }
    };

    addCallback = () => {
        let characters = [...this.state.contractDetails?.characters];
        characters.push(JSON.parse(JSON.stringify({ ...contractCharactersConfig })))
        this.setState((prevState) => ({
            contractDetails: {
                ...prevState.contractDetails,
                ["characters"]: characters
            }
        }));
    }

    removeCallback = (listName, callbackItem, index) => {
        this.setState({
            listName: listName,
            callbackItem: callbackItem,
            postInitiated: false,
            index: index
        }, () => {
            if (callbackItem.contract_character_id) {
                this.setState({ isOpen: true })
            } else {
                this.deleteRow(listName, callbackItem, 'new');
            }
        })
    }

    deleteRow = (listName, callbackItem, type, index = null) => {
        let contractDetails = this.state.contractDetails;
        contractDetails[listName][this.state.index].is_delete = 1;
        if (type === 'edit') {
            let temp = [];
            this.state.inActiveCharacters?.map((item) => {
                temp.push(item);
            });
            let characterList = contractDetails[listName].filter(item =>
                (item.is_delete === 1 && item.index === callbackItem.index)
            );
            temp.push(characterList[0]);
            this.setState({ inActiveCharacters: temp })
        }
        let characterList = contractDetails[listName].filter(item =>
            (item.contract_character_id !== callbackItem.contract_character_id) || (item.index !== callbackItem.index)
        );
        if (characterList.length === 0) {
            contractDetails[listName] = [{ ...contractCharactersConfig }];
        } else {
            contractDetails[listName] = characterList;
        }
        this.setState({
            contractDetails: contractDetails,
            listName: null,
            callbackItem: null,
            postInitiated: false,
            isOpen: false,
        });
    }

    handleAddRider = () => {
        this.setState({ openRiderEditor: true })
    }

    handleCancelRider = () => {
        this.setState((prevState) => ({
            contractDetails: {
                ...prevState.contractDetails,
                ["rider"]: null
            }
        }));
    }

    getFormattedContractDetails = (contractDetails) => {
        let characterTemp = [];
        this.state.inActiveCharacters?.map(item => {
            characterTemp?.push(item);
        });
        contractDetails.characters?.map(item => {
            characterTemp?.push(item);
        });
        contractDetails.characters = characterTemp;
        return contractDetails;
    }

    canSubmit = (contractDetails) => {
        let canSubmit = false;
        if (this.props?.mode == 'New') {
            canSubmit = contractDetails?.contract_date && contractDetails?.daily_rate && contractDetails?.start_date &&
                (!contractDetails?.episodes?.some(episode => (!(episode.episode_id && episode.episode_name) || false))) &&
                (!contractDetails?.characters?.some(character => (!(character.name) || false)));
        } else {
            canSubmit = contractDetails?.episode_id && contractDetails?.contract_date &&
                contractDetails?.daily_rate && contractDetails?.start_date &&
                (!contractDetails?.characters?.some(character => (!(character.name) || false)));
        }
        return canSubmit;
    }

    handleSubmit = () => {
        this.setState({ postInitiated: true, isPosting: true })
        let payload = { ...this.state.contractDetails };
        payload.performer_id = this.props?.performerId;
        payload.union = this.state.unionOptions?.find(item => item.value === payload.union)?.label;
        payload.contract_type = this.state.contractTypes?.find(item => item.value === payload.contract_type)?.label;
        payload.production_company = this.state.productionCompanyOptions?.find(item => item.value === payload.production_company)?.label;
        if (payload.send_check_to === 'Performer') {
            let talent = this.props?.talentDetails;
            payload.send_check_to_address = talent ?
                `${talent?.address_1 ? `${talent?.address_1}` : ''}${talent?.address_2 ? ` \n${talent?.address_2}` : ''}${talent?.city ? ` \n${talent?.city}` : ''}${talent?.state ? `${talent?.city ? ', ' : ''}${talent?.state}` : ''}${talent?.zip ? ` ${talent?.zip}` : ''}${(talent?.country && talent?.country !== 'United States') ? ` \n${talent?.country}` : ''}` : null;
            // payload.send_check_to_address = this.state.sendCheckAddressOptions?.find(item => item.value === payload.send_check_to_address)?.label;
        } else {
            let selectedRep = this.props?.talentDetails?.representatives?.find(item => item.representative_id === payload.send_check_to_address);
            payload.send_check_to_address = selectedRep ?
                `${selectedRep?.address_1 ? `${selectedRep?.address_1}` : ''}${selectedRep?.address_2 ? ` \n${selectedRep?.address_2}` : ''}${selectedRep?.city ? ` \n${selectedRep?.city}` : ''}${selectedRep?.state ? `${selectedRep?.city ? ', ' : ''}${selectedRep?.state}` : ''}${selectedRep?.zip ? ` ${selectedRep?.zip}` : ''}${(selectedRep?.country && selectedRep?.country !== 'United States') ? ` \n${selectedRep?.country}` : ''}` : null;
            // payload.send_check_to_address = this.state.sendCheckRepAgencyAddressOptions?.find(item => item.value === payload.send_check_to_address)?.label;
        }
        let selectedRepAgency = this.props?.talentDetails?.representatives?.find(item => item.representative_id === payload.agency_address_on_contract);
        payload.agency_address_on_contract = selectedRepAgency ?
            `${selectedRepAgency?.address_1 ? `${selectedRepAgency?.address_1}` : ''}${selectedRepAgency?.address_2 ? ` \n${selectedRepAgency?.address_2}` : ''}${selectedRepAgency?.city ? ` \n${selectedRepAgency?.city}` : ''}${selectedRepAgency?.state ? `${selectedRepAgency?.city ? ', ' : ''}${selectedRepAgency?.state}` : ''}${selectedRepAgency?.zip ? ` ${selectedRepAgency?.zip}` : ''}${(selectedRepAgency?.country && selectedRepAgency?.country !== 'United States') ? ` \n${selectedRepAgency?.country}` : ''}` : null;
        // payload.agency_address_on_contract = this.state.sendCheckRepAgencyAddressOptions?.find(item => item.value === payload.agency_address_on_contract)?.label;
        payload.rep_email_on_contract = this.state.repEmailOptions?.find(item => item.value === payload.rep_email_on_contract)?.label;
        payload.rate_breakdown = payload.rate_breakdown;
        if (this.props?.mode === 'Edit') {
            payload.contract_id = this.props?.contractId;
        }
        if (this.state.isFileUploaded) {
            payload['is_rider'] = 1;
        }
        if (this.state.isRiderDelete) {
            payload['is_rider'] = 0;
        }
        payload.daily_rate = payload?.daily_rate?.toString() || null;
        payload = this.getFormattedContractDetails(payload);
        if (this.canSubmit(payload)) {
            let url = null;
            if (this.props?.mode === 'New') {
                url = `/animationPostContract`
            } else {
                url = `/animationPostEditContract`
            }
            CasterService.postDataParams(Constants.CasterServiceBaseUrl + url,
                payload,
                this.props?.userContext?.active_tenant?.tenant_id)
                .then(response => {
                    if (response.data.error) {
                        this.setState({ isPosting: false })
                        this.props?.notificationComponent(true, "fail");
                    } else {
                        if (this.state.isFileUploaded) {
                            this.handleUploadRider(response?.data[0]?.contract_id);
                        }
                        if (this.state.isRiderDelete) {
                            this.deleteRider(response?.data[0]?.contract_id);
                        }
                        if (this.props?.contractFromTalentRecord) {
                            this.props?.navigationCallback(this.props?.talentDetails)
                            this.props?.notificationComponent(true, "success");
                        } else {
                            this.props?.notificationComponent(true, "success", "refresh-contract")
                        }
                        this.setState({
                            isPosting: false,
                            postInitiated: false
                        })
                    }
                },
                    (err) => {
                        console.log('Error is submitting Contract details' + err);
                        this.props?.notificationComponent(true, "fail");
                        this.setState({ isPosting: false, postInitiated: false })
                    });
        } else {
            console.log("Error in submitting Contract details")
            this.setState({ isPosting: false, postInitiated: false })
        }
    }

    getFormattedAkas = () => {
        let akas = [];
        this.props?.talentDetails?.akas?.map(item => {
            akas?.push(item?.aka)
        })
        return akas?.join(', ');
    }

    getFormattedRepresentatives = () => {
        let representatives = [];
        let performerReps = [... this.props?.talentDetails?.representatives]
        performerReps?.map(item => {
            representatives?.push(item?.representative_name)
        })
        return representatives?.join(', ');
    }

    uploadRider = (file, isReplace = false) => {
        console.log("check the file object", file);
        if (file?.type === 'application/pdf') {
            fileFormData = new FormData();
            fileFormData.append("files", file);
            fileFormData.append('showId', this.props?.showId);
            fileFormData.append('showName', this.props?.showName);
            fileFormData.append('seasonStartYear', this.props.seasonDetails?.start_year);
            fileFormData.append('seasonEndYear', this.props.seasonDetails?.end_year);
            if (this.props?.mode === 'New') {
                let episodeDetails = this.state.contractDetails?.episodes[0];
                let filteredEpisodeDetails = this.state.episodeList?.find(item => item.value === episodeDetails?.episode_id);
                fileFormData.append('episodeName', filteredEpisodeDetails?.label);
                fileFormData.append('episodeGlCode', filteredEpisodeDetails?.gl_code);
            } else {
                fileFormData.append('episodeName', this.state.episodeList?.find(item => item.value === this.state.contractDetails?.episode_id)?.label);
                fileFormData.append('episodeGlCode', this.state.episodeList?.find(item => item.value === this.state.contractDetails?.episode_id)?.gl_code);
            }
            fileFormData.append('moduleType', 'Rider');
            this.setState({
                showAttached: true,
                isFileUploaded: true,
            })
        } else {
            this.setState({ showRiderError: true })
        }
    }

    handleUploadRider = (contractId) => {
        fileFormData.append('contractId', contractId);
        CasterService.uploadFileFormData(Constants.CasterServiceBaseUrl + `/animationUploadFiles`,
            fileFormData)
            .then(response => {
                if (response?.data?.status == 200) {
                    this.setState({ showAttached: true })
                }
            },
                (err) => {
                    console.log('Error in uploading files' + err);
                });
    }

    getFiles = (isLoadFile = false) => {
        let payload = {
            "moduleType": "Rider",
            "showId": this.props?.showId,
            "showName": this.props?.showName,
            "seasonStartYear": this.props.seasonDetails?.start_year,
            "seasonEndYear": this.props.seasonDetails?.end_year,
            "episodeName": this.state.episodeList?.find(item => item.value === this.state.contractDetails?.episode_id)?.label,
            "episodeGlCode": this.state.episodeList?.find(item => item.value === this.state.contractDetails?.episode_id)?.gl_code,
            "contract_id": this.state.contractDetails?.contract_id
        }
        CasterService.getData(Constants.CasterServiceBaseUrl + `/animationGetFiles?input=${btoa(JSON.stringify(payload))}`,
            this.props?.userContext?.active_tenant?.tenant_id, 1)
            .then(response => {
                if (!isLoadFile) {
                    console.log("check the get files response", response);
                    let url = response?.data;
                    const filename = extractFilenameFromUrl(url);
                    let decodedFile = decodeURIComponent(filename)
                    downloadUrl(url, decodedFile);
                } else {
                    if (response.status == 200) {
                        this.setState({ showAttached: true })
                    }
                }
            },
                (err) => {
                    console.log('Error is fetching files' + err);
                });
    }

    handleDeleteRider = () => {
        let payload = {
            "moduleType": "Rider",
            "showId": this.props?.showId,
            "showName": this.props?.showName,
            "seasonStartYear": this.props.seasonDetails?.start_year,
            "seasonEndYear": this.props.seasonDetails?.end_year,
            "episodeName": this.state.episodeList?.find(item => item.value === this.state.contractDetails?.episode_id)?.label,
            "episodeGlCode": this.state.episodeList?.find(item => item.value === this.state.contractDetails?.episode_id)?.gl_code,
            // "contractId": this.state.contractDetails?.contract_id
        }
        this.setState({ deleteRiderPayload: payload, isRiderDelete: true, showAttached: false })
    }

    deleteRider = (contractId) => {
        let payload = { ...this.state.deleteRiderPayload };
        payload['contractId'] = contractId;
        CasterService.deleteDataParams(Constants.CasterServiceBaseUrl + `/animationDeleteFiles`, payload,
            this.props?.userContext?.active_tenant?.tenant_id, 1)
            .then(response => {
                console.log("check the delete files response", response);
                if (response.status === 200) {
                    this.setState({ showAttached: false })
                }
            },
                (err) => {
                    console.log('Error is fetching files' + err);
                });
    }

    render() {
        let episodeListValue = this.state.contractDetails?.episodes?.map(item => ({ value: item.episode_id, text: item.episode_name }));
        
        return (
            <div>
                {this.state.isFetchingContractDetails ? (
                    <div className="ContentLoader">
                        <CircularProgress />
                    </div>
                ) : (
                        <MDBContainer className="NewContractContainer">
                            <MessageModal
                                open={this.state.isOpen}
                                title={"Delete record"}
                                message={"Would you like to delete saved record?"}
                                primaryButtonText={"Delete"}
                                secondaryButtonText={"Cancel"}
                                onConfirm={() => this.deleteRow(this.state.listName, this.state.callbackItem, 'edit')}
                                handleClose={(e) => this.setState({ isOpen: false })}
                            />
                            <MDBRow>
                                <MDBRow>
                                    <MDBCol md={6}>
                                        <div className="heading">Selected talent</div>
                                        <div className="paragraph">
                                            {this.props?.talentDetails?.talent_name}
                                        </div>
                                    </MDBCol>
                                    <MDBCol md={6}>
                                        <div className="heading">Representatives</div>
                                        <div className="paragraph">{this.getFormattedRepresentatives()}</div>
                                    </MDBCol>
                                </MDBRow>
                                <MDBRow>
                                    {this.props?.mode === 'Edit' ?
                                        <MDBCol md={6}>
                                            <SelectField
                                                label={"Episode(s)"}
                                                value={this.state.contractDetails?.episode_id}
                                                options={this.state.seasonEpisodes || []}
                                                isMandatory={true}
                                                showMandatory={this.state.postInitiated}
                                                mandatoryWarning={messages.mandatoryWarning}
                                                onChange={(e) => this.handleOnchange("episode_id", e.target.value)}
                                                disabled={this.props?.mode === 'Edit' || false}
                                            />
                                        </MDBCol> :
                                        <MDBCol md={6} className="contractEpisodes pr-0">
                                            <MDBRow
                                                className={`${episodeListValue?.length === 0 ? "searchSelectHeight searchSelect" : "searchSelect"}`}
                                                id='episodeDropDown'
                                            >
                                                <SearchSelectField
                                                    id={"episode"}
                                                    label={"Episode(s)"}
                                                    value={episodeListValue || []}
                                                    options={this.state?.seasonEpisodes || []}
                                                    isMandatory={true}
                                                    showMandatory={this.state.postInitiated}
                                                    mandatoryWarning={messages.mandatoryWarning}
                                                    limitTags={4}
                                                    onChange={(e, value) => this.handleOnchange('episodes', value)}
                                                    multiple={true}
                                                    width={'100%'}
                                                />
                                            </MDBRow>
                                        </MDBCol>
                                    }
                                    <MDBCol className="pr-0">
                                        {this.state.contractDetails?.characters?.map((item, index) =>
                                            <MDBRow>
                                                <MDBCol md={10}>
                                                    <BasicTextField
                                                        className="text"
                                                        label={index === 0 ? "Character(s)" : ""}
                                                        value={item?.name}
                                                        isMandatory={true}
                                                        showMandatory={this.state.postInitiated}
                                                        mandatoryWarning={messages.mandatoryWarning}
                                                        onChange={(e) =>
                                                            this.handleOnchange("characters", e.target.value, index)
                                                        }
                                                    />
                                                </MDBCol>
                                                <MDBCol md={2} className={`${index === 0 ? 'firstRowActions' : 'actionIcons'} p-0`}>
                                                    {!(this.state.contractDetails.characters.length === 1) ? (
                                                        <RemoveCircleIcon
                                                            className="ml-2 align-self-center"
                                                            fontSize="small"
                                                            onClick={() => this.removeCallback('characters', item, index)}
                                                        />
                                                    ) : null}
                                                    {this.state.contractDetails.characters.length - 1 === index ? (
                                                        <AddCircleIcon
                                                            className="ml-2 align-self-center"
                                                            fontSize="small"
                                                            onClick={() => this.addCallback(index)}
                                                        />
                                                    ) : null}
                                                </MDBCol>
                                            </MDBRow>
                                        )}
                                    </MDBCol>
                                </MDBRow>
                                <MDBRow>
                                    <MDBCol md={3} className="pr-0">
                                        <DateField
                                            id="Date"
                                            label={"Recording Date"}
                                            value={this.state.contractDetails.recording_date || ""}
                                            onChange={(e) => this.handleOnchange('recording_date', e.target.value)}
                                        />
                                    </MDBCol>
                                    <MDBCol md={3} className="pr-0">
                                        <SelectField
                                            label={"Union"}
                                            value={this.state.contractDetails.union}
                                            options={this.state.unionOptions || []}
                                            onChange={(e) => this.handleOnchange("union", e.target.value)}
                                        />
                                    </MDBCol>
                                    <MDBCol md={6} className="pr-0">
                                        <SelectField
                                            label={"Production Company"}
                                            value={this.state.contractDetails.production_company}
                                            options={this.state.productionCompanyOptions || []}
                                            onChange={(e) => this.handleOnchange("production_company", e.target.value)}
                                        />
                                    </MDBCol>
                                </MDBRow>
                                <MDBRow>
                                    <div>Contract</div>
                                    <MDBCol md={6} className="pr-0">                                       
                                        {/* <SelectField
                                            label={"Contract Type"}
                                            value={this.state.contractDetails.contract_type}
                                            options={this.state.contractType || ["Day Player","Sag Daily"]}
                                            onChange={(e) => this.handleOnchange("contract_type", e.target.value)}
                                        /> */}
                                        <SelectField
                                            label={"Contract Type"}
                                            value={this.state.contractDetails.contract_type}
                                            options={this.state.contractTypes || []}

                                            onChange={(e) => this.handleOnchange("contract_type", e.target.value)}
                                        />
                                    </MDBCol>
                                </MDBRow>
                                <MDBRow>
                                    <MDBCol md={3} className="pr-0">
                                        <DateField
                                            id="contractDate"
                                            label={"Contract Date"}
                                            value={this.state.contractDetails.contract_date || ""}
                                            isMandatory={true}
                                            showMandatory={this.state.postInitiated}
                                            mandatoryWarning={messages.mandatoryWarning}
                                            onChange={(e) => this.handleOnchange('contract_date', e.target.value)}
                                        />
                                    </MDBCol>
                                    <MDBCol md={3} className="pr-0">
                                        <DateField
                                            id="start-date"
                                            label={"Start Date"}
                                            value={this.state.contractDetails.start_date || ""}
                                            isMandatory={true}
                                            showMandatory={this.state.postInitiated}
                                            mandatoryWarning={messages.mandatoryWarning}
                                            onChange={(e) => this.handleOnchange('start_date', e.target.value)}
                                        />
                                    </MDBCol>
                                    <MDBCol md={3} className="pr-0">
                                        <DateField
                                            id="sent-date"
                                            label={"Sent Date"}
                                            value={this.state.contractDetails.sent_date || ""}
                                            onChange={(e) => this.handleOnchange('sent_date', e.target.value)}
                                        />
                                    </MDBCol>
                                    <MDBCol md={3} className="pr-0">
                                        <DateField
                                            id="returned-date"
                                            label={"Returned Date"}
                                            value={this.state.contractDetails.returned_date || ""}
                                            onChange={(e) => this.handleOnchange('returned_date', e.target.value)}
                                        />
                                    </MDBCol>
                                </MDBRow>
                                <MDBRow>
                                    <MDBCol md={6} className="rate-breakdown" style={{ marginRight: "-25px" }}>
                                        <SelectField
                                            label={"Rate Breakdown"}
                                            value={this.state.contractDetails.rate_breakdown}
                                            options={this.state.rateOptions || []}
                                            onChange={(e) => this.handleOnchange("rate_breakdown", e.target.value)}
                                        />
                                    </MDBCol>
                                    <MDBCol md={3} className="daily-rate pr-0" style={{ marginTop: "4px" }}>
                                        <BasicTextField
                                            label={"Daily Rate"}
                                            placeholder="$0.00"
                                            value={this.state.contractDetails?.daily_rate || ""}
                                            isMandatory={true}
                                            showMandatory={this.state.postInitiated}
                                            mandatoryWarning={messages.mandatoryWarning}
                                            onChange={(e) => this.handleOnchange('daily_rate', e.target.value)}
                                            onBlur={() => {
                                                const formattedValue = formatDailyRate(this.state.contractDetails?.daily_rate);
                                                this.setState((prevState) => ({
                                                    contractDetails: {
                                                        ...prevState.contractDetails,
                                                        daily_rate: formattedValue,
                                                    },
                                                }));
                                            }}
                                        />
                                    </MDBCol>
                                    <MDBCol md={3} className="pr-0">
                                        <BasicTextField
                                            label={"10th Run Buyout"}
                                            placeholder="$0.00"
                                            value={("$" + (this.state.contractDetails?.tenth_run_buyout || ""))}
                                            onChange={(e) => {
                                                const rateValue = e.target.value.replace("$", "");
                                                if (validateNumDotOnly(rateValue)) {
                                                    this.handleOnchange('tenth_run_buyout', rateValue);
                                                }
                                            }}
                                        />
                                    </MDBCol>
                                </MDBRow>
                                <MDBRow>
                                    <MDBCol md={6} className="pr-0">
                                        <BasicTextArea
                                            label={"Compensation"}
                                            value={this.state.contractDetails.compensation}
                                            onChange={(e) =>
                                                this.handleOnchange("compensation", e.target.value)
                                            }
                                        />
                                    </MDBCol>
                                    <MDBCol md={6} className="pr-0">
                                        <BasicTextArea
                                            label={"Miscellaneous"}
                                            value={this.state.contractDetails.miscellaneous}
                                            onChange={(e) =>
                                                this.handleOnchange("miscellaneous", e.target.value)
                                            }
                                        />
                                    </MDBCol>
                                </MDBRow>
                                <MDBRow>
                                    <div>Contract Options</div>
                                </MDBRow>
                                <MDBRow>
                                    <MDBCol md={6} className="send-check-to">
                                        <BasicLabel text="Send Check To" />
                                        <FormControl component="fieldset">
                                            <RadioGroup
                                                aria-label="send-check-to"
                                                name="send-check-to"
                                                value={this.state.contractDetails?.send_check_to}
                                                onChange={(e) => this.handleOnchange('send_check_to', e.target.value)}
                                            >
                                                <FormControlLabel value="Performer" control={<Radio size="small" />} label="Performer" />
                                                <FormControlLabel value="Representative" control={<Radio size="small" />} label="Representative" />
                                            </RadioGroup>
                                        </FormControl>
                                    </MDBCol>
                                    <MDBCol md={6} className="send-contract-to">
                                        <BasicLabel text="Send Contract To" />
                                        <FormControl component="fieldset">
                                            <RadioGroup
                                                aria-label="send-contract-to"
                                                name="send-contract-to"
                                                value={this.state.contractDetails?.send_contract_to}
                                                onChange={(e) => this.handleOnchange('send_contract_to', e.target.value)}
                                            >
                                                <FormControlLabel value="Performer" control={<Radio size="small" />} label="Performer" />
                                                <FormControlLabel value="Representative" control={<Radio size="small" />} label="Representative" />
                                            </RadioGroup>
                                        </FormControl>
                                    </MDBCol>
                                </MDBRow>
                                <MDBRow>
                                    <MDBCol md={6} className="pr-0">
                                        <SelectField
                                            placeholderText="[Address of Rep's Agency]"
                                            value={this.state.contractDetails.send_check_to_address}
                                            options={this.state.contractDetails?.send_check_to === 'Performer' ?
                                                this.state.sendCheckAddressOptions :
                                                this.state.contractDetails?.send_check_to === 'Representative' ?
                                                    this.state.sendCheckRepAgencyAddressOptions : []
                                            }
                                            onChange={(e) => this.handleOnchange("send_check_to_address", e.target.value)}
                                        />
                                    </MDBCol>
                                </MDBRow>
                                <MDBRow>
                                    <MDBCol md={6} className="pr-0">
                                        <SelectField
                                            label={"Agency Address(to Print on Contract)"}
                                            placeholderText="[Address of Rep's Agency]"
                                            value={this.state.contractDetails.agency_address_on_contract}
                                            options={this.state.contractDetails?.send_contract_to === 'Performer' ?
                                            this.state.sendCheckAddressOptions :
                                            this.state.contractDetails?.send_contract_to === 'Representative' ?
                                                this.state.sendCheckRepAgencyAddressOptions : []
                                        }
                                            // options={this.state.sendCheckRepAgencyAddressOptions || []}
                                            onChange={(e) => this.handleOnchange("agency_address_on_contract", e.target.value)}
                                        />
                                    </MDBCol>
                                    <MDBCol md={6} className="pr-0">
                                        <SelectField
                                            label={"Representive Email(to Print on Contract)"}
                                            value={this.state.contractDetails.rep_email_on_contract}
                                            options={this.state.repEmailOptions || []}
                                            onChange={(e) => this.handleOnchange("rep_email_on_contract", e.target.value)}
                                        />
                                    </MDBCol>
                                </MDBRow>
                                <MDBRow>
                                    <BasicLabel text="Rider" />
                                    <MDBCol md={6} className='rider'>
                                        {/* {!this.state.contractDetails?.rider ? ( */}
                                        {(!this.state.showAttached) ? (
                                            <div>
                                                <span>
                                                    {/* <MDBIcon
                                                        icon="plus-circle"
                                                        className="add-icon"
                                                        onClick={this.handleAddRider}
                                                    /> */}
                                                    <BasicButton
                                                        variant="outlined"
                                                        icon="paperclip"
                                                        text="Add"
                                                        type="inline"
                                                        inputType="file"
                                                        multiple={false}
                                                        id={'upload-rider'}
                                                        uploadFile={(file) => this.uploadRider(file)}
                                                    />
                                                </span>
                                                {/* <span className="add-text" onClick={this.handleAddRider}>Add</span> */}
                                            </div>
                                        ) : (
                                                <>
                                                    {this.state.contractDetails?.contract_id_old ?
                                                        <MDBCol
                                                            md={3}
                                                            className="attached-text pr-0"
                                                            onClick={() => this.getFiles(false)}
                                                        >
                                                            Download Rider
                                                        </MDBCol> :
                                                        <MDBRow>
                                                            <MDBCol
                                                                md={3}
                                                                className="attached-text pr-0"
                                                                onClick={() => this.getFiles(false)}
                                                            >
                                                                Attached
                                                            </MDBCol>
                                                            {/* <span>
                                                        <MDBIcon
                                                            icon="pen"
                                                            className="text-success"
                                                            onClick={() => this.setState({ openRiderEditor: true })}
                                                        />
                                                    </span> */}
                                                            <MDBCol md={1}>
                                                                <BasicButton
                                                                    variant="outlined"
                                                                    icon="exchange-alt"
                                                                    type="inline"
                                                                    inputType="file"
                                                                    multiple={false}
                                                                    id={'upload-rider-replace'}
                                                                    uploadFile={(file) => this.uploadRider(file)}
                                                                />
                                                            </MDBCol>
                                                            <MDBCol>
                                                                <MDBIcon
                                                                    icon="times"
                                                                    className="text-danger"
                                                                    // onClick={this.handleCancelRider}
                                                                    onClick={this.handleDeleteRider}
                                                                />
                                                            </MDBCol>
                                                        </MDBRow>
                                                    }
                                                </>
                                            )}
                                    </MDBCol>
                                    <MDBCol>
                                        <BasicCheckbox
                                            id={"is_already_signed"}
                                            label={"Already Signed"}
                                            checked={this.state.contractDetails?.is_already_signed === 1 ? true : false}
                                            onChange={(e) =>
                                                this.handleOnchange("is_already_signed", e.target.checked)
                                            }
                                        />
                                    </MDBCol>
                                </MDBRow>
                            </MDBRow>
                            <MDBRow>
                                <MDBCol>
                                    <BasicTextArea
                                        id="contract-comments"
                                        label="Contract Comments"
                                        value={this.state.contractDetails?.notes}
                                        onChange={(e) => this.handleOnchange('notes', e.target.value)}
                                    />
                                </MDBCol>
                            </MDBRow>
                            <MDBRow className="p-2 margin">
                                <MDBCol md={8} lg={8}></MDBCol>
                                <MDBCol md={2} lg={2}>
                                    <BasicButton
                                        className="btnwidth"
                                        variant="contained"
                                        text={this.props?.mode === "Edit" ?
                                            this.state.isPosting ? (
                                                <CircularProgress color="inherit" size={18} />
                                            ) : (
                                                    "Save"
                                                ) : this.state.isPosting ? (
                                                    <CircularProgress color="inherit" size={18} />
                                                ) : (
                                                    "Create"
                                                )
                                        }
                                        icon={"Create"}
                                        onClick={() => this.handleSubmit(false)}
                                    />
                                </MDBCol>
                                <MDBCol md={2}>
                                    <BasicButton
                                        type="inline"
                                        variant="outlined"
                                        text={"Cancel"}
                                        onClick={() => {
                                            this.props?.onClose();
                                        }}
                                    />
                                </MDBCol>
                            </MDBRow>
                            {this.state.openRiderEditor && (
                                <div className="rider-modal">
                                    <MainViewModalComponent
                                        open={this.state.openRiderEditor}
                                        handleClose={() => this.setState({ openRiderEditor: false })}
                                        headerText={"Edit Rider"}
                                        mode={"Edit"}
                                        modalName={"Rider"}
                                        rider={this.state.contractDetails?.rider}
                                        handleOnChange={this.handleOnchange}
                                    />
                                </div>
                            )}
                        </MDBContainer>
                    )
                }
            </div >
        );
    }
}

export default withUserContext(NewContract);
