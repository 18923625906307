import React, { Component } from "react";
import { MDBContainer, MDBRow, MDBCol, MDBIcon, MDBTooltip } from "mdb-react-ui-kit";
import * as Constants from "../../../constants/constants";
import CasterService from "../../../services/service";
import { CircularProgress } from "@material-ui/core";
import "./WorkActivity.scss";
import SelectField from "../SharedComponents/SelectField/SelectField";
import SearchSelectField from "../SharedComponents/SearchSelectField";
import DateField from "../SharedComponents/DateField/DateField";
import BasicTextField from "../SharedComponents/BasicTextField/BasicTextField";
import BasicTextArea from '../SharedComponents/BasicTextArea/BasicTextArea';
import BasicButton from "../SharedComponents/BasicButton/BasicButton";
import { WorkActivityGuaranteeOptions, workActivityConfig, WorkActivityBasicOptions } from "./Config";
import { getFormattedDate, formatRate, numberToWords } from "../../Common/Helper";
import MessageModal from "../SharedComponents/MessageModal";
import Tooltip from '@mui/material/Tooltip';
import ReactHtmlParser from 'react-html-parser';

export default class WorkActivity extends Component {
  constructor(props) {
    super(props);
    this.state = {
      constractStartOptions: [],
      codeOptions: [],
      workActvityList: [],
      allStatusDatesData: [],
      isActivityListFetching: false,
      finalTotal: null,
      contractDetails: null,
      showFinishCodeError: false,
      workActvity: { ...workActivityConfig },
      isEdit: false,
      workActivityIndex: null,
      deletingWorkActivity: false,
      workActivityListSave:[],
      deleteWorkActivity:[],
      showEditWorkActivitySummary:false,
      showNewWorkActivitySummary:false,
      performerIndex:null,
      newWorkActivity:{ ...workActivityConfig },
      showSaveCancelButtons: false,
      showCancelActvityModal: false,
      postingWorkActivity: false,
      contractDateRequired:false,
       calculateGuarantee : 0,
       calculatePrincipalFree : 0,
       calculatePostFree : 0,
       calculateFree : 0,
       sumActivities:[],
       totalSumActivities:[],
       perksList: [],
       selectedStartDate:null,
       perkToDisplay:null
    };
  }
  
  getAllPerksData() {
    // casterFeaturePerformerDealPerks
    CasterService.getData(
      Constants?.CasterServiceBaseUrl +
      `/casterFeaturePerformerDealPerks?performer_deal_id=${this.props?.performer_deal_id}&performer_deal_perks_id=null`
    ).then(
      (response) => {
        this.setState({ pageLoader: false });
        if (response?.data?.error) {
          console.log("error from search", repsonse?.data?.error);
        } else {
          this.setState({ perksList: response?.data });
        }
      },
      (err) => {
        this.setState({ pageLoader: false });
        console.log("Error from workactivity search", err);
      }
    );
  }
  componentDidMount() {
    this.getAllPerksData();
    this.getSearchEntity("WORK_ACTIVITY");    
    this.getStatusDatesData();
    this.getWorkActivityList();
  }
  getStatusDatesData() {
    CasterService.getData(
      Constants.CasterServiceBaseUrl +
        `/casterFeatureProjectStatusDates?project_id=${this.props?.project_id}`
    ).then(
      (response) => {
        if (response?.data?.error) {
          return;
        } else {
          let formattedList =
            response?.data?.length > 0
              ? response?.data
                  ?.map((item) => {
                    let newItem = { ...item };
                    newItem.from_date = item?.from_date
                      ? getFormattedDate(item?.from_date)
                      : null;
                    newItem.to_date = item?.to_date
                      ? getFormattedDate(item?.to_date)
                      : null;
                    return newItem;
                  })
                  ?.filter(
                    (i) =>
                      i?.status == "Post-Production" ||
                      i?.status == "Production"
                  )
              : [];

          this.setState({ allStatusDatesData: formattedList });
        }
      },
      (err) => {
        this.setState({
          openNotification: "Status Dates Data Load Fail",
          severity: "error",
        });
      }
    );
  }

  onChange(key, value, e) {
    if (key == "code") {
      // work_activity_desc
      const contractDetails = this.state?.constractStartOptions?.filter(
        (i) => i?.value == this.state?.workActvity?.contract_date
      )?.[0]?.obj || null;
      let defaultValue = {};
      if (contractDetails?.as_value == "Daily") {
        defaultValue = this.autoPopulateguaranteePrincipalPostBasicValues(value,"Daily");
      }
      if(contractDetails?.as_value == "Weekly"){
        defaultValue = this.autoPopulateguaranteePrincipalPostBasicValues(value,"Weekly");
      }
      this.setState((prevState) => ({
        workActvity: {
          ...prevState?.workActvity,
          [key]: value?.work_activity_code,
          codeObj:value,
          description: value?.work_activity_desc,
          
          ...({ ...defaultValue }),
        },
      }));
    } else if (
      key == "guarantee" ||
      key == "principal" ||
      key == "post" ||
      key == "basic"
    ) {
      let obj = {
        guarantee:0,
        principal:0,
        post:0,
        basic:0,
        [key]: value,
      };     
        if (!isNaN(value) && value.length <= 3) {         
          this.setState((prevState) => ({
            workActvity: {
              ...prevState?.workActvity,
              ...obj,
            },
          }));       
        } else {         
          return false;          
        }   
    } else if (key === "contract_date") {
      if(value){
        let perkToDisplay=this.state.perksList?.find((item)=>item.contract_date==value && item.memo_type==="Compensation");
        this.setState({
          perkToDisplay:perkToDisplay
        })
        
      }
      this.setState({ showEditWorkActivitySummary: true,showNewWorkActivitySummary:false });
      const contractDetails = this.state?.constractStartOptions?.filter(
        (i) => i?.value == value
      )?.[0]?.obj || null;
      this.getContractDetails(contractDetails?.performer_deal_contract_id);
      this.setState((prevState) => ({
        workActvity : {
          ...prevState?.workActvity,
          [key]: value,
          old_performer_deal_contract_id : this.state?.workActvity?.performer_deal_contract_id,
          performer_deal_contract_id: contractDetails?.performer_deal_contract_id,         
        },
        selectedStartDate:value
      }))
    }
    else {
      this.setState((prevState) => ({
        workActvity: {
          ...prevState?.workActvity,
          [key]: value,
        },
      }));
    }

    // if (key == "contract_date") {
    //   this.setState({ showEditWorkActivitySummary: true,showNewWorkActivitySummary:false });
    //   const contractDetails = this.state?.constractStartOptions?.filter(
    //     (i) => i?.value == value
    //   )?.[0]?.obj || null;
    //   this.getContractDetails(contractDetails?.performer_deal_contract_id);
    //   this.setState((prevState) => ({
    //     workActvity : {
    //       ...prevState?.workActvity,
    //       [key]: value,
    //       performer_deal_contract_id: this.state?.contractDetails?.performer_deal_contract_id
    //     }
    //   }))
      
    // }
  }
  autoPopulateguaranteePrincipalPostBasicValues(value, dailyWeeklyFlag) {
    let {
      guarantee,
      principal_free: principal,
      post_free: post,
      basic,
    } = value;
    if(dailyWeeklyFlag==="Daily"){
      guarantee = guarantee ? parseInt(guarantee) : 0;
      principal = principal ? parseInt(principal) : 0;
      post = post ? parseInt(post) : 0;
      basic = basic ? parseInt(basic) : 0;
    }
    if(dailyWeeklyFlag==="Weekly"){
      guarantee = guarantee ? (parseInt(guarantee))*5 : 0;
      principal = principal ? (parseInt(principal))*5 : 0;
      post = post ? (parseInt(post))*5 : 0;
      basic = basic ? (parseInt(basic))*5 : 0;
    }
    let obj = {
      guarantee: 0,
      principal: 0,
      post: 0,
      basic: 0,
    };
    // if only one of value is present
      let startDate =
        getFormattedDate(
          this.state?.newWorkActivity?.contract_date
        ) || null;
      if (startDate) {
        let workActivitiesOnSelectedDate = this.state.workActvityList?.filter(item=>(item?.contract_date==this.state?.newWorkActivity?.contract_date)) ;
        let guaranteeUsed=0, principalUsed=0, postUsed=0, basicUsed=0, guaranteeDaysRemaining=0, prinicpalFreeDaysRemaining=0, postFreeDaysRemaining=0, basicFreeDaysRemaining=0;
        if(workActivitiesOnSelectedDate?.length>0){
          workActivitiesOnSelectedDate[0]?.work_activity?.map((item, i) => {
            guaranteeUsed += item?.guarantee;
            principalUsed += item?.principal;
            postUsed += item?.post;
            basicUsed += item?.basic;
          })
          if(guaranteeUsed>0){
            guaranteeDaysRemaining = guarantee - guaranteeUsed;
          }else{
            guaranteeDaysRemaining = guarantee;
          }
          if(principalUsed>0){
            prinicpalFreeDaysRemaining = principal - principalUsed;
          }else{
            prinicpalFreeDaysRemaining = principal;
          }
          if(postUsed>0){
            postFreeDaysRemaining = post - postUsed;
          } else{
            postFreeDaysRemaining = post;
          }
          if(basicUsed>0){
            basicFreeDaysRemaining = basic - basicUsed;
          } else{
            basicFreeDaysRemaining = basic;
          }
        }
        let isPostProduction =
          this.state?.allStatusDatesData?.reduce((acc, i) => {
            if (
              i?.status == "Post-Production" &&
              startDate >= i?.from_date &&
              startDate <= i?.to_date
            ) {
              acc = [...acc, true];
            }
            return acc;
          }, [])?.length > 0
            ? true
            : false;
        let isPrincipalPhotography =
          this.state?.allStatusDatesData?.reduce((acc, i) => {
            if (
              i?.status == "Production" &&
              startDate >= i?.from_date &&
              startDate <= i?.to_date
            ) {
              acc = [...acc, true];
            }
            return acc;
          }, [])?.length > 0
            ? true
            : false;
          
            if(isPrincipalPhotography){
              if (guarantee > 0 && guaranteeDaysRemaining > 0) 
                {
                    obj.guarantee ="1";
                } 
                else if (principal > 0 & prinicpalFreeDaysRemaining > 0) 
                {   
                  obj.principal ="1";
                    
                }
                else if (basicFreeDaysRemaining > 0)
                {   // basic free days can be allocated to any performer with ability to have principal or post free days
                  obj.basic ="1";
                }
                obj.guarantee ="1";
            }
            else if(isPostProduction){
              if (guarantee > 0 && guaranteeDaysRemaining > 0) 
                {
                    obj.guarantee ="1";
                } 
                else if (post > 0 & postFreeDaysRemaining > 0) 
                {   
                  obj.post ="1";
                    
                }
                else if (basicFreeDaysRemaining > 0)
                {   // basic free days can be allocated to any performer with ability to have principal or post free days
                  obj.basic ="1";
                }
                obj.guarantee ="1";
            }else{
              if (guarantee > 0 && guaranteeDaysRemaining > 0){
                    obj.guarantee ="1";
                } 
                else if (principal > 0 & prinicpalFreeDaysRemaining > 0){   
                    obj.principal ="1";  
                }
                else if (post > 0 & postFreeDaysRemaining > 0){   
                  obj.post ="1"; 
                }
                else if (basicFreeDaysRemaining > 0){   // basic free days can be allocated to any performer with ability to have principal or post free days
                  obj.basic ="1";
                }
                obj.guarantee ="1";
                
            }
      }
    
    return obj;
  }
  getSearchEntity(type) {
    let response = CasterService.getData(
      Constants?.CasterServiceBaseUrl +
        `/casterFeatureSearch?objectType=${type}&searchString=null&performer_deal_id=${this.props?.performer_deal_id}`
    ).then(
      (response) => {
        if (response?.data?.error) {
          console.log("error from search", repsonse?.data?.error);
        } else {
          if (type == "WORK_ACTIVITY") {
            let formattedList = response?.data?.map((i) => ({
              value: i,
              text: i?.work_activity_code,
              obj: i,
            }));
            this.setState({ codeOptions: formattedList });
          } else if (type == "PERFORMER_DEAL_CONTRACT") {
            let formattedList = response?.data?.reduce((acc, i) => {
              if (i?.can_have_wa == 1) {
                acc = [
                  ...acc,
                  {
                    value: i,
                    label: getFormattedDate(i?.contract_date),
                    obj: i,
                    showRed:true
                  },
                ];
              }
              return acc;
            }, []);
            // this.setState({ constractStartOptions: formattedList });
            return formattedList;
          }
        }
      },
      (err) => {
        console.log("Error from workactivity search", err);
      }
    );
    return response;
  }
  getContractDetails(contractId) {
    //
    CasterService.getData(
      Constants.CasterServiceBaseUrl +
        `/casterFeaturePerformerDealContract?performer_deal_id=${this.props?.performer_deal_id}&performer_deal_contract_id=${contractId}`,
      this.props.userContext?.active_tenant?.tenant_id
    ).then(
      (response) => {
        if (response?.data?.error) {
          console.log("error from search", repsonse?.data?.error);
          // this.props?.notificationComponent(true, "fail");
        } else {
          this.setState({ contractDetails: response?.data?.[0] });
        }
      },
      (err) => {
        this.setState({ contractDetails: null });
        console.log("add deal reprsentative: " + err);
      }
    );
  }
  getWorkActivity = (id = null, index=null) => {
    CasterService.getData(
      Constants.CasterServiceBaseUrl +
        `/casterFeaturePerformerDealWorkActivity?performer_deal_id=${this.props?.performer_deal_id}&performer_deal_work_activity_id=${id}`,
      this.props.userContext?.active_tenant?.tenant_id
    ).then(
      (response) => {
        if (response?.data?.error) {
          this.props?.notificationComponent(true, "fail");
        } else {
          let workactivity = { ...response?.data?.[0] };
          this.getContractDetails(workactivity?.performer_deal_contract_id);
          workactivity.contract_date =
            this.state?.constractStartOptions?.filter(
              (i) => i?.label == getFormattedDate(workactivity?.start_date)
            )?.[0]?.value || null;
          workactivity.work_date = getFormattedDate(
            workactivity?.work_date,
            true
          );
          workactivity.code =
            {
              ...this.state?.codeOptions?.filter(
                (i) => i?.text == workactivity?.code)?.[0]?.value,
              text: workactivity?.code,
            } || null;
            workactivity.guarantee = workactivity?.guarantee ? { text: workactivity?.guarantee?.toString()} : 0
            workactivity.principal = workactivity?.principal ? { text: workactivity?.principal?.toString()} : 0
            workactivity.post = workactivity?.post ? { text: workactivity?.post?.toString()} : 0
            workactivity.basic = workactivity?.basic ? { text: workactivity?.basic?.toString()} : 0
          this.setState({ workActvity: workactivity, isEdit: true , workActivityIndex:index});
        }
      },
      (err) => {
        this.props?.notificationComponent(true, "fail");
        console.log("add deal reprsentative: " + err);
      }
    );
  };
  getWorkActivityList = async () => {
    this.setState({ isActivityListFetching: true });
    let workActivityExtractList = [];
    let contractOptions  = await this.getSearchEntity("PERFORMER_DEAL_CONTRACT");
    CasterService.getData(
      Constants.CasterServiceBaseUrl +
        `/casterFeaturePerformerDealWorkActivity?performer_deal_id=${this.props?.performer_deal_id}`,
      this.props.userContext?.active_tenant?.tenant_id
    ).then(
      (response) => {
        this.setState({ isActivityListFetching: false });
       
        if (response?.data?.error) {
          console.log("error from search", repsonse?.data?.error);
          // this.props?.notificationComponent(true, "fail"); 
        } else {
          let constractStartOptions = contractOptions?.length > 0 ? JSON.parse(
            JSON.stringify(contractOptions)
          ) : [];
          constractStartOptions = constractStartOptions?.map((i) => {
            i.showRed = response?.data?.[0]?.contracts?.length > 0 ?
              (
                (response?.data?.[0]?.contracts?.findIndex((j) => (getFormattedDate(j?.contract_date) == i?.label) && (j?.work_activity.length > 0)) != -1) ? false : true)
              : true;
            i.label !== undefined ? i.label = getFormattedDate(i?.label) + (i?.value?.void == 1 ? " VOIDED" : "") : null;
            i.value = i.value.contract_date;
            return i;
          });
          response?.data?.[0]?.contracts?.forEach(element => {
            element?.work_activity?.forEach(con => {
                con["deleteSelected"] = false;                
            })
        });
          this.setState({
            workActvityList: response?.data?.[0]?.contracts,
            finalTotal: response?.data?.[0],
            constractStartOptions: constractStartOptions,
          });
          this.calculateActivity();
        }
      },
      (err) => {
        this.setState({ isActivityListFetching: false });
        this.props?.notificationComponent(true, "fail");
        console.log("add deal reprsentative: " + err);
      }
    );
  };

  formatPostJson(postJson) {
    postJson.code = postJson?.codeObj?.work_activity_code;
    
    postJson.guarantee = Number(postJson?.guarantee) || 0;
    postJson.principal =  Number(postJson?.principal) || 0;
    postJson.post = Number(postJson?.post) || 0;
    postJson.basic = Number(postJson?.basic) || 0;
    postJson.contract_date = postJson?.contract_date;
    postJson.performer_deal_id = this.props?.performer_deal_id;
    postJson.performer_deal_contract_id =
      postJson?.performer_deal_contract_id ||
      this.state?.contractDetails?.performer_deal_contract_id ||
      null;
    return postJson;
  }

  validateWorkActivity(postJson) {
    let value = false;
    value = postJson?.contract_date;
    if (
      this.state?.contractDetails?.show_as_value == "Weekly" &&
      !postJson?.performer_deal_work_activity_id &&
      postJson?.code == "SW" &&
      this.state?.contractDetails?.is_finish == 0
    ) {
      value = false;
      this.setState({ showFinishCodeError: true });
    } else {
      this.setState({ showFinishCodeError: false });
    }
    return value;
  }

  editWorkActivity(workactivity, index, performerIndex) {
    workactivity['codeObj'] =
      {
        ...this.state?.codeOptions?.filter(
          (i) => i?.text == workactivity?.code)?.[0]?.value,
        text: workactivity?.code,
      } || null;
    //workactivity?.basic ? { text: workactivity?.basic?.toString()} : 0
    const updateWorkActivity = [...this.state?.workActvityList];
   
    workactivity['guarantee'] = workactivity?.guarantee? (workactivity?.guarantee) : '' 
    workactivity['principal'] = workactivity?.principal? (workactivity?.principal) : '' 
    workactivity['post'] = workactivity?.post? (workactivity?.post) : '' 
    workactivity['basic'] = workactivity?.basic? (workactivity?.basic) : '' 
    updateWorkActivity?.map((performerDeal) => {
      performerDeal?.work_activity?.filter(workActivity => {
        if (workActivity.currentSelection === true) {
         return workActivity.currentSelection = false
        }
      });
    })
    updateWorkActivity[performerIndex].work_activity[index]['currentSelection'] = true;
    this.setState({showSaveCancelButtons: true, workActvity: workactivity, isEdit: true, workActivityIndex: index, workActvityList: updateWorkActivity, newWorkActivity: [] });



  }
  
  saveWorkActivities() {
    const saveWorkActivity = [...this.state?.workActvityList];
    let workActivityListToSave = [];
    saveWorkActivity?.map((performerDeal) => {
      performerDeal?.work_activity?.filter(workActivity => {
        if (workActivity.modified === true) {
          const workActivityToSave = this.formatPostJson(workActivity);
          workActivityListToSave.push(workActivityToSave);
        }
      });
    })
    const payload = [...workActivityListToSave, ...this.state.deleteWorkActivity];
    let payloadObj = {
      performer_deal_work_activity: []
    };
    payloadObj.performer_deal_work_activity=payload;
    this.setState({postingWorkActivity:true})
    CasterService.postDataParams(
      Constants.CasterServiceBaseUrl +
      `/casterFeaturePerformerDealWorkActivity`,
      payloadObj
    ).then(
      (response) => {
        this.setState({ isPosting: false, showAddLoader: false,postingWorkActivity: false });
        if (response?.data?.error) {
          console.log("error from search", response?.data?.error);
          this.props?.notificationComponent(true, "fail");
        } else {
          this.getWorkActivityList();
          this.getSearchEntity("WORK_ACTIVITY");
          this.getStatusDatesData();
          this.setState({
            workActvity: workActivityConfig,
            contractDetails: null,
            isEdit: false,
            showSaveCancelButtons: false
          });
          this.props?.notificationComponent(true, "success");
          this.props?.updateDiscardChangesFlag(false);
          this.props?.updateDiscardChangesFlagParent(false);
        }
      },
      (err) => {
        this.setState({ isPosting: false, showAddLoader: false,postingWorkActivity:false });
        this.props?.notificationComponent(true, "fail");
        console.log("add deal reprsentative: " + err);
      }
    );
  }

  calculateActivity() {
    const updateWorkActivity = [...this.state?.workActvityList];
    const calculateActivities = [];
    updateWorkActivity?.map((performerDeal, index) => {
      if(performerDeal?.work_activity?.length > 0){
      const objPerformerIndex = performerDeal?.work_activity?.map(activity => {
        calculateActivities.push(
          {
            indexPerformer: index,
            guarantee:  Number(activity?.guarantee),
            principal:  Number(activity?.principal),
            post:  Number(activity?.post),
            basic: Number(activity?.basic),
          }
        )
      })
    }else{
      calculateActivities.push(
        {
          indexPerformer: index,
          guarantee: 0,
          principal: 0,
          post: 0,
          basic: 0,
        }
      )
    }
    })
    const summed = calculateActivities.reduce((acc, cur, i) => {
      const item = i > 0 && acc.find(({ indexPerformer }) => indexPerformer === cur.indexPerformer)
      if (item) {
        item.guarantee += cur.guarantee;
        item.principal += cur.principal;
        item.post += cur.post;
        item.basic += cur.basic;
      }
      else { acc.push({ indexPerformer: cur.indexPerformer, guarantee: cur.guarantee, principal: cur.principal, post: cur.post, basic: cur.basic }); } // don't push cur here
      return acc;
    }, [])
    this.setState({ sumActivities: summed });
    const totalSumActivities = [...this.state?.sumActivities];
    const sumActivity ={guarantee:0,principal:0,post:0,basic:0};
    summed.forEach(activ=>{
      sumActivity.guarantee += activ.guarantee;
      sumActivity.principal += activ.principal;
      sumActivity.post += activ.post;
      sumActivity.basic += activ.basic;
    })
    this.setState({totalSumActivities:sumActivity})
  }

  refreshWorkActivityList() {
    const updateWorkActivity = [...this.state?.workActvityList];
    updateWorkActivity?.map((performerDeal) => {
      performerDeal?.work_activity?.filter(workActivity => {
        if (workActivity.currentSelection === true) {
          return workActivity.currentSelection = false
        }
      });
    })
    this.setState({ isEdit: false, workActvityList: updateWorkActivity });
  }

  submitWorkActivity(performer_deal_contract_id = null, performer_deal_work_activity_id = null, mode = null,DealIndex = null,rowIndex = null) {
    let workActivityListSave = [];
    if (performer_deal_work_activity_id != null) {
      let selectedWorkActivity = this.state?.workActvity;
      selectedWorkActivity['work_date'] = getFormattedDate(this.state?.workActvity?.work_date, true);
      selectedWorkActivity['modified'] = true;
      selectedWorkActivity['currentSelection']= false;
      selectedWorkActivity = this.formatPostJson(selectedWorkActivity)
      const updateWorkActivity = [...this.state?.workActvityList];
      updateWorkActivity?.map((performerDeal) => {
       // if(performerDeal.contract_date === this.state.workActvity.contract_date){
        const objPerformerIndex = performerDeal?.work_activity?.findIndex(
          objPerformer => objPerformer.performer_deal_work_activity_id === this.state?.workActvity?.performer_deal_work_activity_id
           && (objPerformer.performer_deal_work_activity_id != null));
        if (objPerformerIndex === -1) {
          return;
        }
        if(performerDeal.work_activity[objPerformerIndex].contract_date === this.state.workActvity.contract_date){
          return performerDeal.work_activity[objPerformerIndex] = selectedWorkActivity;
        }else{
          performerDeal.work_activity.splice(objPerformerIndex, 1);
         const ContractDateIndexValue = updateWorkActivity.findIndex( contractDateIndex => contractDateIndex.contract_date === this.state.workActvity.contract_date);
          if (ContractDateIndexValue === -1) {
            return;
          }
         
          return updateWorkActivity[ContractDateIndexValue].work_activity.push(selectedWorkActivity);
        }
        
      //}
      });

      this.setState({
        workActvityList: updateWorkActivity,
        isEdit: false,
        workActivityIndex: null,
        workActivityListSave: workActivityListSave,
        showSaveCancelButtons: true
      })
    } else if (performer_deal_work_activity_id === null && performer_deal_contract_id != null && mode === 'edit') {
      let selectedWorkActivity = this.state?.workActvity;
      selectedWorkActivity['modified'] = true;
      selectedWorkActivity['work_date'] = getFormattedDate(this.state?.workActvity?.work_date, true);
      selectedWorkActivity['currentSelection']= false;
      selectedWorkActivity = this.formatPostJson(selectedWorkActivity)
      const updateWorkActivity = [...this.state?.workActvityList];
      if(this.state?.workActvity?.old_performer_deal_contract_id){
        updateWorkActivity?.map((performerDeal) => {
          const objPerformerIndex = performerDeal?.work_activity?.findIndex(objPerformer =>            
            (objPerformer.performer_deal_work_activity_id === null) && 
             (this.state?.workActvity?.old_performer_deal_contract_id === performerDeal?.performer_deal_contract_id)           
          )
           
          if (objPerformerIndex === -1) {
            return;
          }
          performerDeal.work_activity.splice(objPerformerIndex, 1);
        });
      }else{
        // updateWorkActivity?.map((performerDeal) => {
        //   const objPerformerIndex = performerDeal?.work_activity?.findIndex(objPerformer =>            
        //     (objPerformer.performer_deal_work_activity_id === null) && 
            
        //    (this.state?.workActvity?.performer_deal_contract_id === performerDeal?.performer_deal_contract_id)
           
        //   )
           
        //   if (objPerformerIndex === -1) {
        //     return;
        //   }       
        //   performerDeal[DealIndex].work_activity.splice(rowIndex, 1);
        // });
        updateWorkActivity[DealIndex].work_activity.splice(rowIndex, 1);
      }   
      const ContractDateIndexValue = updateWorkActivity.findIndex( contractDateIndex => contractDateIndex.contract_date === this.state.workActvity.contract_date);
      if (ContractDateIndexValue === -1) {
        return;
      }     
      updateWorkActivity[ContractDateIndexValue].work_activity.push(selectedWorkActivity);
      this.setState({
        workActvityList: updateWorkActivity,
        isEdit: false,
        workActivityIndex: null,
        workActivityListSave: workActivityListSave,
        showSaveCancelButtons: true
      })
    }   
    else {
      if(!this.state?.newWorkActivity.contract_date){
        this.setState({contractDateRequired:true});
        return false;
      }
      let addNewWorkActivity = this.state?.newWorkActivity;
      addNewWorkActivity['modified'] = true;
      addNewWorkActivity['performer_deal_work_activity_id'] = null;
      addNewWorkActivity = this.formatPostJson(addNewWorkActivity);
      const addWorkActivity = [...(this.state?.workActvityList || [{ work_activity: [] }])];
      const performerIndex = addWorkActivity?.findIndex(performerDeal => performerDeal.performer_deal_contract_id === addNewWorkActivity.performer_deal_contract_id);      
      if (performerIndex >= 0) {
        addWorkActivity[performerIndex].work_activity.push(addNewWorkActivity);
      }else{
        addWorkActivity[0].work_activity.push(addNewWorkActivity);
      }
      this.setState({
        workActvityList: addWorkActivity,
        isEdit: false,
        workActivityIndex: null,
        newWorkActivity: [],
        showSaveCancelButtons: true,
        selectedStartDate:null
      });
    }
    this.props?.updateDiscardChangesFlag(true);
    this.props?.updateDiscardChangesFlagParent(true);
    this.setState({contractDateRequired:false});
    this.calculateActivity()
  }

  deleteActivity(workActivity, workActivityIndex, performerDealIndex) {
    workActivity.is_delete = 1;
    let deleteWorkActivity = [...this.state?.deleteWorkActivity];
    const updateWorkActivity = [...this.state?.workActvityList];
    
    if(updateWorkActivity[performerDealIndex].work_activity[workActivityIndex]?.performer_deal_work_activity_id){
      updateWorkActivity[performerDealIndex].work_activity[workActivityIndex]['is_delete'] = 1;
      deleteWorkActivity.push(updateWorkActivity[performerDealIndex].work_activity[workActivityIndex]);
    }    
    updateWorkActivity[performerDealIndex].work_activity.splice(workActivityIndex, 1);
    this.props?.updateDiscardChangesFlag(true);
    this.props?.updateDiscardChangesFlagParent(true);
    this.setState({
      workActvityList: updateWorkActivity,
      isEdit: false,
      workActivityIndex: null,
      newWorkActivity:[],
      deleteWorkActivity : deleteWorkActivity,
      showSaveCancelButtons: true
    });
    this.calculateActivity();
  }
  onConfirmingWorkActivityCancellation = () => {
        this.setState({ showCancelActvityModal: false,showSaveCancelButtons: false, selectedStartDate:null, newWorkActivity:{ ...workActivityConfig } },
        ()=>{this.getWorkActivityList();
        });
}
  onCancelWorkActivity = (newOrGlobal) => {
    this.setState({ showCancelActvityModal: true, newOrGlobal });
}
  onExistingWorkActivityChange = ( key, value) => {
    if (key == "code") {
      const contractDetails = this.state?.constractStartOptions?.filter(
        (i) => i?.value == this.state?.newWorkActivity?.contract_date
      )?.[0]?.obj || null;
      let defaultValue = {};
      if (contractDetails?.as_value == "Daily") {
        defaultValue = this.autoPopulateguaranteePrincipalPostBasicValues(value,"Daily");
      }
      if(contractDetails?.as_value == "Weekly"){
        defaultValue = this.autoPopulateguaranteePrincipalPostBasicValues(value,"Weekly");
      }
      this.setState((prevState) => ({
        newWorkActivity: {
          ...prevState?.newWorkActivity,
          [key]: value?.work_activity_code,
          codeObj:value,
          principal: value?.principal_free,
          guarantee: value?.guarantee,
          post:value?.post_free,
          basic: value?.basic,
          description: value?.work_activity_desc,
          ...({ ...defaultValue }),
        },
      }));
    } else if (
      key == "guarantee" ||
      key == "principal" ||
      key == "post" ||
      key == "basic"
    ) {
      let obj = {
        guarantee: 0,
        principal: 0,
        post: 0,
        basic: 0,
        [key]: value,
      };     
      if (!isNaN(value) && value.length <= 3) {
        this.setState((prevState) => ({
          newWorkActivity: {
            ...prevState?.newWorkActivity,
            ...obj,
          },
        }));     
      } else {
        return false;        
      }
    } else if (key === "contract_date") {
      if(value){
        let perkToDisplay=this.state.perksList?.find((item)=>item.contract_date==value && item.memo_type==="Compensation");
        this.setState({
          perkToDisplay:perkToDisplay
        })
        this.setState((prevState) => ({
          newWorkActivity : {
            ...workActivityConfig,
            [key]: value,
          },
          selectedStartDate:value
        }))
      }else{
        this.setState((prevState) => ({
          newWorkActivity: {
            ...workActivityConfig,
          },
          selectedStartDate:null
        }));
      }
    }
    else {
      this.setState((prevState) => ({
        newWorkActivity: {
          ...prevState?.newWorkActivity,
          [key]: value,
        },
      }));
    }

    if (key == "contract_date") {     
      this.setState({ showEditWorkActivitySummary: false,showNewWorkActivitySummary:true,contractDateRequired:false });
     const contractDetails = this.state?.constractStartOptions?.filter(
        (i) => i?.value == value
      )?.[0]?.obj || null;
      this.getContractDetails(contractDetails?.performer_deal_contract_id);
    }
}

  workActivityInputField(mode,contract_date = null){    
    return (
      <>
      <div className="addWorkActivityRowContainer">
      <MDBRow  className="tableHeader">
        <MDBCol  class="col1 lessPadding">
          <SelectField
            id="contractStartSelect"
            options={this.state?.constractStartOptions}
            value={(mode ==='new' ? this.state?.newWorkActivity?.contract_date : (this.state?.workActvity?.contract_date) ? (this.state?.workActvity?.contract_date) : contract_date) }
            label={(mode ==='new') ?  'Start Date':''}
            isMandatory={true}
            onChange={(e,value) =>
              {
              mode==='new' ?  this.onExistingWorkActivityChange("contract_date", e.target?.value) :
               this.onChange ("contract_date", e.target?.value)
              }
            }
          />
        </MDBCol>
        <MDBCol  class="col2 lessPadding">
          <DateField
            id="workDate"
            value={(mode ==='new' ? this.state?.newWorkActivity?.work_date : getFormattedDate(this.state?.workActvity?.work_date, true)) } //"2023-12-20"
            label={(mode ==='new') ? 'Work Date':''}
            // isMandatory={true}
            onChange={(e) =>
              mode==='new' ?  this.onExistingWorkActivityChange("work_date", e.target?.value) :
              this.onChange("work_date", e.target?.value)
            }
          />
        </MDBCol>
        <MDBCol  class="col3 lessPadding">
          <SearchSelectField
            id="codeSelect"
            options={this.state?.codeOptions}
            value={(mode ==='new' ? this.state?.newWorkActivity?.codeObj :this.state?.workActvity?.codeObj) }
            label={mode ==='new' ? 'Activity':''}
            width="100%"
            // isMandatory={true}
            onChange={(e, value) =>{
              if(!value){
                if(mode === 'new'){
                  this.onExistingWorkActivityChange("code",null);
                  this.onExistingWorkActivityChange("description","");
                }else{
                  this.onChange("code",null);
                  this.onChange("description","");
                } 
              }
                mode==='new' ?  this.onExistingWorkActivityChange("code", {
                  ...value.value,
                  ...{ text: value?.text },
                  guarantee:this.state?.contractDetails?.guarentee_period,
                  principal_free:this.state?.contractDetails?.principal_free_period,
                  post_free:this.state?.contractDetails?.post_free_period,
                  basic:this.state?.contractDetails?.basic_free_period,
                }) :
                this.onChange("code", {
                  ...value.value,
                  ...{ text: value?.text },
                })
              }
            }            
          />
        </MDBCol>
        <MDBCol class="col4 lessPadding">
          <BasicTextField
            id="description"
            value={(mode ==='new' ? this.state?.newWorkActivity?.description :this.state?.workActvity?.description) }
            label={mode ==='new' ? 'Description':''}
            onChange={(e) =>
              mode==='new' ?  this.onExistingWorkActivityChange("description", e.target?.value) :
              this.onChange("description", e.target?.value)
            }
          />
        </MDBCol>
        
         
          <MDBCol class="col5 lessPadding">
          <BasicTextField
            id="guaranteeInp"
            limit={3}
            options={WorkActivityGuaranteeOptions || []}
            value={(mode ==='new' ? this.state?.newWorkActivity?.guarantee : this.state?.workActvity?.guarantee)}
            label={mode ==='new' ? 'Guarantee':''}
            width="auto"
            // searchSelect={true}
            onChange={(e, value) => {
              mode==='new' ? this.onExistingWorkActivityChange("guarantee", e.target?.value) :
               this.onChange("guarantee",e.target?.value)  
            }
                    
            }
          />
          </MDBCol>
          <MDBCol class="col6 lessPadding">
          <BasicTextField
            id="PrincipalInp"
            options={WorkActivityGuaranteeOptions || []}
            value={(mode ==='new' ? this.state?.newWorkActivity?.principal :  this.state?.workActvity?.principal)}
            label={mode ==='new' ? 'Principal Free':''}
            width="auto"
            // searchSelect={true}
            onChange={(e, value) => mode==='new'?
            this.onExistingWorkActivityChange("principal", e.target?.value ) : this.onChange("principal", e.target?.value)  
            }
          />
          </MDBCol>
          <MDBCol class="col7 lessPadding">
          <BasicTextField
            id="postInp"
            options={WorkActivityBasicOptions || []}
            value={(mode ==='new' ? this.state?.newWorkActivity?.post : this.state?.workActvity?.post)}
            label={mode ==='new' ? 'Post Free':''}
            width="auto"
            onChange={(e, value) => mode==='new'?
            this.onExistingWorkActivityChange("post", e.target?.value ) : this.onChange("post", e.target?.value)  
            }
          />
    </MDBCol>
          <MDBCol class="col8 lessPadding">
          <BasicTextField
            id="basicInp"
            options={WorkActivityBasicOptions || []}
            value={(mode ==='new' ? this.state?.newWorkActivity?.basic : this.state?.workActvity?.basic)}
            label={mode ==='new' ? 'Free':''}
            width="auto"
            onChange={(e, value) => mode==='new'?
            this.onExistingWorkActivityChange("basic", e.target?.value ) : this.onChange("basic", e.target?.value)  
            }
          />
          </MDBCol>
        
        
    
        <MDBCol   class="col9 lessPadding">
          <BasicTextField
            id="textArea"
            value={(mode ==='new' ? this.state?.newWorkActivity?.text :this.state?.workActvity?.text)}
            label={mode ==='new' ? 'Notes':''}          
            onChange={(e) => mode==='new' ?  this.onExistingWorkActivityChange("text", e.target?.value) : this.onChange("text", e.target?.value)}
          />
        </MDBCol>

        <MDBCol   class="col10 noPadding">
        <div className="addBtnWorkActivity">
        {this.state?.showAddLoader ? (
          <CircularProgress size={20} />
        ) : (
          <>
          {mode !=='new' &&
            <MDBIcon
              id="addWorkAct"
              className="tealText mr-2 cursorPointer"
              icon={"save"}
              onClick={() => this.submitWorkActivity(this.state?.workActvity?.performer_deal_contract_id, this.state?.workActvity?.performer_deal_work_activity_id,mode )}
            />
        }
         {mode ==='new' &&
                    <Tooltip clickable arrow componentsProps={{
                      tooltip: {
                        sx: {
                          maxWidth: '500px',
                          fontSize: '13px',
                        },
                      },
                    }}
                      title={"Save"}>
                      <span>
                        <MDBIcon
                          id="addWorkAct"
                          className="tealText mr-2 cursorPointer"
                          icon={"plus-circle"}
                          onClick={() => this.submitWorkActivity(null, null, mode)}
                        />
                      </span>
                    </Tooltip>
      }
                  <Tooltip clickable arrow componentsProps={{
                    tooltip: {
                      sx: {
                        maxWidth: '500px',
                        fontSize: '13px',
                      },
                    },
                  }}
                    title={"Cancel"}>
                      <span>
                    <MDBIcon
                      icon={this.state.showAddLoader ? <CircularProgress size={20} /> : "times"}
                      className="cursorPointer"
                      onClick={() =>
                        mode === 'new' ? this.setState({ newWorkActivity: workActivityConfig, contractDateRequired: false, selectedStartDate:null }) : this.refreshWorkActivityList()
                      }
                    /></span>
                  </Tooltip>
          </>
        )}
      </div>
      </MDBCol>
        <MDBCol md={12}  className="errorTexts lessPadding">
        { mode ==='new' && this.state.contractDateRequired && <div className="errorText">Please complete this mandatory field</div>}
          {this.state?.isPosting &&
            (!workActvity?.contract_date ||
              !workActvity?.work_date ||
              !workActvity?.code) && (
              <div className="errorText">
                Please fill all required field!!
              </div>
            )}
          {this.state?.isPosting && this.state?.showFinishCodeError && (
            <div className="errorText">
              Cannot start new activity before finish previous one!!
            </div>
          )}
        </MDBCol>
      </MDBRow>
      
    </div>    
    </>
    );
  }

  getWorkActivitySummary(mode){
    let contractDetails;
    if(mode==='edit'){
       contractDetails = this.state?.constractStartOptions?.filter(
        (i) => i?.value == this.state?.workActvity?.contract_date
      )?.[0]?.obj || null;
    }else{
       contractDetails = this.state?.constractStartOptions?.filter(
        (i) => i?.value == this.state?.newWorkActivity?.contract_date
      )?.[0]?.obj || null;
    }
  

    return (
      <MDBRow className="marginBottom font-weight-bold blueHeadingText">
      <MDBCol md="12">
        {/* [CurrencySymbol][Rate]/[day/week] - [x Guaranteed] [days/weeks] guarantee. [x PrincipalFree] [days/weeks] principal free. [x PostFreeDays] [days/weeks] Post Free. [x BasicFree] [days/weeks] Basic Free. */}
        {this.state.selectedStartDate && (
          <div>
            <MDBTooltip wrapperProps={{ color: 'secondary' }} title={ReactHtmlParser(this.state.perkToDisplay?.memo_text)}>
              <span>{ReactHtmlParser(this.state.perkToDisplay?.memo_text)}</span>
            </MDBTooltip>
          </div>
        )} 
      </MDBCol>
    </MDBRow>
    )
  }
  render() {
    let cancelWorkActivityMessage = "Confirm Work Activity Cancellation";
    let confirmCancelNewWorkActivityMsg = "Are you sure to cancel unsaved work activity details?";
    let discardChangeMessage = "Are you sure you want to discard changes ?";
    let discardMessageTitle ="Discard Work Activity Changes";    
   // this.setState({sumActivities : []});
    return (
      <>
      {this.state.showSaveCancelButtons === true ?(
        <div className="d-flex justify-content-end mb-2">
          <div style={{marginRight:'8px'}}>
               <BasicButton
               type="inline"
                variant="contained"
                text={this.state.postingWorkActivity?<CircularProgress color="inherit" size={20} /> :"SAVE"}
                onClick={() => this.saveWorkActivities()}
                icon={"save"} />
                </div>
                <BasicButton
                 type="inline"
                 variant="outlined"
                 text={"Cancel"}
                 icon="times"
                 onClick={() => this.onCancelWorkActivity("global")}
                />
              </div>)
        :null}
      <div className="WorkActivityContainer">  
      <MessageModal
      open ={this.props?.showdiscardNotification || this.state.showCancelActvityModal || this.props?.showdiscardNotificationParent || false}
      title ={this.state.showCancelActvityModal && cancelWorkActivityMessage || this.props?.showdiscardNotification && discardMessageTitle || this.props?.showdiscardNotificationParent && discardMessageTitle }
      primaryButtonIcon ={this.state.showCancelActvityModal}
      secondaryButtonIcon="times"
      message ={this.state.showCancelActvityModal && confirmCancelNewWorkActivityMsg || this.props?.showdiscardNotification && discardChangeMessage || this.props?.showdiscardNotificationParent && discardChangeMessage }
      primaryButtonText ={this.state.showCancelActvityModal && "Confirm" || this.props?.showdiscardNotification && "Discard" || this.props?.showdiscardNotificationParent && "Discard"}
      secondaryButtonText ={"Cancel"}
      onConfirm ={this.state.showCancelActvityModal && (() => this.onConfirmingWorkActivityCancellation()) || (this.props?.showdiscardNotification && (() => this.props?.refreshDiscardChangesFlag("confirm"))) ||  (this.props?.showdiscardNotificationParent && (() => this.props?.refreshDiscardChangesFlagParent("confirm")))}
      handleClose ={this.state.showCancelActvityModal && ((e) => this.setState({ showCancelActvityModal: false }))
      || (this.props?.showdiscardNotification && (() => this.props?.refreshDiscardChangesFlag())) ||  (this.props?.showdiscardNotificationParent && (() => this.props?.refreshDiscardChangesFlagParent()))}
      />
        {!this?.props?.summary && (
          <>
          <> {this.workActivityInputField('new')} </>
         
          {this.state.showNewWorkActivitySummary && this.getWorkActivitySummary('new')}
          
          </>
        )}
         {this.props?.summary && this.state?.workActvityList?.length == 0 ? (
                <div>No Work activity added!</div>
              ) : <>
             
        <MDBRow className="tableHeader">
          <MDBCol class="col1 lessPadding">
            <div className="bold">Start Date</div>
          </MDBCol>
          <MDBCol class="col2 lessPadding">
            <div className="bold">Work Date</div>
          </MDBCol>
          <MDBCol class="col3 lessPadding">
            <div className="bold">Activity</div>
          </MDBCol>
          <MDBCol class="col4 lessPadding">
            <div className="bold">Description</div>
          </MDBCol>
          <MDBCol class="col5 lessPadding">
            <div className="bold">Guarantee</div>
          </MDBCol>
          <MDBCol class="col6 lessPadding">
            <div className="bold">Principal Free</div>
          </MDBCol>
          <MDBCol class="col7 lessPadding">
            <div className="bold">Post Free</div>
          </MDBCol>
          <MDBCol class="col8 lessPadding">
            <div className="bold">Free</div>
          </MDBCol>
          <MDBCol class="col9 lessPadding">
            <div className="bold">Notes</div>
          </MDBCol>
          <MDBCol class="col10 ">
           <div className="bold"></div>
          </MDBCol>
        </MDBRow>

        <div className="workActivityListSection">       
                
          {this.state?.isActivityListFetching ? (
            <CircularProgress size={30} />
          ) : (
            <>
             
                  {this.state?.workActvityList?.map((item, index) => (
                    <>
                    {item?.work_activity?.length > 0 &&   <div className={`contractRow ${index}`}>
                        {item?.work_activity?.map((activity, rowWorkActivityIndex) => (                         
                        <div> 
                          { (activity.is_delete != 1) && 
                          <MDBRow className={"striped" +((this.state.workActivityIndex ===rowWorkActivityIndex && this.state.performerIndex === index) ? ' hidee' : '') }>
                            <MDBCol class="col1 lessPadding">
                         {activity.currentSelection !=true && <div>{getFormattedDate(activity?.contract_date)}</div>}
                         {activity.currentSelection ==true &&
                                  <div>  <SelectField
                                    id="contractStartSelect"
                                    options={this.state?.constractStartOptions}
                                    value={(this.state?.workActvity?.contract_date) ? (this.state?.workActvity?.contract_date) : item?.contract_date }           
                                    label={''}
                                    isMandatory={true}
                                    onChange={(e, value) => {
                                      this.onChange("contract_date", e.target?.value)
                                    }
                                    }
                                  />
                                  </div>}
                            </MDBCol>
                            <MDBCol class="col2 lessPadding">
                            {activity.currentSelection !=true &&  <div>{getFormattedDate(activity?.work_date)}</div>}
                            {activity.currentSelection ==true && <div>
                                  <DateField
                                    id="workDate"
                                    value={getFormattedDate(this.state?.workActvity?.work_date, true)} //"2023-12-20"
                                    label={ ''}
                                    onChange={(e) =>                                    
                                        this.onChange("work_date", e.target?.value)
                                    }
                                  />
                                </div>}
                            </MDBCol>
                            <MDBCol class="col3 lessPadding">
                            {activity.currentSelection !=true &&  <div>
                                {activity?.code || null}{" "}
                              </div>}
                              {activity.currentSelection ==true &&  <div>
                                  <SearchSelectField
                                    id="codeSelect"
                                    options={this.state?.codeOptions}
                                    value={this.state?.workActvity?.codeObj}
                                    label={''}
                                    width="100%"
                                    // isMandatory={true}
                                    onChange={(e, value) => {
                                      if (!value) {
                                      
                                          this.onChange("code", null);
                                          this.onChange("description", "");
                                       
                                      }
                                     
                                        this.onChange("code", {
                                          ...value.value,
                                          ...{ text: value?.text },
                                        })
                                    }
                                    }
                                  />
                                </div>}
                            </MDBCol>
                            <MDBCol class="col4 lessPadding" style={{"word-wrap": "break-word"}}>
                            {activity.currentSelection !=true &&    <div>{activity?.description || null}</div>}
                            {activity.currentSelection ==true &&   <div>
                                  <BasicTextField
                                    id="description"
                                    value={this.state?.workActvity?.description}
                                    label={''}
                                    onChange={(e) =>
                                        this.onChange("description", e.target?.value)
                                    }
                                  />
                                </div>}
                            </MDBCol>
                            <MDBCol class="col5 lessPadding">
                            {activity.currentSelection !=true &&   <div>{Number(activity?.guarantee ) > 0 ?(activity?.guarantee):''}</div>}
                            {activity.currentSelection ==true &&  <div>
                                  <BasicTextField
                                    id="guaranteeInp"
                                    limit={3}
                                    options={WorkActivityGuaranteeOptions || []}
                                    value={this.state?.workActvity?.guarantee }
                                    label={''}
                                    width="auto"
                                    // searchSelect={true}
                                    onChange={(e, value) => this.onChange("guarantee", e.target?.value)
                                    }
                                  />
                                </div>}
                            </MDBCol>
                            <MDBCol class="col6 lessPadding">
                            {activity.currentSelection !=true &&   <div>{Number( activity?.principal ) > 0 ?(activity?.principal):''}</div>}
                            {activity.currentSelection ==true &&  <div>
                                  <BasicTextField
                                    id="PrincipalInp"
                                    options={WorkActivityGuaranteeOptions || []}
                                    value={ this.state?.workActvity?.principal }
                                    label={ ''}
                                    width="auto"
                                    // searchSelect={true}
                                    onChange={(e, value) =>  this.onChange("principal", e.target?.value)
                                    }
                                  />
                                </div>}
                            </MDBCol>
                            <MDBCol class="col7 lessPadding">
                            {activity.currentSelection !=true &&   <div>{Number(activity?.post ) > 0 ?(activity?.post):''}</div>}
                            {activity.currentSelection ==true &&    <div>
                                  <BasicTextField
                                    id="postInp"
                                    options={WorkActivityBasicOptions || []}
                                    value={  this.state?.workActvity?.post}
                                    label={ ''}
                                    width="auto"
                                    onChange={(e, value) => this.onChange("post", e.target?.value)
                                    }
                                  />
                                </div>}
                            </MDBCol>
                            <MDBCol class="col8 lessPadding">
                            {activity.currentSelection !=true &&    <div>{Number(activity?.basic ) > 0 ?(activity?.basic):''}</div>}
                            {activity.currentSelection ==true &&   <div>
                                  <BasicTextField
                                    id="basicInp"
                                    options={WorkActivityBasicOptions || []}
                                    value={ this.state?.workActvity?.basic }
                                    label={ ''}
                                    width="auto"
                                    onChange={(e, value) =>  this.onChange("basic", e.target?.value)
                                    }
                                  />
                                </div>}
                            </MDBCol>
                            <MDBCol class="col9 lessPadding word-break white-space">
                            {activity.currentSelection !=true &&       <div>
                                  {activity?.text?.length > 120 ?
                                    <Tooltip clickable arrow componentsProps={{
                                      tooltip: {
                                        sx: {
                                          maxWidth: '500px',
                                          fontSize: '13px',
                                          bgcolor: 'common.white',
                                          border: '1px solid #6c757d',
                                          color: 'common.black',
                                          '& .MuiTooltip-arrow': {
                                            color: 'common.white',
                                            '&::before':{
                                              border: '1px solid #6c757d',
                                            }
                                          },
                                        },
                                      },
                                    }}
                                      title={activity?.text || null}>
                                      <span>{activity?.text?.substring(0, 120) + "..."} </span>
                                    </Tooltip> : <span>{activity?.text}</span>}
                                </div>}
                                {activity.currentSelection ==true &&  <div>
                                  <BasicTextArea
                                    id="textArea"
                                    value={ this.state?.workActvity?.text}
                                    label={ ''}
                                    rows="3"
                                    onChange={(e) =>  this.onChange("text", e.target?.value)}
                                  />
                                </div>}
                            </MDBCol>
                              <MDBCol class="col10 noPadding" className="iconContainer">
                                {!this.props?.summary && <div className="actionIcons">
                                {activity.currentSelection != true &&   
                                 <div class="icon"> 
                                    <Tooltip clickable arrow componentsProps={{
                                      tooltip: {
                                        sx: {
                                          maxWidth: '500px',
                                          fontSize: '13px',
                                        },
                                      },
                                    }}
                                      title={"Edit"}>
                                   <span>   <MDBIcon
                                        icon="pen"
                                        className="cursorPointer"
                                        onClick={() =>
                                          this.editWorkActivity(
                                            activity, rowWorkActivityIndex, index
                                          )
                                        }
                                      /></span>
                                    </Tooltip>
                                  </div> 
                                  }
                                  {
                                  activity.currentSelection == true &&
                                    <div class="icon">
                                      <Tooltip clickable arrow componentsProps={{
                                        tooltip: {
                                          sx: {
                                            maxWidth: '500px',
                                            fontSize: '13px',
                                          },
                                        },
                                      }}
                                        title={"Save"}>
                                      <span> <MDBIcon
                                          id="addWorkAct"
                                          className="tealText mr-2 cursorPointerm"
                                          icon={"save"}
                                          onClick={() => this.submitWorkActivity(this.state?.workActvity?.performer_deal_contract_id, this.state?.workActvity?.performer_deal_work_activity_id, 'edit',index,rowWorkActivityIndex)}
                                        /></span>
                                      </Tooltip>
                                    </div>
                                  }
                                 
                                  {activity.currentSelection == true &&
                                    <div class="icon">
                                      <Tooltip clickable arrow componentsProps={{
                                        tooltip: {
                                          sx: {
                                            maxWidth: '500px',
                                            fontSize: '13px',
                                          
                                          },
                                        },
                                      }}
                                        title={"Cancel"}>
                                        <span>  <MDBIcon
                                          icon={"times"}
                                          className="cursorPointer"
                                          onClick={() =>
                                            this.refreshWorkActivityList()
                                          }
                                        /></span>
                                      </Tooltip>
                                    </div>
                                  }
                                  {activity.currentSelection != true &&
                                    <div class="icon">
                                      <Tooltip clickable arrow componentsProps={{
                                        tooltip: {
                                          sx: {
                                            maxWidth: '500px',
                                            fontSize: '13px',
                                         
                                          },
                                        },
                                      }}
                                        title={"Remove"}>
                                        <span> <MDBIcon
                                          icon={"minus"}
                                          className="cursorPointer"
                                          onClick={() => this.deleteActivity(activity, rowWorkActivityIndex, index)}
                                        /></span>

                                      </Tooltip>
                                    </div>
                                  }


                                </div>}
                              </MDBCol>
                          </MDBRow>
  }
                            {this.state.workActvity.performer_deal_work_activity_id === activity.performer_deal_work_activity_id && this.state.isEdit &&
                              <div>
                                 {/* <MDBRow> {this.workActivityInputField('edit',item?.contract_date)}</MDBRow> */}
                                <MDBRow>  {this.state.showEditWorkActivitySummary && this.getWorkActivitySummary('edit')}</MDBRow></div>
                            }
                       </div> ))}
                      </div>}
               {item?.work_activity?.length > 0 &&
                      <MDBRow className="totalRow">
                        <MDBCol class='col1'></MDBCol>
                        <MDBCol class='col2'></MDBCol>
                        <MDBCol class='col3' className="bold"></MDBCol>
                        <MDBCol class='col4'></MDBCol>
                        <MDBCol class='col5 lessPadding'>
                          <div className="bold blueText"> 
                            <div
                              className={`${
                                this.state.sumActivities[index]?.guarantee >
                                item?.contract_guarantee_total
                                  ? "redText"
                                  : this.state.sumActivities[index]?.guarantee > 0
                                  ? "blueText"
                                  : ""
                              } "bold"`}
                            >
                              {this.state.sumActivities[index]?.guarantee || 0} 
                             &nbsp;of{" "}
                              {item?.contract_guarantee_total || 0}
                            </div>
                          </div>
                        </MDBCol>
                        <MDBCol class='col6 lessPadding'>
                          {this.state.sumActivities[index]?.principal > 0 && (
                            <div
                              className={`${
                                this.state.sumActivities[index]?.principal >
                                item?.contract_principal_total
                                  ? "redText"
                                  : this.state.sumActivities[index]?.principal > 0
                                  ? "blueText"
                                  : ""
                              } "bold"`}
                            >
                              
                             {this.state.sumActivities[index]?.principal || 0} 
                             &nbsp;of{" "}
                              {item?.contract_principal_total || 0}
                            </div>
                          )}
                        </MDBCol>
                        <MDBCol class='col7 lessPadding'>
                          {this.state.sumActivities[index]?.post > 0 && (
                            <div
                              className={`${
                                this.state.sumActivities[index]?.post > item?.contract_post_total
                                  ? "redText"
                                  : this.state.sumActivities[index]?.post > 0
                                  ? "blueText"
                                  : ""
                              } "bold"`}
                            >
                              {this.state.sumActivities[index]?.post || 0} 
                              &nbsp;of{" "}
                              {item?.contract_post_total || 0}
                            </div>
                          )}
                        </MDBCol>
                        <MDBCol class='col8 lessPadding'>
                          {this.state.sumActivities[index]?.basic > 0 && (
                            <div
                              className={`${
                                this.state.sumActivities[index]?.basic >
                                item?.contract_basic_total
                                  ? "redText"
                                  : this.state.sumActivities[index]?.basic > 0
                                  ? "blueText"
                                  : ""
                              } "bold"`}
                            >
                              {this.state.sumActivities[index]?.basic || 0}
                              &nbsp;of{" "}
                              {item?.contract_basic_total || 0}
                            </div>
                          )}
                        </MDBCol>
                        <MDBCol class='col9'></MDBCol>                       
                      </MDBRow>
  }
                    </>
                  ))}

                  {/* final total */}
                  <MDBRow className="bolTopBorder">
                    <MDBCol class='col1'></MDBCol>
                    <MDBCol class='col2'></MDBCol>
                    <MDBCol class='col3'></MDBCol>
                    <MDBCol class='col4' className="blackText">
                      Total Days
                    </MDBCol>
                    <MDBCol class='col5 lessPadding' className="blueText">
                          {this.state?.totalSumActivities?.guarantee > 0 && <div className="bold blueText">
                            {this.state?.totalSumActivities?.guarantee || 0}
                          </div>
                          }
                    </MDBCol>
                    <MDBCol class='col6 lessPadding'>
                      {this.state?.totalSumActivities?.principal > 0 && (
                        <div
                          className={`${
                            this.state?.totalSumActivities?.principal >
                            this.state?.finalTotal
                              ?.final_contract_principal_total
                              ? "redText"
                              : this.state?.totalSumActivities?.principal > 0
                              ? "blueText"
                              : ""
                          } "bold"`}
                        >
                          {this.state?.totalSumActivities?.principal ||0}{" "}
                          {/* of{" "}
                          {this.state?.finalTotal
                            ?.final_contract_principal_total || 0} */}
                        </div>
                      )}
                    </MDBCol>
                    <MDBCol class='col7 lessPadding'>
                      {this.state?.totalSumActivities?.post > 0 && (
                        <div
                          className={`${
                            this.state?.totalSumActivities?.post >
                            this.state?.finalTotal?.final_contract_post_total
                              ? "redText"
                              : this.state?.totalSumActivities?.post > 0
                              ? "blueText"
                              : ""
                          } "bold"`}
                        >
                          {this.state?.totalSumActivities?.post || 0} 
                          {/* of{" "}
                          {this.state?.finalTotal?.final_contract_post_total ||
                            0} */}
                        </div>
                      )}
                    </MDBCol>
                    <MDBCol class='col8 lessPadding'>
                      {this.state?.totalSumActivities?.basic > 0 && (
                        <div
                          className={`${
                            this.state?.totalSumActivities?.basic >
                            this.state?.finalTotal?.final_contract_basic_total
                              ? "redText"
                              : this.state?.totalSumActivities?.basic > 0
                              ? "blueText"
                              : ""
                          } "bold"`}
                        >
                          {this.state?.totalSumActivities?.basic || 0} 
                          {/* of{" "}
                          {this.state?.finalTotal?.final_contract_basic_total ||
                            0} */}
                        </div>
                      )}
                    </MDBCol>
                    {/* <MDBCol class='col9 lessPadding' className="blueText">
                       {this.state?.totalSumActivities?.guarantee + this.state?.totalSumActivities?.principal +this.state?.totalSumActivities?.post+ this.state?.totalSumActivities?.basic}
                    </MDBCol> */}
                  </MDBRow>
            </>
          )}
        </div>
        </>}
      </div>
      </>
    );
  }
}