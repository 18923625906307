import React, { Component } from 'react'
import { Popover } from '@material-ui/core';
import { MDBRow, MDBCol, MDBContainer } from 'mdbreact';
import BasicButton from '../../components/SharedComponents/BasicButton/BasicButton'
import BasicTextField from '../../components/SharedComponents/BasicTextField/BasicTextField';
import SelectField from '../../components/SharedComponents/SelectField/SelectField';
import './NewAgency.scss';
import ModalComponent from '../DefaultModalLayout/Modal/Modal';
import { agencyNavList } from '../../../constants/NavList';
import CasterService from '../../../services/service';
import * as Constants from '../../../constants/constants';
import NotificationComponent from '../NotificationComponent/NotificationComponent';
import CircularProgress from "@material-ui/core/CircularProgress";
let createdAgency = null
export default class NewAgency extends Component {
    constructor(props) {
        super(props)
        this.state = {
            openAgency: false,
            agencyName: null,
            showError: false,
            agencyDetails: {
                agency_id: null,
                agency_name: null
            },
            showSuccess: false,
            showFail: false,
            saveFlag: false,
            saveEditAgency: false
        }
    }

    componentDidMount = () => {
        if(this.props?.agencySelected?.optionType == 'new' && this.props?.agencySelected) {
            let agencyDetails = this.state.agencyDetails
            agencyDetails.agency_name = this.props?.agencySelected?.value
            this.setState({ agencyDetails: agencyDetails })
        }
    }
    openAgency = () => {
        this.setState({ saveEditAgency: true }, () => {
            this.submitAgency()
        })
    }

    handleResetNotify = () => {
        this.setState({ showFail: false, showSuccess: false });
        if (this.state.saveFlag) {
            this.setState({ saveFlag: false }, this.closPopup)
        }
    }

    onChange = (key, value) => {
        this.setState((prevState)=>({
            agencyDetails:{
                ...prevState?.agencyDetails,
                [key]:value
            }
        }))
    }

    notificationComponent = (value, mode = "fail") => {
        switch (mode) {
            case "success": return this.setState({ showSuccess: value, saveFlag: true });
            case "fail": return this.setState({ showFail: value });
        }
    }

    submitAgency = () => {
        this.state.saveEditAgency ? this.setState({ isPosting: true, showError: false }) : this.setState({ savingAgency: true, showError: false })
        let postJson = this.state.agencyDetails;
        if (postJson?.agency_name != null && postJson?.agency_name.length > 0) {
            CasterService.postDataParams(Constants.CasterServiceBaseUrl + `/casterFeatureAgencyDetails`, postJson, this.state?.selecttenant)
                .then((response) => {
                    console.log("response", response);
                    if(response?.data?.error) {
                        this.setState({ savingAgency: false, showError: false })
                        this.notificationComponent(true, "fail");
                    } else {
                        if(this.state.saveEditAgency) {
                            this.notificationComponent(true, "success");
                            createdAgency = {
                                value: response.data[0].agency_id,
                                text: this.state.agencyDetails?.agency_name
                            }
                            this.setState({ savingAgency: false, showError: false, saveEditAgency: false })
                            setTimeout(() => {
                                this.props?.handleCloseAgencyPopOver();            
                                this.props?.setObjectSelected('agency', {text: this.state.agencyDetails?.agency_name, value: response.data[0].agency_id, aka: this.state.agencyDetails?.agency_name})
                                this.props?.setNewAgency(createdAgency);
                            },1500) 
                            // console.log("Open Agency Pop up", this.state.openAgency);
                        }
                        else {
                            createdAgency = {
                                value: response.data[0].agency_id,
                                text: this.state.agencyDetails?.agency_name
                            }
                            this.setState({ savingAgency: false, showError: false })
                            this.notificationComponent(true, "success");
                            setTimeout(() => {
                                this.props?.handleCloseAgencyPopOver();
                                this.props?.setNewObjectSelected('agency', {text: this.state.agencyDetails?.agency_name, optionType: 'save' });
                                this.props?.setNewAgency(createdAgency);
                                console.log("post response", response);
                            }, 1500)
                            
                        }
                    }
                },
                (err) => {
                    this.setState({ savingAgency: false, showError:false })
                    this.notificationComponent(true, "fail");
                    console.log("Post agency details error: " + err);
                });
        } 
        else {
            this.setState({ showError: true, savingAgency: false })
        }
    }

    render() {
        let severity = this.state.showFail ? "error" : this.state.showSuccess ? "success" : "";
        let messageNotification = this.state.showSuccess ? "The changes are saved successfully!" : this.state.showFail ? "Saving changes failed!" : "";
        return (
            <div>
                <NotificationComponent open={this.state.showSuccess || this.state.showFail} message={messageNotification} severity={severity}
                    handleResetNotify={this.handleResetNotify.bind(this)} />
                <Popover
                    id={this.props?.id}
                    open={this.props.open}
                    anchorEl={this.props?.setPopOver}
                    onClose={() => { this.props?.handleCloseAgencyPopOver() }}
                    className='talentPopver'
                    getContentAnchorEl={null}
                    anchorReference="anchorOrigin"
                    anchorOrigin={{
                        vertical: 'center',
                        horizontal: 'center',
                    }}
                    transformOrigin={{
                        vertical: 'top',
                        horizontal: 'right',
                    }}
                    anchorPosition={{ top: 700, left: 300 }}
                    PaperProps={{ style: { width: '35%', backgroundColor: '#F2F2F2' } }}
                >
                    <MDBContainer className='NewAgencyContainer'>
                        <MDBRow>
                            <MDBCol md={12}>
                                <div>New Agency will be displayed in application as:</div>
                            </MDBCol>
                        </MDBRow>
                        <MDBRow>
                            <MDBCol md={12} className="agencyField">
                                <BasicTextField
                                    id="agencyName"
                                    // placeHolder="Agency Name"
                                    value={this.state.agencyDetails?.agency_name || null}
                                    isMandatory={true}
                                    showMandatory={this.state?.showError}
                                    isAlphabetVar={true}
                                    handleAlphabetVariantSelect={(value) => this.onChange('agency_name', value)}
                                    onChange={(e) => this.onChange('agency_name', e.target.value)}
                                />
                            </MDBCol>
                        </MDBRow>
                        <MDBRow className='mt-3'>
                            <MDBCol md={5}></MDBCol>
                            <MDBCol md={3} className='d-flex justify-content-end'>
                                <BasicButton
                                    variant="contained"
                                    text={this.state?.isPosting ? <CircularProgress color="inherit" size={20} /> : "Save & Edit"}
                                    onClick={() => { this.openAgency() }}
                                />
                            </MDBCol>
                            <MDBCol md={2} className='d-flex justify-content-end' >
                                <BasicButton
                                    variant="contained"
                                    text={this.state?.savingAgency ? <CircularProgress color="inherit" size={20} /> : "save"}
                                    icon={!this.state?.savingAgency ? "save" : " "}
                                    onClick={() => { this.submitAgency() }}
                                />
                            </MDBCol>
                            <MDBCol md={2} className='d-flex justify-content-end'>
                                <BasicButton
                                    variant="outlined"
                                    text=" "
                                    icon={"times"}
                                    onClick={this.props?.handleCloseAgencyPopOver} />
                            </MDBCol>
                        </MDBRow>
                    </MDBContainer>
                </Popover>
                {/* {this.state.openAgency &&
                    <ModalComponent
                        id={this.state.agencyId}
                        open={this.state.openAgency}
                        handleClose={this.props?.handleClose}
                        tabList={agencyNavList}
                        agencyId={createdAgency}
                        bodyText={"Search for an Agency to view its Data"}
                        searchPlaceholder={"Search Agencies by name, type or ID"}
                        objectType={'AGENCY'}
                        headerText={"Agency"} dataUrl='agencyDetails'
                        mode={'edit'}
                    />
                } */}
            </div>
        )
    }
}