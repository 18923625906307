export const initialNewProdCompanyDetails = {
  project_production_company_id: null,
  production_company: null,
  signator: null,
  signator_email: null,
  address: null,
  city: null,
  state: null,
  zip:null,
  country:null,
  phone:null,
  is_delete:0
};
export const initialObjectConfig = {
  object_id: null,
  object_type: null,
  value: null,
  is_primary: 0,
  is_delete: 0,
  ext: null
}
