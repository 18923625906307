export const newProjectDetails =
{
    "project_id": null,
    "project_name": null,
    "studio_name": null,
    "distributor_name": null,
    "status": null,
    "start_date": null,
    "wrap_date": null,
    "release_date": null,
    "assigned_to": null,
    "assigned_to_name": "",
    "gl_no_prod": "",
    "gl_no_dev": "",
    "project_type": "",
    "sag_id": null,
    "contract_type": null,
    "url": null,
    "notes": null,
    "aliases": [],
    "production_companies": [],
    "akaRadio": 1
}

export const aliasList =
{
    "project_alias_id": null,
    "alias_name": "",
    "is_primary": 0,
    "is_delete": 0,
    "index": 1,
    "is_aka": 1
}

export const companiesList =
{
    "project_production_company_id": null,
    "production_company_id":null,
    "production_company": null,
    "union": "",
    "signator": "",
    "index": 1,
    "is_delete": 0,
    "notes": "",
    "type": "new",
    "project_id": null,
    "signator_email": "",
    "address": "",
    "city": "",
    "state":"" ,
    "zip": null,
    "country": "",
    "phone": null,
    "notes": "",
    "is_primary": 0
}



export const checkDropDown = [
    {
        value: "check1",
        label: "check1"
    },
    {
        value: "check2",
        label: "check2"
    },
    {
        value: "check3",
        label: "check3"
    },
    {
        value: "check4",
        label: "check4"
    },
    {
        value: "check5",
        label: "check5"
    },
    {
        value: "h",
        label: "h"
    }
]

export const checkSelectDropDown = [
    {
        value: 1,
        text: "check1"
    },
    {
        value: 2,
        text: "check2"
    },
    {
        value: 3,
        text: "check3"
    },
    {
        value: 4,
        text: "check4"
    },
    {
        value: 5,
        text: "check5"
    }
]

export const initialObjectConfig = {
    address: null,
    city: null,
    state: null,
    country: 0,
    zip: 0,
}