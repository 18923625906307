import { MDBIcon } from 'mdbreact';
import React, { Component } from 'react'
import * as Constants from '../../../constants/constants';
import CasterService from '../../../services/service';
import TableComponent from "../SharedComponents/Table";
import { projectCrewConfig } from './config'
import { updateTableSortConfigObject, tableSortList } from '../../Common/TableHelper';
import MainViewModalComponent from '../../Common/MainViewModalLayout/Modal/Modal';
import { crewNavList } from '../Crew/Config'
import ModalComponent from '../../Common/DefaultModalLayout/Modal/Modal';
export default class ProjectCrew extends Component {
    constructor(props) {
        super(props);
        this.state = {
            fetchingCrewDetails: false,
            crewList: [],
            sortCount: 0,
            sortBy: "Name",
            config: JSON.parse(JSON.stringify(projectCrewConfig)),
            showCrewPopup: false,
            crewPopupMode: 'new',
            crewSelected: null,
            projectCrewId: null,
            showDMCrewModal: false,
            selectedCrewDetails: null,
            currentIndexForAssistant: null
        }
    }

    componentDidMount() {
        console.log(this.props?.navigationCrewDetail, this.props.navigationName);
        this.getCrewData();
        this.navigationCallback(this.props?.navigationCrewDetail, this.props.navigationName)
    }
    componentDidUpdate(prevProps, prevState) {
        console.log("update from project crew", prevProps?.refreshPage?.value, this.props?.refreshPage?.value)
        if (prevProps?.refreshPage?.value !== this.props?.refreshPage?.value && this.props?.refreshPage?.modal === 'ProjectCrew') {
            console.log("update from project crew")
            this.getCrewData();
            this.props?.toggleRefreshPage('');
        }
    }
    handleShowHideCrewPopup(value, projectCrewId = null, crewSelected = null) {
        this.setState({
            showCrewPopup: value,
            projectCrewId: projectCrewId,
            crewSelected: crewSelected
        })
    }
    getCrewData() {
        this.setState({ fetchingCrewDetails: true })
        CasterService.getData(Constants.CasterServiceBaseUrl +
            `/casterFeatureProjectCrew?project_id=${this.props?.project_id}`, this.props.userContext?.active_tenant?.tenant_id)
            .then(response => {
                if (response?.data?.error || !response?.data) {
                    this.setState({ fetchingCrewDetails: false })
                }
                else {
                    let crewList = response?.data?.map(item => {
                        let phoneString = '', emailString = ''
                        item?.phone_list?.map(i => {
                            if (i?.is_primary == 1) {
                                phoneString = phoneString + i?.phone_type +
                                    '-'
                                    + `${i?.phone} <span style="font-size:15px;color:#164f9f;">&starf;</span>\n`
                            }
                            else {
                                phoneString = phoneString + i?.phone_type + '-' + i?.phone + '\n'
                            }
                        })
                        item?.email_list?.map(i => {
                            if (i?.is_primary == 1) {
                                emailString = emailString + `${i?.email} <span style="font-size:15px;color:#164f9f;">&starf;</span>\n`
                            }
                            else {
                                emailString = emailString + i?.email + '\n'
                            }
                        })

                        return {
                            project_id: item?.project_id,
                            crew_id: item?.crew_id,
                            project_crew_id: item?.project_crew_id,
                            crew_name: item?.crew_name,
                            location_name: item?.location_name,
                            occupation: item?.occupation,
                            company: item?.company,
                            phone: phoneString,
                            email: emailString
                        }
                    })
                    this.setState({ fetchingCrewDetails: false, crewList: crewList })
                    console.log(response?.data);
                }

            }, err => {
                this.setState({ fetchingCrewDetails: false })
                console.log("error from project crew details")
            })
    }
    getSortNode = (col) => {
        switch (col) {
            case "Name": return "crew_name";
            case "Occupation": return "occupation";
            case "Company": return "company";
            case "Location": return "location_name";

        }
    }
    handleTableSort = (colName) => {
        this.setState({
            isSortByAsc: this.state.sortCount % 2 === 0 ? true : false,
            config: updateTableSortConfigObject(this.state.config, this.state.isSortByAsc, this.state.sortBy, colName)
        }, () => {
            let list = tableSortList("Name", this.getSortNode(colName), this.state.crewList, this.state.isSortByAsc)
            this.setState({
                sortBy: colName,
                crewList: list
            });
        })
    }
    handleTableColumnClick = (column) => {
        if (column != "Phone" && column != "Email")
            if (column === this.state.sortBy) {
                this.setState({ sortCount: this.state.sortCount + 1 }, () => {
                    this.handleTableSort(column);
                })
            }
            else this.setState({ sortCount: 0 }, () => {
                this.handleTableSort(column);
            })
    }
    handleDelCallBack(id, dataItem) {
        console.log("del callback", id, dataItem)

        if (id && dataItem) {
            let postDataParams = { ...dataItem, is_delete: 1 }
            CasterService.postDataParams(Constants.CasterServiceBaseUrl + '/casterFeatureProjectCrew', postDataParams)
                .then((response) => {
                    if (response?.data?.error || !response?.data) {
                        // this.setState({ fetchingCrewDetails: false})
                        this.props?.notificationComponent(true, "fail");
                    }
                    else {
                        this.props?.notificationComponent(true, "success");
                        this.getCrewData();
                    }
                }, err => {
                    this.props?.notificationComponent(true, "fail");
                    console.log("Error from crew delete")
                })
        }
    }
    editCallback(id, dataItem) {
        console.log("edit callback", id, dataItem);
        if (id) {
            this.handleShowHideCrewPopup(true, id, { value: dataItem?.crew_id, text: dataItem?.crew_name })
        }
    }
    navigationCallback = (obj, node) => {
        if (node?.includes("crew_name")) {
            console.log("crew_name:", obj)
            this.setState({
                selectedCrewDetails: {
                    value: obj?.crew_id,
                    headerName: obj?.crew_name,
                    text: obj?.crew_name
                }
            }, this.handleShowCrewModal())
        }
    }
    handleShowCrewModal() {
        this.setState({ showDMCrewModal: true })
    }
    handleHideCrewModal() {
        this.setState({ showDMCrewModal: false })
    }

    functions = {
        handleHideCrewPopup: () => {
            this.setState({ showDMCrewModal: false });
        }
    }
    setCurrentIndexForAssistant(index) {
        this.setState({ currentIndexForAssistant: index })
    }
    render() {
        return (
            <>
                <div className='ProjectCrewContainer'>
                    <input className="hiddenButton" type="button" ref={this.props?.buttoncRef} onClick={() => this.handleShowHideCrewPopup(true)}></input>
                    <TableComponent
                        tableType=""
                        isLoading={this.state?.fetchingCrewDetails}
                        list={this.state?.crewList || []}
                        config={this.state.config}
                        sortCallback={this.handleTableColumnClick}
                        handleDelCallBack={this.handleDelCallBack.bind(this)}
                        editCallback={this.editCallback.bind(this)}
                        hyperLinkNavigationCallback={this.navigationCallback}
                    />
                </div>
                {this.state?.showCrewPopup &&
                    <MainViewModalComponent
                        modalName='Add Project Crew'
                        handleClose={() => this.handleShowHideCrewPopup(false)}
                        mode={this.state?.projectCrewId ? 'edit' : 'new'}
                        id={this.state?.projectCrewId || null}
                        project_id={this.props?.project_id}
                        crewSelected={this.state?.crewSelected || null}
                        open={this.state.showCrewPopup}
                        headerText={"Crew Record"}
                        toggleRefreshPage={this.props?.toggleRefreshPage}
                    />}
                {this.state.showDMCrewModal ?
                    <ModalComponent
                        id={this.state.selectedCrewDetails?.value}
                        akas={this.state?.selectedCrewDetails?.aka}
                        open={this.state.showDMCrewModal}
                        handleClose={this.handleHideCrewModal.bind(this)}
                        fetchDetailsCallback={this.getCrewData.bind(this)}
                        tabList={crewNavList}
                        headerText={"Crew"}
                        mode="edit"
                        isProfileDiv={true}
                        addButtonText={"New Crew"}
                        objectType={'CREW'}
                        objectId={this.state?.selectedCrewDetails?.value}
                        dataUrl='casterFeatureCrewDetails'
                        headerName={this.state.selectedCrewDetails?.headerName}
                        navFuns={this.functions}
                        setCurrentIndexForAssistant={this.setCurrentIndexForAssistant.bind(this)}
                        currentIndexForAssistant={this.state.currentIndexForAssistant}
                        {...this.props}
                    /> : null
                }
            </>
        )
    }
}
