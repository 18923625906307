import React from 'react';
import TableContainer from '@material-ui/core/TableContainer';
import Table from '@material-ui/core/Table';
import TableBody from '@material-ui/core/TableBody';
import TableCell from '@material-ui/core/TableCell';
import TableHead from '@material-ui/core/TableHead';
import TableRow from '@material-ui/core/TableRow';
import Paper from '@material-ui/core/Paper';
import { MDBIcon, MDBRow, MDBCol } from "mdbreact";
import './shared.scss';
import CircularProgress from "@material-ui/core/CircularProgress";
import Chip from '@material-ui/core/Chip';
import BasicButton from './BasicButton/BasicButton';
import TableFilters from './TableFilters';
import TableColumnExpand from './TablecolumnExpand';
import TableInlineEdits from './TableInlineEdits';
import { withUserContext } from "../../../contexts/UserContext";
import { withFilterContext } from "../../../contexts/FilterContext";
//import Tooltip from '@material-ui/core/Tooltip';
import BasicCheckbox from './BasicCheckbox/BasicCheckbox';
import StarBorderIcon from '@material-ui/icons/StarBorder';
import StarIcon from '@material-ui/icons/Star';
import Tooltip from '@mui/material/Tooltip';
import Popover from '@material-ui/core/Popover';
import { getFormattedDateTableFilter } from '../../Common/Helper';
import ReactHtmlParser from 'react-html-parser';
import { MDBTooltip } from 'mdb-react-ui-kit';

class TableComponent extends React.Component {

    constructor(props) {
        super(props);
        var headings = this.props?.config?.headings;
        var colArr = {};
        var dataTypeArr = {};
        var selectOptionsArr = {};
        headings.forEach((heading, index) => {
            colArr[heading.headingLabel] = "";
            dataTypeArr[this.props?.config?.dataNodes[index]] = heading.inputType;
            if (heading.inputType == "select")
                selectOptionsArr[this.props?.config?.dataNodes[index]] = heading.selectOptions;
        });
        this.state = {
            inputValues: colArr,
            filterValues: [],
            listEdits: JSON.parse(JSON.stringify(this.props?.list || [])),
            editInputValues: [],
            dataTypeValues: dataTypeArr,
            selectOptionValues: selectOptionsArr
        }
    }

    componentDidMount() {
        if(this.props?.config?.filterCallBack && this.props?.config?.filterCallBackKeyIdentifier === this.props?.filterContext?.filterNavigationName){
            this.setState({ 
                  inputValues: JSON.parse(JSON.stringify(this.props?.filterContext?.inputValues || [])),
                  filterValues: this.props?.filterContext?.filterData || []
                 });
        }
        if(this.props?.isLoadingWithFilters===true){
            this.setState({ 
                inputValues: [],
                filterValues: []
               });
        }
        
    }


    componentDidUpdate(prevProps, prevState) {
        if (prevProps.list !== this.props.list) {
            this.setState({ listEdits: JSON.parse(JSON.stringify(this.props?.list || [])) });
            if(this.props?.isLoadingWithFilters===true){
                this.setState({ 
                    inputValues: [],
                    filterValues: []
                   });
            }else{
                if(this.props?.filterValues?.length>0 && this.props?.config?.identityKey === 'performerTable'){
                    this.setState({ 
                        inputValues: this.props?.inputValues,
                        filterValues: this.props?.filterValues
                       });
                }
            }
        }
        
    }

    clearInputValues = (index, name, value,label=null) => {
        if(this.props?.config?.filtersLandingCasting){
            let inputValuesTemp = this.state.inputValues;
            inputValuesTemp[value] = "";
            let filterValuesTemp = this.state.filterValues;
        
        if (filterValuesTemp.length > 0) {
            let presentInd = filterValuesTemp.findIndex((item) => item.split(":")[0] === name);
            if (presentInd != -1) {
                filterValuesTemp[presentInd] = name + ": " + '';
            }
        }
            this.setState({
                inputValues: inputValuesTemp,
                filterValues: filterValuesTemp
            },()=>{
                if(this.props?.config?.filterCallBack){
                    this.props?.userContext?.filterTableData(
                       {
                        navigation: this.props?.config?.filterCallBackKeyIdentifier, 
                        filterValues: this.state.filterValues,
                        inputValues:this.state.inputValues,
                        page:'',
                        rowsPerPage:''
                       }
                    );     
                    this.props.applyFilter(this.state.filterValues, false);      
                }else{
                    this.props.applyFilter(this.state.filterValues, true);
                }
                
            });
            // var filtersNone = false;
            // if (filterValues.length == 0)
            //     filtersNone = true;
            
        }
        if(this.props?.config?.identityKey === 'performerTable' || this.props?.config?.identityKey === 'billingTable'){
            let inputValuesTemp = this.state.inputValues;
            inputValuesTemp[value] = "";
            let filterValuesTemp = this.state.filterValues;
        
        if (filterValuesTemp.length > 0) {
            let presentInd = filterValuesTemp.findIndex((item) => item.split(":")[0] === name);
            if (presentInd != -1) {
                filterValuesTemp.splice(presentInd,1)
            }
        }
            this.setState({
                inputValues: inputValuesTemp,
                filterValues: filterValuesTemp
            },()=>{
                if(this.state.filterValues?.length>0){
                    this.props?.applyFilter(this.state?.filterValues, false,"clearFilter");
                }else{
                    this.props?.applyFilter(this.state?.filterValues, true);
                }
                
            });
            // var filtersNone = false;
            // if (filterValues.length == 0)
            //     filtersNone = true;
            
        }
    }
    addFilterChangeHandler = (index, name, value) => {
        var inputValues = this.state.inputValues;
        var filterValues = this.state.filterValues;
        if(this.props?.config?.filtersLandingCasting){
            //do nothing
        }else if((this.props?.config?.identityKey === 'performerTable' && name === "Deal Date" ) || (this.props?.config?.identityKey === 'billingTable' && name === "Deal Date")){
            inputValues[name] = getFormattedDateTableFilter(value);
        }else if(this.props?.config?.identityKey === 'performerTable' || this.props?.config?.identityKey === 'billingTable'){
            inputValues[name] = (value);
        }else{
        inputValues[name] = "";
        }

        if (filterValues?.length > 0) {
            let presentInd = filterValues?.findIndex((item) => item?.split(":")[0] === name);
            if (presentInd != -1) {
                if(value?.length>0){
                    if((this.props?.config?.identityKey === 'performerTable' && name === "Deal Date" )||(this.props?.config?.identityKey === 'billingTable' && name === "Deal Date")){
                        filterValues[presentInd] = name + ": " + getFormattedDateTableFilter(value);
                    }else{
                        filterValues[presentInd] = name + ": " + value;
                    }
                    
                }else{
                    filterValues.splice(presentInd,1)
                }   
            } else {
                if(value.length>0){
                    if((this.props?.config?.identityKey === 'performerTable' && name === "Deal Date" ) || (this.props?.config?.identityKey === 'billingTable' && name === "Deal Date")){
                        filterValues.push(name + ": " + getFormattedDateTableFilter(value));
                    }else{
                        filterValues.push(name + ": " + value);
                    }
                }
            }
        }
        else {
            if(value.length>0){
                if((this.props?.config?.identityKey === 'performerTable' && name === "Deal Date" ) || (this.props?.config?.identityKey === 'billingTable' && name === "Deal Date")){
                    filterValues.push(name + ": " + getFormattedDateTableFilter(value));
                }else{
                    filterValues.push(name + ": " + value);
                }
            }
        }
        this.setState({
            inputValues: inputValues,
            filterValues: filterValues
        },()=>{
            if(this.props?.config?.filterCallBack){
                this.props?.userContext?.filterTableData(
                   {
                    navigation: this.props?.config?.filterCallBackKeyIdentifier, 
                    filterValues: this.state?.filterValues,
                    inputValues:this.state?.inputValues,
                    page: null,
                    rowsPerPage: null
                   }
                );           
            }
            if (this.state?.filterValues?.length > 0){
                    this.props?.applyFilter(this.state?.filterValues, false);
                
            }else{
                this.props?.applyFilter(this.state?.filterValues, true);
            }
        });
        
    }

    filterChangeHandler = (index, name, value, filter) => {
        var inputValues = this.state.inputValues;
        inputValues[name] = ((name === "Deal Date" && this.props?.identityKey==='performerTable') || (this.props?.identityKey === 'billingTable' && name === "Deal Date"))?getFormattedDateTableFilter(value):value;
        this.setState({
            inputValues: inputValues
        });
    }

    handleInlineEditChange = (value, index, nodeName) => {
        var editInputValues = [...this.state.listEdits];
        editInputValues[index][nodeName] = value;
        this.setState({ listEdits: editInputValues, filterValues: [] });
    }

    handleFilterDelete = (removeNode, index) => {
        var filterValues = this.state.filterValues;
        filterValues.splice(index, 1);
        this.setState({ filterValues: filterValues });
        var filtersNone = false;
        if (filterValues.length == 0)
            filtersNone = true;
        this.props.applyFilter(filterValues, filtersNone);

    }
onColumnCheck = (element, index, checked) => {
        this.props.onColumnCheck(element, index, checked)
    }
    handleColumns = (event) => {
        this.setState({
            setColumns: event.currentTarget
        })
    };
    handleCloseColumns = () => {
        this.setState({
            setColumns: null
        })
    };
    divideArrayToChunks = (chunksize, array) => {
        return array.map((e, i) => {
            return i % chunksize === 0 ? array.slice(i, i + chunksize) : null;
        }).filter(e => { return e; });
    }
    render() {
        let userPermissions = this.props?.userContext?.active_tenant?.permissions;
        let openColumn = Boolean(this.state.setColumns);
        let idColumn = openColumn ? 'simple-popover' : undefined;
        return (
            <>
                <div>
                {
                                this.props?.config?.filtersLandingCasting &&
                                <div className="col-12 dashboardTopHeader">
                                    <div class="row justify-content-end headerTopActions">
                                        <div class="col-4">
                                            <div className="newProjectButtonFeature">
                                                <BasicButton
                                                    variant="contained"
                                                    type="inline"
                                                    className="outlined-btn"
                                                    text="New Project"
                                                    icon={"plus-circle"}
                                                    onClick={this.props?.addNewProject}
                                                // onClick={this.handleshowNewProjectModal}
                                                />
                                                <BasicCheckbox
                                                    id={"historic-data"}
                                                    label={"Show Historical Data"}
                                                    checked={this.props.showHistoricalData}
                                                    onChange={(e) => this.props?.handleShowHistoricalData(e)}
                                                />
                                            </div>
                                        </div>
                                        <div class="col-1">
                                            <div className="dashboardConstantButtons">
                                                <div className="columnsButton">
                                                    <BasicButton
                                                        variant="text"
                                                        text={"Columns >"}
                                                        onClick={this.handleColumns}
                                                    />
                                                    <Popover
                                                        id={idColumn}
                                                        className="popOverFilter dashboardPopOverFilter"
                                                        open={openColumn}
                                                        anchorEl={this.state.setColumns}
                                                        onClose={this.handleCloseColumns}
                                                        anchorOrigin={{
                                                            vertical: 'bottom',
                                                            horizontal: 'left',
                                                        }}
                                                    >
                                                        <div class='row'>
                                                            {this.divideArrayToChunks(6, this.props?.config?.headings)?.map((element, parentIndex) => {
                                                                return (<div class='col-md-auto columnChecks'>
                                                                    {element.map((column, i) => {
                                                                        return (<span className={column.isDisabled ?'disable':''}>{column.dataNode !== "expand" && <BasicCheckbox
                                                                            id={i + (parentIndex * 6)} key={i + (parentIndex * 6)}
                                                                            label={column.headingLabel} 
                                                                            checked={ column.isColumn }
                                                                            disabled={column.isDisabled ? column.isDisabled : false}
                                                                            onChange={(e) => this.onColumnCheck(column, (i + (parentIndex * 6)), e.target.checked)} />}</span>)
                                                                    })}
                                                                </div>)
                                                            })
                                                            }
                                                        </div>
                                                    </Popover>
                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                            }
                    <div className={this.props?.maxHeight ? "tableContainer " + "max-height-" + this.props?.maxHeight : "tableContainer"}>

                    <TableContainer component={Paper} className={`${this.props?.tableMode == "light" ? "bg-white overflow-x-unset" : this.props?.tableMode == "dark" ? "bg-light overflow-x-unset" : 'bg-white overflow-x-unset'}  ${this.props?.tableType == 'striped' ? ' striped' : ''}`} >
                           
                            {/* {
                                this.props?.config?.filtersLandingCasting &&
                                <div className="headerdashboardAction">
                                <div className="col-12 dashboardTopHeader">
                                    <div class="row justify-content-end headerTopActions">
                                    <div class="col-7"></div>
                                        <div class="col-4">
                                            <div className="newProjectButtonFeature">
                                                <BasicButton
                                                    variant="contained"
                                                    type="inline"
                                                    className="outlined-btn"
                                                    text="New Project"
                                                    icon={"plus-circle"}
                                                    onClick={this.props?.addNewProject}
                                                // onClick={this.handleshowNewProjectModal}
                                                />
                                                <BasicCheckbox
                                                    id={"historic-data"}
                                                    label={"Show Historical Data"}
                                                    checked={this.props.showHistoricalData}
                                                    onChange={(e) => this.props?.handleShowHistoricalData(e)}
                                                />
                                            </div>
                                        </div>
                                        <div class="col-1">
                                            <div className="dashboardConstantButtons">
                                                <div className="columnsButton">
                                                    <BasicButton
                                                        variant="text"
                                                        text={"Columns >"}
                                                        onClick={this.handleColumns}
                                                    />
                                                    <Popover
                                                        id={idColumn}
                                                        className="popOverFilter dashboardPopOverFilter"
                                                        open={openColumn}
                                                        anchorEl={this.state.setColumns}
                                                        onClose={this.handleCloseColumns}
                                                        anchorOrigin={{
                                                            vertical: 'bottom',
                                                            horizontal: 'left',
                                                        }}
                                                    >
                                                        <div class='row'>
                                                            {this.divideArrayToChunks(6, this.props?.config?.headings)?.map((element, parentIndex) => {
                                                                return (<div class='col-md-auto columnChecks'>
                                                                    {element.map((column, i) => {
                                                                        return (<span className={column.isDisabled ?'disable':''}>{column.dataNode !== "expand" && <BasicCheckbox
                                                                            id={i + (parentIndex * 6)} key={i + (parentIndex * 6)}
                                                                            label={column.headingLabel} 
                                                                            checked={ column.isColumn }
                                                                            disabled={column.isDisabled ? column.isDisabled : false}
                                                                            onChange={(e) => this.onColumnCheck(column, (i + (parentIndex * 6)), e.target.checked)} />}</span>)
                                                                    })}
                                                                </div>)
                                                            })
                                                            }
                                                        </div>
                                                    </Popover>
                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                                </div>
                            } */}
                             {this.props?.isLoadingWithFilters ? (
                                        <div className="tableLoader">
                                            <CircularProgress />
                                        </div>):
                            <Table id={this.props.className + "-table"} stickyHeader={this.props?.stickyHeader || false} aria-label="collapsible table" size="small" style={{ tableLayout: this.props?.list?.length > 0 ?'fixed':'auto' }} >
                            {this.props?.config?.filtersLandingCasting ? 
                                 <colgroup align="center">
                                 {this.props?.config?.headings?.map((heading, index) => (                                    
                                        heading.isColumn  &&  heading?.dataNode !=='expand' &&
                                    <col key={"colWidth_" + index} 
                                    className={ this.props.showSelectedColumnCount > 6 && this.props?.list?.length <= 0 ? 'colSizePlus' : ''}
                                     width={  this.props.showSelectedColumnCount > 6  ?  '250px' : '14.28%'
                                   
                                        // (100 / this.props.showSelectedColumnCount) + "%"
                                        //  this.props.showSelectedColumnCount > 6 ? (100 / this.props.showSelectedColumnCount) + "%" :
                                        //  (this.props.selectedExpandedData.length > 0 ?'20%' : (heading.defaultColumn ? 
                                        //     (heading.width ? heading.width : (this.props.showSelectedColumnCount <= 6) ? (heading?.width):(100 / this.props?.config?.headings?.length) + "%") : ""))
                                 } />))
                                 }
                             </colgroup>                                
                                : 
                                <colgroup>
                                    {this.props?.config?.headings?.map((heading, index) => (
                                        <col key={"colWidth_" + index} width={heading.width ? heading.width : (100 / this.props?.config?.headings?.length) + "%"} />))
                                    }
                                </colgroup>
                                }                               
                                {this.props?.config?.isSearchBar ? <>
                                    <TableHead class="searchHeaders">
                                        <TableRow key={this.props.className + "-searchHeaderRow"}>
                                            {
                                                this.props?.config?.headings?.map((heading, index) => (
                                                    <TableCell key={this.props.className + "-" + index + "-" + heading.headingLabel} className="searchHeaderRows">
                                                        {index === 0 ? 'Search Results for :' + '"' + this.props.searchText + '"' : ''}
                                                    </TableCell>
                                                ))
                                            }
                                        </TableRow>
                                    </TableHead>
                                    <TableHead class="searchHeadersSecond">
                                        <TableRow key={this.props.className + "-searchHeaderSecondRow"}>
                                            {
                                                this.props?.config?.headings?.map((heading, index) => (
                                                    <TableCell key={this.props.className + "-" + index} className="searchHeadersSecondRows">
                                                        {index === 0 ? this.props.resultsCount + ' search results for ' + '[' + this.props.searchType + ']' : ''}
                                                    </TableCell>
                                                ))
                                            }
                                        </TableRow>
                                    </TableHead></> : null}
                                   
                                <TableHead id={this.props.className + "-tablehead"} className={this.props?.tableMode == "light" ? "bg-light" : this.props?.tableMode == "dark" ? "bg-dark" : ''}>

                                    {this.props?.config?.filters && this.props?.config?.identityKey!=='performerTable' && this.props?.config?.identityKey!=='billingTable' ?
                                        <TableRow className={this.state.filterValues?.length > 0 ? "columnFilters d-block" : "d-none"}>
                                            {/* <TableCell colSpan={this.props?.config?.headings?.length || 1} key={this.props.className + "-chips-0" } className="FW800" style ={{padding : "4px"}}  > */}
                                            <div style={{ display: "flex" }}>
                                                {
                                                    this.state.filterValues && this.state.filterValues?.map((item, index) =>
                                                        <Chip
                                                            label={item}
                                                            onDelete={this.handleFilterDelete.bind(this, item, index)}
                                                        />
                                                    )
                                                }
                                            </div>
                                            {/* </TableCell> */}
                                        </TableRow> :
                                    //    this.props?.config?.filtersLanding ?
                                    //         <TableRow className="columnFilters d-block d-flex justify-content-between">
                                    //             {/* <TableCell colSpan={this.props?.config?.headings?.length || 1} key={this.props.className + "-chips-0" } className="FW800" style ={{padding : "4px"}}  > */}
                                    //             {this.props?.config?.filtersLandingCasting ?<></>:<div className="constant" style={{ display: "flex" }}>
                                    //                 {
                                    //                     this.state.filterValues && this.state.filterValues?.map((item, index) =>
                                    //                         <Chip
                                    //                             label={item}
                                    //                             onDelete={this.handleFilterDelete.bind(this, item, index)}
                                    //                         />
                                    //                     )
                                    //                 }
                                    //             </div>}
                                               

                                    //         </TableRow>
                                    //         :
                                            <>
                                            </>
                                    }
                                 
                                    <TableRow>
                                        {this.props?.config?.filtersLandingCasting ? <>
                                            {this.props?.config?.headings?.filter(item => item.isColumn === true).map((heading, index) => (
                                                <TableCell key={this.props.className + "-" + index} style={{ padding: "4px" }}
                                                    className={this.props?.tableMode == "light" ? "bg-light FW800" : this.props?.tableMode == "dark" ? "bg-dark FW800" : ''} >
                                                   {this.props?.config?.filters || this.props?.config?.filtersLandingCasting ?
                                                        <><TableFilters inputType={heading?.filterType ? heading?.filterType : heading?.inputType}
                                                        isAlphabetVar={heading?.isAlphabetVar || false}
                                                            options={heading?.filterOptions || []}
                                                            filterValues={this.state.filterValues}
                                                            name={heading.headingLabel} 
                                                            inputValue={this.state.inputValues[heading.headingLabel]}
                                                            filterChangeHandler={this.filterChangeHandler.bind(this, index, heading?.headingLabel || heading?.chipAlternateText)}
                                                            filtersLandingCasting={true}
                                                            clearInputValues={this.clearInputValues.bind(this, index, ((this.props?.config?.identityKey==='Dashboard') ? heading?.searchJson : heading?.headingLabel) || heading?.chipAlternateText ,heading?.headingLabel)}
                                                            identityKey={this.props?.config?.identityKey}
                                                            addFilterChangeHandler={this.addFilterChangeHandler.bind(this, index, (this.props?.config?.identityKey==='Dashboard'?  heading?.searchJson : heading?.headingLabel) || heading?.chipAlternateText)} {...this.props} /> </> : <> </>}
                                                    {(heading.inputType === "checkbox" || heading.headingLabel === "Role #" || heading.headingLabel === "Deal Date") ?
                                                        <div className="d-flex justify-content-center align-items-center">
                                                            <span className="action-icon" onClick={() => 
                                                                heading?.sortColumn ? (heading.chipAlternateText === "Attachment") ? 
                                                                this.props?.sortCallback(heading.chipAlternateText) : this.props?.sortCallback(heading.headingLabel):''
                                                                }>
                                                                <b> {heading.icon ? <div>{heading.chipAlternateText === "Attachment" ? <MDBIcon icon='paperclip' /> : heading.headingLabel}<MDBIcon icon={heading.icon} /></div>
                                                                    : (heading.chipAlternateText === "Attachment" ? <MDBIcon icon='paperclip' /> : heading.headingLabel)} </b>
                                                            </span>
                                                        </div> :
                                                        <span className="action-icon" onClick={() =>  heading?.sortColumn && this.props?.sortCallback(heading.headingLabel)}>
                                                            <b> {heading.icon ? <div>{heading.headingLabel}<MDBIcon icon={heading.icon} /></div>
                                                                : heading.headingLabel} </b>
                                                        </span>
                                                    }
                                                </TableCell>
                                            ))
                                        }</>:(this.props?.config?.identityKey === 'performerTable' || this.props?.config?.identityKey === 'billingTable') ? <>{this.props?.config?.headings?.map((heading, index) => (
                                            <TableCell key={this.props.className + "-" + index} style={{ padding: "4px" }}
                                                className={this.props?.tableMode == "light" ? "bg-light FW800" : this.props?.tableMode == "dark" ? "bg-dark FW800" : ''} >
                                                {this.props?.config?.filters || this.props?.config?.filtersLandingCasting ?
                                                    <><TableFilters inputType={heading?.filterType ? heading?.filterType : heading?.inputType}
                                                        isAlphabetVar={heading?.isAlphabetVar || false}
                                                        options={heading?.filterOptions || []}
                                                        placeholder = {heading?.placeholder}
                                                        filterValues={this.state?.filterValues}
                                                        name={heading?.headingLabel} inputValue={this.state?.inputValues[heading?.headingLabel]}
                                                        filterChangeHandler={this.filterChangeHandler.bind(this, index, heading?.headingLabel)}
                                                        filtersLandingCasting={true}
                                                        clearInputValues={this.clearInputValues.bind(this, index, heading?.headingLabel || heading?.chipAlternateText, heading?.headingLabel)}
                                                        identityKey={this.props?.config?.identityKey}
                                                        addFilterChangeHandler={this.addFilterChangeHandler.bind(this, index,heading?.headingLabel || heading?.chipAlternateText)} {...this.props} /> </> : <> </>}
                                                {(heading.inputType === "checkbox" || heading.headingLabel === "Role #" || heading.headingLabel === "Deal Date") ?
                                                    <div className="d-flex justify-content-center align-items-center">
                                                        <span className="action-icon" onClick={() => (heading.chipAlternateText === "Attachment") ? this.props?.sortCallback(heading.chipAlternateText) : this.props?.sortCallback(heading.headingLabel)}>
                                                            <b> {heading.icon ? <div>{heading.chipAlternateText === "Attachment" ? <MDBIcon icon='paperclip' /> : heading.headingLabel}<MDBIcon icon={heading.icon} /></div>
                                                                : (heading.chipAlternateText === "Attachment" ? <MDBIcon icon='paperclip' /> : heading.headingLabel)} </b>
                                                        </span>
                                                    </div> :
                                                    <span className="action-icon" onClick={() => this.props?.sortCallback(heading.headingLabel)}>
                                                        <b> {heading.icon ? <div>{heading.headingLabel}<MDBIcon icon={heading.icon} /></div>
                                                            : heading.headingLabel} </b>
                                                    </span>
                                                }
                                            </TableCell>
                                        ))
                                        }</>:<>{this.props?.config?.headings?.map((heading, index) => (
                                            <TableCell key={this.props.className + "-" + index} style={{ padding: "4px" }}
                                                className={this.props?.tableMode == "light" ? "bg-light FW800" : this.props?.tableMode == "dark" ? "bg-dark FW800" : ''} >
                                                {this.props?.config?.filters || this.props?.config?.filtersLandingCasting ?
                                                    <><TableFilters inputType={heading?.filterType ? heading?.filterType : heading?.inputType}
                                                        isAlphabetVar={heading?.isAlphabetVar || false}
                                                        options={heading?.filterOptions || []}
                                                        filterValues={this.state.filterValues}
                                                        name={heading.headingLabel} inputValue={this.state.inputValues[heading.headingLabel]}
                                                        filterChangeHandler={this.filterChangeHandler.bind(this, index, heading?.headingLabel)}
                                                        filtersLandingCasting={true}
                                                        clearInputValues={this.clearInputValues.bind(this, index, heading?.headingLabel || heading?.chipAlternateText)}
                                                            addFilterChangeHandler={this.addFilterChangeHandler.bind(this, index, heading?.headingLabel || heading?.chipAlternateText)} /> </> : <> </>}
                                                        {(heading.inputType==="checkbox" || heading.headingLabel === "Role #" || heading.headingLabel === "Deal Date") ?
                                                            <div className="d-flex justify-content-center align-items-center">
                                                            <span className="action-icon" onClick={() =>(heading.chipAlternateText === "Attachment") ? this.props?.sortCallback(heading.chipAlternateText) : this.props?.sortCallback(heading.headingLabel)}>
                                                                <b> {heading.icon ? <div>{heading.chipAlternateText === "Attachment" ?<MDBIcon icon='paperclip' /> : heading.headingLabel}<MDBIcon icon={heading.icon} /></div>
                                                                    :(heading.chipAlternateText === "Attachment" ?<MDBIcon icon='paperclip' /> : heading.headingLabel)} </b>
                                                            </span>
                                                            </div> :
                                                            <span className="action-icon" onClick={() => this.props?.sortCallback(heading.headingLabel)}>
                                                                <b> {heading.icon ? <div>{heading.headingLabel}<MDBIcon icon={heading.icon} /></div>
                                                                    : heading.headingLabel} </b>
                                                            </span>
                                                            }
                                                </TableCell>
                                            ))
                                        }</>}
                                        {this.props?.config?.actions &&
                                            <TableCell key={this.props.className + "-" + this.props?.config?.headings?.length}
                                                className={this.props?.tableMode == "light" ? "bg-light FW800" : this.props?.tableMode == "dark" ? "bg-dark FW800" : ''} style={{ padding: "4px" }}  >
                                            </TableCell>
                                        }
                                    </TableRow>
                                </TableHead>
                                {
                                    this.props?.isLoading ? (
                                        <div className="tableLoader">
                                            <CircularProgress />
                                        </div>
                                    ) : (

                                        <TableBody key={this.props.className + "body"}>
                                            {this.props?.list?.length > 0 && this.props?.list?.map((dataItem, rowIndex) => (
                                                this.props?.postInitiated && (this.props?.postInitiated === dataItem[this.props.config.primaryKey]) ? <CircularProgress size={20} /> :
                                                <>
                                                    <TableRow className= {(rowIndex %2  == 0?'even ':'odd ' ) + (dataItem?.expand==='<<' ? 'firstExpandedRow expandedRow': (dataItem['performer']?.length >0 && 'firstExpandedRow' ))}  id={this.props.className + dataItem[this.props.config.primaryKey]}
                                                        key={this.props.className + "-" + dataItem[this.props.config.primaryKey] + "-" + rowIndex}>
                                                        {this.props?.config?.iscolumnFilterRequired &&
                                                                this.props?.config?.headings.filter(item => item.isColumn == true)?.map((node, index) => (node.dataNode == 'check' ?
                                                                    <TableCell className='FW800'>
                                                                        <input type='checkbox' checked={dataItem[node.dataNode]}
                                                                            onClick={(event, newValue) => {
                                                                                dataItem[node.dataNode] = event.target.checked;
                                                                                this.props.arrayCheckUncheck(event.target.checked, dataItem);
                                                                            }}></input>
                                                                    </TableCell> :
                                                                    <TableCell
                                                                      rowSpan={dataItem[node.rowSpanColumn]?.length > 0 ? dataItem[node.rowSpanColumn]?.length:''}
                                                                    // colSpan = {dataItem['expand'] === '>>'||dataItem['expand'] === '<<' ?1:''}
                                                                        key={this.props.className + "-" + dataItem[this.props?.config?.primaryKey] + "-" + dataItem[node.dataNode] + "-" + index}
                                                                        className={(this.props?.config?.borders?.includes(node.dataNode) ? "border-left-1-secondary " : "") +
                                                                            (this.props?.config?.isLanding ? (dataItem.is_confidential == "True" ? this.props?.config?.changeColourToRed?.includes(node.dataNode) ? "textColorToRed " : "" : "") :
                                                                                (this.props?.config?.changeColourToRed?.includes(dataItem[node.dataNode]) ? "textColorRed " : "")) +
                                                                            (this.props?.config?.changeColourToRed?.includes(dataItem[node.dataNode]) ? "textColorRed " : "") +
                                                                            (this.props?.config?.changeColourToGreen?.includes(dataItem[node.dataNode]) ? "textColorGreen " : "") +
                                                                            //(this.props?.config?.dataNodes.length == index+1 ? "border-right-0 " : ""  ) +
                                                                            (node.color ? node.color+' ':' ') + (node.letterCasing ? node.letterCasing+' ':' ')+
                                                                             (node.hasMultipleValue ?  ' hasMultiple ':' ')+
                                                                            "border-ver-1-secondary 1 padding-5"} >
                                                                        {dataItem["editing"] && this.props?.config?.inlineEdits?.includes(node.dataNode) ?
                                                                            <> </> :
                                                                            node.dataNode == "groups" ?
                                                                                dataItem[node.dataNode]?.map(item => (<div className="groupsStyles">{item}</div>)) :
                                                                                node.dataNode == "label" ?
                                                                                    <div className="labelNames" onClick={(e) => this.props.editData(dataItem, node.dataNode)}>
                                                                                        {dataItem[node.dataNode]}</div> :
                                                                                    this.props?.config?.hyperlinks?.includes(node.dataNode) ?
                                                                                        Array.isArray(dataItem[node.dataNode]) && this.props.config?.identityKey !=='Dashboard' ?
                                                                                            dataItem[node.dataNode]?.map((i, index, iArr) => <a href="javascript:void(0);" className='mr-2' onClick={(e) => this.props.hyperLinkNavigationCallback(i, node.dataNode)}>
                                                                                                {i?.label}{index === iArr?.length - 1 ? '' : ','}</a>) :
                                                                                            this.props?.config?.filtersLandingCasting ? (
                                                                                             // Customized for Dashboard page start 
                                                                                             <>
                                                                                                  
                                                                                                           <TableColumnExpand projectId={dataItem.project_id}
                                                                                                           value = {dataItem[node.dataNode]}
                                                                                                           node ={node}
                                                                                                           columnCount = { this.props.showSelectedColumnCount}
                                                                                                           dataItem = {dataItem}
                                                                                                           rowIndex ={rowIndex}
                                                                                                           hyperLinkNavigationCallback =  {this.props.hyperLinkNavigationCallback.bind(this)}
                                                                                                           isExpandLoading = {this.props?.isExpandLoading}
                                                                                                           selectedRowIndex = {this.props?.selectedRowIndex}
                                                                                                           expandStatus={this.props.expandStatus}
                                                                                                           />

                                                                                         {/* <a href="javascript:void(0);" className={dataItem[node?.dataNode + 'ShowRed'] && dataItem[node?.dataNode + 'ShowBold'] ? "textColorRed textstylebold" :
                                                                                        dataItem[node?.dataNode + 'ShowBold'] ? "textstylebold" : dataItem[node?.dataNode + 'ShowRed'] ? "textColorRed" : '	'} 
                                                                                        onClick={(e) => this.props.hyperLinkNavigationCallback(dataItem, node.dataNode)}> 
                                                                                             {node.dataNode==="contact" || node.dataNode==="occupation" ?<></>:
                                                                                                <p className={(node.dataNode==="project_name" || node.dataNode==="union")?"mainTitleWithCaps":"mainTitleWithCamelCase"} title={node.dataNode==="expand"?"expand":dataItem[node.dataNode]?.split("|")[0]}>
                                                                                                    {dataItem[node.dataNode]?.split("|")[0].length > node.maxCharacterLength ? dataItem[node.dataNode]?.toLowerCase()?.split("|")[0].substring(0, node.maxCharacterLength) + "..." : dataItem[node.dataNode]?.toLowerCase()?.split("|")[0]}
                                                                                                </p>} */}
                                                                                                {/* {(node.dataNode==="role" || node.dataNode==="agency_name") ?
                                                                                                <>
                                                                                                    <p className="secondRow" title={dataItem[node.dataNode]?.split("|")[1]}>{dataItem[node.dataNode]?.split("|")[1]?.length > node.maxCharacterLength ? dataItem[node.dataNode]?.toLowerCase()?.split("|")[1].substring(1, node.maxCharacterLength) + "..." : dataItem[node.dataNode]?.toLowerCase()?.split("|")[1]}</p>

                                                                                                    <p className="thirdRow" title={dataItem[node.dataNode]?.split("|")[2]}>{dataItem[node.dataNode]?.split("|")[2]?.length > node.maxCharacterLength ? dataItem[node.dataNode]?.toLowerCase()?.split("|")[2].substring(2, node.maxCharacterLength) + "..." : dataItem[node.dataNode]?.toLowerCase()?.split("|")[2]}</p>
                                                                                                
                                                                                                </>:<></>} */}
                                                                                                {/* </a>  */}
                                                                                                {/* {(node.dataNode==="role" || node.dataNode==="agency_name") ? <></>:<>
                                                                                                {node.dataNode==="contact" || node.dataNode==="occupation" ?<p className="alias1" title={dataItem[node.dataNode]?.split("|")[0]}>{dataItem[node.dataNode]?.split("|")[0]?.length > node.maxCharacterLength ? dataItem[node.dataNode]?.split("|")[0].substring(0, node.maxCharacterLength) + "..." : dataItem[node.dataNode]?.split("|")[0]}</p>:<></>}
                                                                                                <p className="alias1" title={dataItem[node.dataNode]?.split("|")[1]}>{dataItem[node.dataNode]?.split("|")[1]?.length > node.maxCharacterLength ? dataItem[node.dataNode]?.toLowerCase()?.split("|")[1].substring(1, node.maxCharacterLength) + "..." : dataItem[node.dataNode]?.toLowerCase()?.split("|")[1]}</p>

                                                                                                <p className="alias2" title={dataItem[node.dataNode]?.split("|")[2]}>{dataItem[node.dataNode]?.split("|")[2]?.length > node.maxCharacterLength ? dataItem[node.dataNode]?.toLowerCase()?.split("|")[2].substring(2, node.maxCharacterLength) + "..." : dataItem[node.dataNode]?.toLowerCase()?.split("|")[2]}</p>
                                                                                                
                                                                                                </>
                                                                                                } */}
                                                                                            </>) : (<a href="javascript:void(0);" className={dataItem[node?.dataNode + 'ShowRed'] && dataItem[node?.dataNode + 'ShowBold'] ? "textColorRed textstylebold" :
                                                                                        dataItem[node?.dataNode + 'ShowBold'] ? "textstylebold" : dataItem[node?.dataNode + 'ShowRed'] ? "textColorRed" : '	'}
                                                                                         onClick={(e) => this.props.hyperLinkNavigationCallback(dataItem, node.dataNode)}>{dataItem[node.dataNode]}</a>) :
                                                                                        this.props?.config?.shareIcon?.some(configItem => configItem.column === node.dataNode) ?
                                                                                            <MDBIcon icon={"share"} onClick /> :
                                                                                            this.props?.config?.toggleFields?.includes(node.dataNode) ? (dataItem[node.dataNode] != null ?
                                                                                                <MDBIcon icon={dataItem[node.dataNode] == true ? "check-circle" : "times"}
                                                                                                    className={dataItem[node.dataNode] == true ? "text-success" : "text-dark"} /> : <> </>) :
                                                                                                this.props?.config?.fileAttachments?.includes(node.dataNode) ?
                                                                                                    <BasicButton variant="outlined" icon="paperclip"
                                                                                                        type="inline" inputType="file" id={this.props?.objectType + "-" + this.props?.name}
                                                                                                        uploadFile={this.props?.handleFileSelect} />
                                                                                                    :
                                                                                                    this.state.selectOptionValues?.[node.dataNode] ?
                                                                                                        <>{this.state.selectOptionValues?.[node.dataNode].map((item) => {
                                                                                                            if (item.value == dataItem[node.dataNode])
                                                                                                                return (<> {item.label} </>);
                                                                                                        })}</> : 
                                                                                                        //Customized for Dashboard page start 
                                                                                                     <>                                                                                                      
                                                                                                        {                                                                                                     
                                                                                                            this.props.config?.identityKey ==='Dashboard'?
                                                                                                           <> <TableColumnExpand 
                                                                                                           projectId={dataItem.project_id}
                                                                                                            value = {dataItem[node.dataNode]}
                                                                                                            dataItem = {dataItem}
                                                                                                            node ={node}
                                                                                                            columnCount = { this.props.showSelectedColumnCount}
                                                                                                            rowIndex={rowIndex}
                                                                                                            hyperLinkNavigationCallback={this.props.hyperLinkNavigationCallback.bind(this)}
                                                                                                            isExpandLoading = {this.props?.isExpandLoading}
                                                                                                            selectedRowIndex = {this.props?.selectedRowIndex}
                                                                                                            expandStatus={this.props.expandStatus}
                                                                                                            /> </>:
                                                                                                         dataItem[node.dataNode]
                                                                                                            
                                                                                                        }
                                                                                                            </>

                                                                        }
                                                                        {
                                                                            this.props?.config?.dataNodeGroups?.[node.dataNode] ? (
                                                                                <>
                                                                                    {this.props?.config?.dataNodeGroups?.[node.dataNode]?.map((item) => (
                                                                                        dataItem["editing"] && this.props?.config?.inlineEdits?.includes(item) ? <> </>
                                                                                            : <div>{dataItem[item]}</div>))}
                                                                                </>) : <> </>
                                                                        }
                                                                        {
                                                                            this.props?.config?.columnWithIcon?.some(configItem => configItem.column === node.dataNode) ? (
                                                                                <>
                                                                                    {this.props?.config?.columnWithIcon?.map((item) => (
                                                                                        item.column == node.dataNode &&
                                                                                        (
                                                                                            <MDBIcon
                                                                                                icon={item.icon}
                                                                                                onClick={(e) => this.props.viewCallback(dataItem, node.dataNode)}
                                                                                            />
                                                                                        )
                                                                                    ))}
                                                                                </>
                                                                            ) : null
                                                                        }

                                                                        {dataItem["editing"] && this.props?.config?.inlineEdits?.includes(node.dataNode) ?
                                                                            <TableInlineEdits nodeName={node.dataNode} inputType={this.state.dataTypeValues[node.dataNode]}
                                                                                inputValue={this.state.listEdits?.[rowIndex]?.[node.dataNode]} index={rowIndex}
                                                                                handleInlineEditChange={this.handleInlineEditChange.bind(this)}
                                                                                selectOptions={this.state.selectOptionValues?.[node.dataNode]}
                                                                            /> : <> </>}

                                                                        {
                                                                            this.props?.config?.dataNodeGroups?.[node.dataNode] ? (
                                                                                <>
                                                                                    {this.props?.config?.dataNodeGroups?.[node.dataNode]?.map((item) =>
                                                                                        dataItem["editing"] && this.props?.config?.inlineEdits?.includes(item) ?
                                                                                            (<TableInlineEdits nodeName={item} inputType={"text"}
                                                                                                inputValue={this.state.listEdits?.[rowIndex]?.[item]} index={rowIndex}
                                                                                                handleInlineEditChange={this.handleInlineEditChange.bind(this)}
                                                                                            />) : <> </>)}
                                                                                </>) : <> </>
                                                                        }



                                                                    </TableCell>
                                                                ))
                                                            }
                                                            {!this.props?.config?.iscolumnFilterRequired && this.props?.config?.dataNodes?.map((node, index) => (node == 'check' ?
                                                        <TableCell className='FW800'>
                                                                <input type='checkbox' checked={dataItem[node]}
                                                                    onClick={(event, newValue) => {
                                                                        dataItem[node] = event.target.checked;
                                                                        this.props.arrayCheckUncheck(event.target.checked, dataItem);
                                                                    }}></input>                                                                  
                                                            </TableCell> : this.props?.config?.starIcon?.includes(node) && dataItem["is_primary"] === 1 ? (
                                                                <TableCell>
                                                                    {dataItem[node] + " "} <MDBIcon fas icon={"star"} />
                                                                </TableCell>
                                                            ) :
                                                                this.props?.config?.isList?.includes(node) ? (
                                                                    <TableCell className={"border-ver-1-secondary padding-5"} dangerouslySetInnerHTML={{ __html: dataItem[node] }}>
                                                                    </TableCell>
                                                                ) :
                                                                    <TableCell
                                                                        key={this.props.className + "-" + dataItem[this.props?.config?.primaryKey] + "-" + dataItem[node] + "-" + index}
                                                                        className={(this.props?.config?.borders?.includes(node) ? "border-left-1-secondary " : "") +
                                                                            (this.props?.config?.changeColourToRed?.includes(dataItem[node]) ? "textColorRed " : "") +
                                                                            (this.props?.config?.changeColourToGreen?.includes(dataItem[node]) ? "textColorGreen " : "") +
                                                                            //(this.props?.config?.dataNodes.length == index+1 ? "border-right-0 " : ""  ) +
                                                                            "border-ver-1-secondary padding-5 word-break"} >
                                                                        {dataItem["editing"] && this.props?.config?.inlineEdits?.includes(node) ?
                                                                            <> </> :
                                                                            (node === 'role_no' || node === 'deal_date')?
                                                                            <div className="d-flex justify-content-center align-items-center"><span>{dataItem[node]}</span></div>
                                                                            : (node === "performer_notes")?<MDBTooltip wrapperProps={{ color: 'transparent' }} title={dataItem[node]}><span className={"PerformerNotesWrapper"}>{dataItem[node]}</span></MDBTooltip>:
                                                                            ((node === "notes" && this.props?.config?.projectDetailsProdCompanyTable) || (node === "union" && this.props?.config?.projectDetailsProdCompanyTable) || (node === "signator" && this.props?.config?.projectDetailsProdCompanyTable))?
                                                                            <span title={dataItem[node]}>{dataItem[node]}</span>
                                                                            : node == "groups" ?
                                                                                dataItem[node]?.map(item => (<div>{<a href="javascript:void(0);" onClick={(e) => this.props.groupElementCallback(item?.value, node, dataItem)}>{item?.label === 'add a button' ? <BasicButton text="Add to Episode" /> : item?.label}</a>}</div>)) :
                                                                                node === 'contract_list' ? 
                                                                dataItem[node]?.map((con,conIdx) =>  (<div key={conIdx+"contract"}>{con?.contract? (con?.contract.includes("-")?(<><span className="dateContainer">{con?.contract.split("-")[0]}</span><span className="contractContainer">{con?.contract.split("-")[1]+ "  "}</span></>):<><span className="noDate dateContainer"></span><span className="contractContainer">{con?.contract+ "  "}</span></>):""} {con.isClicked ? <CircularProgress color="inherit" size={10} /> : con.template_name !== "N/A" ? <MDBIcon icon={'file-pdf'} onClick={(e) => this.props?.pdfDownload(dataItem, con?.performer_deal_contract_id, node, conIdx)}/> : ""}{con?.void_revised_flag? (con?.void_revised_flag?.toLowerCase()?.trim()===("void")||con?.void_revised_flag?.toLowerCase()?.trim()===("revised"))?<span className={con?.void_revised_flag.toLowerCase().trim()===("void")?"voidIdentifier":con?.void_revised_flag.toLowerCase().trim()===("revised")?"revisedIdentifier":""}>{"  "+con?.void_revised_flag}</span>:con?.void_revised_flag?.toLowerCase()?.trim()===("void revised")?<><span className="voidIdentifier">{"  "+con?.void_revised_flag.split(" ")[0]}</span><span className="revisedIdentifier">{"  "+con?.void_revised_flag.split(" ")[1]}</span></>:<></>:""}</div>))
                                                                                :
                                                                                node == "label" ?
                                                                                    <div className="labelNames" onClick={(e) => this.props.editData(dataItem, node)}>
                                                                                        {dataItem[node]}</div> :
                                                                                    this.props?.config?.hyperlinks?.includes(node) ?
                                                                                            <a href="javascript:void(0);" title={dataItem[node]}
                                                                                                onClick={(e) => this.props.config.titleAction ? (this.props?.editCallback(dataItem[this.props.config.primaryKey], dataItem)) :
                                                                                                    this.props.hyperLinkNavigationCallback(dataItem, node,e)}>
                                                                                                {dataItem[node]}</a> :
                                                                                        this.props?.config?.hyperlinktexticon?.includes(node) ?
                                                                                            <a href="javascript:void(0);" onClick={(e) => this.props.hyperLinkTextIconNavigationCallback(dataItem, node)}>
                                                                                                <div>
                                                                                                    <span>{dataItem[node]+" "}<MDBIcon icon="location-arrow"/></span>
                                                                                                    {/* </span><MDBIcon icon="pen" /></span>  */}
                                                                                                </div>
                                                                                            </a> :
                                                                                            this.props?.config?.buttonWithText?.includes(node) ?
                                                                                                    <BasicButton variant="contained" type="inline" className="outlined-btn" text={node} onClick={(e) => this.props?.buttonWithFieldFun(dataItem, node, e)} /> :
                                                                                                    this.props?.config?.popUpField?.includes(node) ?
                                                                                                        <>
                                                                                                            {dataItem[node]?.length > 100 ? <Tooltip clickable arrow componentsProps={{
                                                                                                                tooltip: {
                                                                                                                    sx: {
                                                                                                                        maxWidth:'500px',
                                                                                                                        fontSize:'13px',
                                                                                                                        bgcolor: 'common.white',
                                                                                                                        border: '1px solid #6c757d',
                                                                                                                        color: 'common.black',
                                                                                                                        '& .MuiTooltip-arrow': {
                                                                                                                            color: 'common.white',
                                                                                                                            '&::before':{
                                                                                                                                border: '1px solid #6c757d',
                                                                                                                              }
                                                                                                                        },
                                                                                                                    },
                                                                                                                },
                                                                                                            }}
                                                                                                                title={dataItem[node]}>
                                                                                                                <span>{dataItem[node]?.substring(0, 100) + "..."} </span>
                                                                                                            </Tooltip> : <span>{dataItem[node]}</span>}
                                                                                                </>
                                                                                                    // <>{dataItem[node] ? <>{dataItem[node]?.length > 100 ? <Tooltip title={dataItem[node]}><span>{dataItem[node]?.substring(0, 100) + "..."}
                                                                                                    // </span></Tooltip> : <span>{dataItem[node]}</span>}
                                                                                                    //      <BasicButton icon='pen' onClick={() => this.props.notesModal(dataItem)} /> 
                                                                                                    //     </> : 
                                                                                                    //      <BasicButton icon='plus-circle' onClick={() => this.props.notesModal(dataItem)} />
                                                                                                    //     }</>
                                                                                                    :
                                                                                                    this.props?.config?.nodeFields?.some(
                                                                                                        (configItem) => configItem.column === node) ?
                                                                                                        <MDBIcon
                                                                                                            icon={dataItem[node] ? nodeItem.icon : ""}
                                                                                                        /> :
                                                                                                        this.props?.config?.toggleFields?.includes(node) ? (dataItem[node] != null ?
                                                                                                            (this.props?.config?.tooltips?.includes(node) && dataItem[node]) ?
                                                                                                            <div className="d-flex justify-content-center align-items-center">
                                                                                                                <Tooltip clickable arrow
                                                                                                                 componentsProps={{
                                                                                                                    tooltip: {
                                                                                                                      sx: {
                                                                                                                        maxWidth:'500px',
                                                                                                                        fontSize:'13px',
                                                                                                                        bgcolor: 'common.white',
                                                                                                                        border: '1px solid #6c757d',
                                                                                                                        color:'common.black',
                                                                                                                        '& .MuiTooltip-arrow': {
                                                                                                                          color: 'common.white',
                                                                                                                          '&::before':{
                                                                                                                            border: '1px solid #6c757d',
                                                                                                                          }
                                                                                                                        },
                                                                                                                      },
                                                                                                                    },
                                                                                                                  }}
                                                                                                                   title={<div dangerouslySetInnerHTML={{ __html: (dataItem[this.props?.config?.creditTooltipsText?.[node]]) }}/>}
                                                                                                                          disableHoverListener={!this.props?.config?.tooltips?.includes(node) || !dataItem[this.props?.config?.creditTooltipsText?.[node]]}>
                                                                                                                    <span><MDBIcon icon={dataItem[node] == true ? "check-circle" : ""}
                                                                                                                            className={dataItem[node] == true ? "text-success" : ""}  /></span>
                                                                                                                </Tooltip></div> :
                                                                                                                <div className="d-flex justify-content-center align-items-center">
                                                                                                                <span ><MDBIcon icon={dataItem[node] == true ? "check-circle" : ""}
                                                                                                                    className={dataItem[node] == true ? "text-success" : ""} /></span></div> : 
                                                                                                                <>                                                                                                               
                                                                                                                    {dataItem[node] === null ?
                                                                                                                        <div className="d-flex justify-content-center align-items-center">
                                                                                                                            <Tooltip clickable arrow componentsProps={{
                                                                                                                                tooltip: {
                                                                                                                                    sx: {
                                                                                                                                        maxWidth:'500px',
                                                                                                                                        fontSize:'13px',
                                                                                                                                        bgcolor: 'common.white',
                                                                                                                                        border: '1px solid #6c757d',
                                                                                                                                        color: 'common.black',
                                                                                                                                        '& .MuiTooltip-arrow': {
                                                                                                                                            color: 'common.white',
                                                                                                                                            '&::before':{
                                                                                                                                                border: '1px solid #6c757d',
                                                                                                                                              }
                                                                                                                                        },
                                                                                                                                    },
                                                                                                                                },
                                                                                                                            }}
                                                                                                                                title={<div dangerouslySetInnerHTML={{ __html: (dataItem[this.props?.config?.creditTooltipsText?.[node]]) }} />}
                                                                                                                                disableHoverListener={!this.props?.config?.tooltips?.includes(node) || !dataItem[this.props?.config?.creditTooltipsText?.[node]]}>
                                                                                                                                <span ><BasicCheckbox className="inlineCheckbox" /></span>
                                                                                                                            </Tooltip></div> : <></>}
                                                                                                               </>) :
                                                                                                            this.props?.config?.nodeFields?.includes(node) ?
                                                                                                                <React.Fragment>
                                                                                                                    {dataItem.is_favorite === 0 ? (
                                                                                                                        <StarBorderIcon
                                                                                                                            variant="outlined"
                                                                                                                            id={dataItem[this.props.config.primaryKey]}
                                                                                                                            onClick={(e) => this.props?.favIconCallback(dataItem, node)}
                                                                                                                            className={dataItem.is_favorite === 0 ? '' : 'star-border'}
                                                                                                                        />
                                                                                                                    ) : (
                                                                                                                        <StarIcon
                                                                                                                            variant="outlined"
                                                                                                                            id={dataItem[this.props.config.primaryKey]}
                                                                                                                            onClick={(e) => this.props?.favIconCallback(dataItem, node)}
                                                                                                                            className={dataItem.is_favorite === 0 ? '' : 'star-border'}
                                                                                                                        />
                                                                                                                    )}
                                                                                                                </React.Fragment>
                                                                                                                :
                                                                                                                this.props?.config?.fileAttachments?.includes(node) ?
                                                                                                                    !dataItem[node] ?
                                                                                                                        dataItem["editing"] ? <div className="d-flex justify-content-center"><BasicButton variant="outlined" icon="upload"
                                                                                                                            type="inline" inputType="file" id={'newFIle' + dataItem[[this.props.config.primaryKey]]} //id={this.props?.objectType + "-" + this.props?.name}
                                                                                                                            uploadFile={(file) => this.props?.handleFileSelect(file, dataItem , null,this.props.config.primaryKey )} /></div> : ''
                                                                                                                        :
                                                                                                                        <div className="d-flex justify-content-center">
                                                                                                                        <BasicButton variant="outlined" icon="paperclip"
                                                                                                                            type="inline" id={'editFile' + dataItem[[this.props.config.primaryKey]]} //id={this.props?.objectType + "-" + this.props?.name}
                                                                                                                            onClick={(e) => dataItem["editing"] ? this.props.handleOpenBillingAttachment(e, node, dataItem) : this.props.downloadFileSelect(e, node, dataItem) } /></div>
                                                                                                                    :
                                                                                                                    this.state.selectOptionValues?.[node] ?
                                                                                                                        <>{this.state.selectOptionValues?.[node]?.map((item) => {
                                                                                                                            if (item.value == dataItem[node] && dataItem[node])
                                                                                                                                return (<span className={this.props?.config?.colouredCoulms?.find(clrCol => clrCol?.columnName === node) && dataItem[node] ? this.props?.config?.colouredCoulms?.find(clrCol => clrCol?.columnName === node)?.colours[dataItem[node]?.substring(0, 3)] : ""}> {item.label} </span>);
                                                                                                                        })}</> :
                                                                                                                        <> {(this.props?.config?.tooltips?.includes(node) && dataItem[node]) ? <Tooltip  clickable arrow componentsProps={{
                                                                                                                            tooltip: {
                                                                                                                              sx: {
                                                                                                                                maxWidth:'500px',
                                                                                                                                fontSize:'13px',
                                                                                                                                bgcolor: 'common.white',
                                                                                                                                border: '1px solid #6c757d',
                                                                                                                                color:'common.black',
                                                                                                                                '& .MuiTooltip-arrow': {
                                                                                                                                  color: 'common.white',
                                                                                                                                  '&::before':{
                                                                                                                                    border: '1px solid #6c757d',
                                                                                                                                  }
                                                                                                                                },
                                                                                                                              },
                                                                                                                            },
                                                                                                                          }}
                                                                                                                          title={dataItem[node]}>
                                                                                                                            <span>{dataItem[node]?.length > 40 ? dataItem[node]?.substring(0, 40) + "..." : dataItem[node]}</span>
                                                                                                                        </Tooltip> : dataItem[node]}</>

                                                                        }
                                                                        {
                                                                            this.props?.config?.dataNodeGroups?.[node] ? (
                                                                                <>
                                                                                    {this.props?.config?.dataNodeGroups?.[node]?.map((item) => (
                                                                                        dataItem["editing"] && this.props?.config?.inlineEdits?.includes(item?.nodeValue) ? <> </>
                                                                                            : ((dataItem[item?.nodeValue] || item?.showLabelWhenNull) && 
                                                                                            <div className={item?.textColor}>
                                                                                                {item?.nodeHeader}
                                                                                                {dataItem[item?.nodeValue]}</div>)))}
                                                                                </>) : <> </>
                                                                        }

                                                                        {dataItem["editing"] && this.props?.config?.inlineEdits?.includes(node) ?
                                                                        <div className={(this.state.dataTypeValues[node]=== 'checkbox')? 'd-flex justify-content-center align-items-center':''}>
                                                                            <Tooltip clickable arrow
                                                                            componentsProps={{
                                                                                tooltip: {
                                                                                  sx: {
                                                                                    maxWidth:'500px',
                                                                                    fontSize:'13px',
                                                                                    bgcolor: 'common.white',
                                                                                    border: '1px solid #6c757d',
                                                                                    color:'common.black',
                                                                                    '& .MuiTooltip-arrow': {
                                                                                      color: 'common.white',
                                                                                      '&::before':{
                                                                                        border: '1px solid #6c757d',
                                                                                      }
                                                                                    },
                                                                                  },
                                                                                },
                                                                              }}
                                                                            disableFocusListener={!this.props?.config?.tooltips?.includes(node) || (!this.state.listEdits?.[rowIndex]?.[this.props?.config?.creditTooltipsText?.[node]])}
                                                                            disableHoverListener={!this.props?.config?.tooltips?.includes(node) || (!this.state.listEdits?.[rowIndex]?.[this.props?.config?.creditTooltipsText?.[node]])}
                                                                            title={<div dangerouslySetInnerHTML={{ __html: (this.state.listEdits?.[rowIndex]?.[this.props?.config?.creditTooltipsText?.[node]]) }} />}  >
                                                                                 <span>
                                                                                <TableInlineEdits nodeName={node} inputType={this.state.dataTypeValues[node]}
                                                                                    inputValue={this.state.listEdits?.[rowIndex]?.[node]} index={rowIndex}
                                                                                    handleInlineEditChange={this.handleInlineEditChange.bind(this)}
                                                                                    selectOptions={this.state.selectOptionValues?.[node]}
                                                                                />
                                                                            </span>
                                                                            </Tooltip></div>
                                                                            : <> </>}

                                                                        {
                                                                            this.props?.config?.dataNodeGroups?.[node] ? (
                                                                                <>
                                                                                    {this.props?.config?.dataNodeGroups?.[node]?.map((item) =>
                                                                                        dataItem["editing"] && this.props?.config?.inlineEdits?.includes(item?.nodeValue) ?
                                                                                            <MDBRow className={item?.textColor}>
                                                                                                <MDBCol md={12}>
                                                                                                    <span>{item?.nodeHeader}</span>
                                                                                                </MDBCol>
                                                                                                <MDBCol md={12}>
                                                                                                    <TableInlineEdits nodeName={item?.nodeValue} inputType={"text"} isAlphabetVar={item?.isAlphabetVar || false}
                                                                                                        inputValue={this.state.listEdits?.[rowIndex]?.[item?.nodeValue]} index={rowIndex}
                                                                                                        handleInlineEditChange={this.handleInlineEditChange.bind(this)}
                                                                                                    />
                                                                                                </MDBCol>
                                                                                            </MDBRow>
                                                                                            : <> </>)}
                                                                                </>) : <> </>
                                                                        }



                                                                    </TableCell>
                                                        ))
                                                        }
                                                        {this.props?.config?.actions && 
                                                            <TableCell key={this.props.className + "action"} className={`${this.props.config.primaryKey === 'performer_deal_billing_id' ? 'border-ver-1-secondary padding-0':'border-ver-1-secondary'}`} > <div className="row">
                                                                <div className='minus-align'>
                                                                    {dataItem?.editing && this.props.config.primaryKey !='performer_deal_billing_id'&& <MDBIcon icon="minus" onClick={this.props?.readCallback}></MDBIcon>}
                                                                    {this.props?.config?.actions?.map((action, indexNo) => (

                                                                        <div key={this.props.className + "-" + indexNo} className={this.props?.config?.isLocation === true && action === "pen" ? "col-md padding-3 location" : this.props.config.primaryKey !='performer_deal_billing_id' ?'col-md padding-3':''}
                                                                            disabled={this.props?.tabValue === 1 ? (!dataItem?.is_edit) : false}
                                                                            onClick={(e) => action === "arrows-alt-v" ?
                                                                                this.props?.handleOpenPopUp(dataItem[this.props.config.primaryKey], dataItem) :
                                                                                action === "circle" ? this.props?.handleMarkAsPrimary(dataItem[this.props.config.primaryKey], dataItem)
                                                                                    : action === "pen" ?
                                                                                        (dataItem["editing"] ? this.props?.saveCallback(this.state.listEdits, rowIndex, dataItem[this.props.config.primaryKey], dataItem) :
                                                                                            this.props?.editCallback(dataItem[this.props.config.primaryKey], dataItem)
                                                                                        ) : action === "arrows-alt-v" || action === "file" ? this.props?.handleEditPopOverCallBack(this.state.listEdits, rowIndex, dataItem[this.props.config.primaryKey], dataItem, e) :
                                                                                            (action === "plus" ? this.props?.handleAddCallback(dataItem[this.props.config.primaryKey]) :
                                                                                                (action === "trash-alt" || action === "minus-circle" || action === "times" ? this.props?.handleDelCallBack(dataItem[this.props.config.primaryKey], dataItem) : null))}>
                                                                            {action === "view" ? (<a href="#">{action}</a>) :
                                                                                action === "circle" ? (dataItem.is_primary === 1 ? <MDBIcon far icon="dot-circle" /> : <MDBIcon far icon="circle" />) :
                                                                                    ((action === "pen" && dataItem["editing"]
                                                                                        ? (<>
                                                                                            <Tooltip clickable arrow
                                                                                                componentsProps={{
                                                                                                    tooltip: {
                                                                                                        sx: {
                                                                                                            maxWidth: '500px',
                                                                                                            fontSize: '13px',
                                                                                                        },
                                                                                                    },
                                                                                                }}
                                                                                                title={"Save"} >                                                                                               
                                                                                                <span class="padding-3">
                                                                                                    <MDBIcon className='cursor-pointer padding-7 leftMarginForSave action-icon'  icon={"save"} color="primary" />
                                                                                                </span>
                                                                                            </Tooltip>
                                                                                        </>): (action === "pen" && !dataItem["editing"] )?<Tooltip clickable arrow
                                                                                        componentsProps={{
                                                                                            tooltip: {
                                                                                                sx: {
                                                                                                    maxWidth: '500px',
                                                                                                    fontSize: '13px',
                                                                                                },
                                                                                            },
                                                                                        }}
                                                                                        title={"Edit"} >                                             
                                                                                        <span class="padding-3">
                                                                                            <MDBIcon className='action-icon edit-tooltip'  icon={action} />
                                                                                        </span>
                                                                                    </Tooltip>: (action === "times" )?
                                                                                        <Tooltip clickable arrow
                                                                                        componentsProps={{
                                                                                            tooltip: {
                                                                                                sx: {
                                                                                                    maxWidth: '500px',
                                                                                                    fontSize: '13px',
                                                                                                },
                                                                                            },
                                                                                        }}
                                                                                        title={"Delete"} >                                                                                               
                                                                                        <span class="padding-3">
                                                                                            <MDBIcon className='action-icon delete-tooltip'  icon={action} />
                                                                                        </span>
                                                                                    </Tooltip>
                                                                                        : (action === "trash-alt" ?
                                                                                            (userPermissions?.isRegular || userPermissions?.isReadOnly ?
                                                                                                null : <MDBIcon icon={action} className="action-icon" />) :
                                                                                            (userPermissions?.isReadOnly
                                                                                                ? null
                                                                                                : this.props?.config?.isLocation && action === "file" ? !dataItem["comments"] ? "" : <MDBIcon icon={action} className="action-icon" /> : (!this.props.config.showEditIcon) && <MDBIcon icon={action} className="action-icon" />)
                                                                                        )))}
                                                                        </div>

                                                                    ))
                                                                    }
                                                                { dataItem?.editing && this.props.config.primaryKey === 'performer_deal_billing_id' &&
                                                                    <Tooltip clickable arrow
                                                                        componentsProps={{
                                                                            tooltip: {
                                                                                sx: {
                                                                                    maxWidth: '500px',
                                                                                    fontSize: '13px',
                                                                                },
                                                                            },
                                                                        }}
                                                                        title={"Cancel"} >
                                                                        <span class="padding-7 cursor-pointer">
                                                                            <MDBIcon icon="times" className="padding-7 cursor-pointer" onClick={this.props?.readCallback} />
                                                                        </span>
                                                                    </Tooltip>
                                                                }
                                                                </div>
                                                            </div></TableCell>
                                                        }
                                                    </TableRow>
                                                   <> {/* Customized for Dashboard page start */}</>
                                                    {dataItem?.performer?.length > 0 && dataItem['expand'] === '>>' && dataItem?.performer?.map((expandRowdataItem, expandRowrowIndex) => (                                                        
                                                            <> 
                                                        {expandRowrowIndex !== 0 && <TableRow className= {(rowIndex % 2  == 0 ?'even ':'odd ' ) + 'expandedRow'}>
                                                        {this.props?.config?.headings.filter(item => item.isColumn == true)?.map((node, index) =>
                                                                <> {node.dataNode !== 'expand' &&  node.dataNode !== 'project_name'
                                                                 && node.dataNode !== 'crew_name' && node.dataNode !== 'crew_occupation' && 
                                                                <TableCell className={node.dataNode +' padding-5 ' +(node.color ? node.color+' ':' ') + (node.letterCasing ? node.letterCasing+' ':' ')+
                                                                            (node.hasMultipleValue ?  ' hasMultiple ':' ')}>
                                                                                <TableColumnExpand projectId={dataItem.project_id}
                                                                                    value={expandRowdataItem.hasOwnProperty([node.dataNode]) ?  expandRowdataItem[node.dataNode]: expandRowdataItem[node.altDataNode]}
                                                                                    node={node}
                                                                                    columnCount = { this.props.showSelectedColumnCount}
                                                                                    dataItem={expandRowdataItem}
                                                                                    rowIndex={rowIndex}
                                                                                    hyperLinkNavigationCallback={this.props.hyperLinkNavigationCallback.bind(this)}
                                                                                    isExpandLoading = {this.props?.isExpandLoading}
                                                                                    selectedRowIndex = {this.props?.selectedRowIndex}
                                                                                    expandStatus={this.props.expandStatus}
                                                                                    mode={'normal'}
                                                                                />
                                                                            </TableCell>}</>
                                                                        )}
                                                        </TableRow>}</>
                                                        
                                                        ))}
                                                            {this.props?.selectedExpandedData?.length > 0 && dataItem['expand'] === '<<' && this.props?.selectedExpandedData?.map((expandedDataItem, expandRowIndex) => (
                                                                <>
                                                                    {expandRowIndex !== 0 && <TableRow id= {"expandedRow"} className= {(this.props?.selectedRowIndex % 2  == 0 ?'even ':'odd ' ) + 'expandedRow'}>
                                                                        {this.props?.config?.headings.filter(item => item.isColumn == true)?.map((node, index) =>
                                                                          <>
                                                                         {node.dataNode !== 'expand'
                                                                         &&  node.dataNode !== 'project_name'
                                                                         && node.dataNode !== 'crew_name' && node.dataNode !== 'crew_occupation'
                                                                          &&  <TableCell className={ node.dataNode+ ' padding-5 ' +(node.color ? node.color+' ':' ') + (node.letterCasing ? node.letterCasing+' ':' ')+
                                                                            (node.hasMultipleValue ?  ' hasMultiple ':' ')}>
                                                                                <TableColumnExpand projectId={expandedDataItem.project_id}
                                                                                    value={expandedDataItem.hasOwnProperty([node.dataNode]) ?  expandedDataItem[node.dataNode]: expandedDataItem[node.altDataNode]}
                                                                                    node={node}
                                                                                    columnCount = { this.props.showSelectedColumnCount}
                                                                                    dataItem={expandedDataItem}
                                                                                    rowIndex={rowIndex}
                                                                                    hyperLinkNavigationCallback={this.props.hyperLinkNavigationCallback.bind(this)}
                                                                                    isExpandLoading = {this.props?.isExpandLoading}
                                                                                    selectedRowIndex = {this.props?.selectedRowIndex}
                                                                                    expandStatus={this.props.expandStatus}
                                                                                    mode={'expand'}
                                                                                />
                                                                            </TableCell>
                                                                                }
                                                                              </>
                                                                        )}
                                                                    </TableRow>}
                                                                </>
                                                            ))}
                                                        </>
                                            ))
                                            } 
                                            {/* Customized for Dashboard page end */}
                                            {
                                                this.props?.list?.length <= 0 &&
                                                <TableRow >
                                                    <TableCell className="text-align-center" colSpan={this.props?.config?.headings?.length}>
                                                        {/* <b>{this.props?.noTableDataHeading ? this.props?.noTableDataHeading : "No results"}</b> */}
                                                        <b>No results</b>
                                                    </TableCell>
                                                </TableRow>
                                            }
                                        </TableBody>
                                    )
                                }
                            </Table>
                            }
                        </TableContainer>

                    </div>
                </div >
            </>
        )
    }
}


export default withUserContext(withFilterContext(TableComponent));