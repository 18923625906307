// import { MDBContainer } from 'mdb-react-ui-kit';
import React from 'react';
import { withUserContext } from "../../../contexts/UserContext";
// import Modal from '@material-ui/core/Modal';
// import { withStyles } from '@material-ui/core/styles';
import { MDBContainer, MDBRow, MDBCol, MDBIcon } from 'mdbreact'
import CancelIcon from '@material-ui/icons/Cancel';
// import { newLocationDetails } from './config';
import BasicTextField from '../SharedComponents/BasicTextField/BasicTextField';
import DateField from '../SharedComponents/DateField/DateField';
import BasicTextArea from '../SharedComponents/BasicTextArea/BasicTextArea';
import SelectField from '../SharedComponents/SelectField/SelectField';
// import ProdCompany from './ProdCompanyy';
import BasicButton from '../SharedComponents/BasicButton/BasicButton';
import BasicLabel from '../SharedComponents/BasicLabel/BasicLabel';
import AddCircleIcon from '@material-ui/icons/AddCircle';
import RemoveCircleIcon from '@material-ui/icons/RemoveCircle';
import CasterService from '../../../services/service';
import * as Constants from '../../../constants/constants';
import { getFormattedDate, validatePlusBracketHypenNumbersOnly, formatPhoneNumbers, validateEmail } from '../../Common/Helper';
// import Autocomplete from '@material-ui/lab/Autocomplete';
// import TextField from '@material-ui/core/TextField';
// import BasicLabel from '../SharedComponents/BasicLabel/BasicLabel';
import CircularProgress from '@material-ui/core/CircularProgress';
import Radio from "@material-ui/core/Radio";
// import { validateCharNumOnlyWithSpace, validateCharNumWithHyphenSlash } from '../../Common/Helper';
import messages from '../../Common/Messages.json';
// import sizeLimits from '../../Common/SizeLimits.json';
// import MessageModal from '../SharedComponents/MessageModal';
// import { withUserContext } from '../../../contexts/UserContext';
// import './AddProjectLocation.scss';
import { initialObjectConfig, phoneObjData, occupationList, locationList, companyList } from './Config';
import './EditRepresentative.scss';
import PhoneComponent from '../../Common/DefaultPhoneComponent/PhoneComponent';
import InputSelectRadio from '../../Common/InputSelectRadioInline/InputSelectRadio';
import AddressComponent from '../../Common/AddressComponent/AddressComponent';
import SearchSelectField from '../SharedComponents/SearchSelectField';
import LocationComponent from '../../Common/LocationComponent/LocationComponent';
import { locationConfig } from '../Agency/Config';
import SearchFieldWithAddValue from "../SharedComponents/SearchFieldWithAddValue";
import ModalComponent from '../../Common/DefaultModalLayout/Modal/Modal';
import { agencyNavList } from '../../../constants/NavList';
import NewAgency from '../../Common/NewAgency/NewAgency';

class EditRepresentative extends React.Component {
    constructor(props) {
        super(props);
        this.state = {
            representativeList: [],
            // postInitiated: false,
            // fieldValid: false,
            akaIndex: 2,
            productionIndex: 2,
            isPosting: false,
            // isProductionDetails: false,
            // isFetchingFeatureTypes: false,
            // isFetchingDistributionTypes: false,
            // isFetchingIntendedMarket: false,
            // formEdited: false,
            isSubmit: false,
            akaRadio: 0,
            phoneIndex: 2,
            isFetchingRepresentativeDetails: false,
            openLocationPopover: false,
            setLocationPopover: false,
            locationConfig: locationConfig,
            isEdit: null,
            openAgencyPopOver:null,
            showNewAgencyPopup:null,
            loadingLocation:null
        }

    }

    componentDidMount = () => {
        if (this.props.mode === "New") {
            this.setState({
                newLocationList: JSON.parse(JSON.stringify({ ...newLocationDetails }))
            })
        } else {

            // this.getLookupValues('Country');
            // this.getLookupValues('State');
            // this.getLookupValues('PROJECT_STUDIO');
            // this.getLookupValues('PROJECT_DISTRIBUTOR');
            // this.getLookupValues('CONTRACT_TYPE');
            // this.getLookupValues('PROJECT_TYPE');
            //     this.getFeatureType();
            //     this.getDistributionType();
            //     this.getIntendedMarket();
        }
        this.getLookupValues('Phone');
        this.getLookupValues('Email');
        this.getRepOccupation();
        //this.getAgencyLocation()
        this.getCompanyNames()
        if (this.props?.mode == 'edit') {
            this.getEditRepresentativeDetails()
        }
    }

    getRepOccupation = () => {
        CasterService.getData(Constants.CasterServiceBaseUrl + `/casterFeatureSearch?objectType=REPRESENTATIVE_OCCUPATION&searchString=null`)
            .then((response) => {
                if (response?.data?.error) {
                    console.log('error from search', repsonse?.data?.error)
                } else {
                    let formattedList = response?.data?.map(i => ({ value: i?.occupation, label: i?.occupation, id: i?.id || null }))
                    this.setState({ occupationOptions: formattedList })
                }
            },
                (err) => {
                    console.log("Post agency details error: " + err);
                });
    }

    // getAgencyLocation = () => {
    //     CasterService.getData(Constants.CasterServiceBaseUrl + `/casterFeatureSearch?objectType=AGENCY_LOCATION&searchString=null`)
    //         .then((response) => {
    //             if (response?.data?.error) {
    //                 console.log('error from search', repsonse?.data?.error)
    //             } else {
    //                 let formattedList = response?.data?.map(i => ({ value: i?.location_name, label: i?.location_name, id: i?.id }))
    //                 this.setState({ locationOptions: formattedList })
    //                 console.log("post response", response);
    //             }
    //         },
    //             (err) => {
    //                 console.log("Post agency details error: " + err);
    //             });
    // }

    getRepresentativesList = (objectType, searchString) => {
        CasterService.getData(Constants.CasterServiceBaseUrl + `/casterFeatureSearch?objectType=${objectType}&searchString=${searchString}`)
            .then((response) => {
                if (response?.data?.error) {
                    console.log('error from search', repsonse?.data?.error)
                } else {
                    let formattedList = response?.data?.map(i => ({ value: i?.agency_name, text: i?.agency_name, id: i?.id }))
                    this.setState({ company_options: formattedList, backup_company_options: response?.data })
                    console.log("post response", response);
                }
            },
                (err) => {
                    console.log("Post agency details error: " + err);
                });
    }

    getCompanyNames = () => {
        CasterService.getData(Constants.CasterServiceBaseUrl + `/casterFeatureAgencyDetails?id=${this.props?.project_id}`)
            .then(response => {
                if (!response || response?.data?.error) {
                    console.log("error", response?.data?.error)
                }
                else {
                    let formattedList = response?.data?.map(item => ({ value: item?.agency_name, label: item?.agency_name }))
                    this.setState({ companyOptions: formattedList })
                }
            }, err => {
                console.log("error from lookup")
            })
    }
    getLookupValues(type) {
        // casterFeatureLookup
        CasterService.getData(Constants.CasterServiceBaseUrl + `/casterFeatureLookup?objectType=${type}`)
            .then(response => {
                if (!response || response?.data?.error) {
                    console.log("error", response?.data?.error)
                }
                else {
                    let formattedList = response?.data?.map(item => ({ id: item?.lookup_data_id, value: item?.lookup_value, label: item?.lookup_value }))
                    if (type == 'Gender') {
                        this.setState({ genderOptions: formattedList })
                    } else if (type == 'Ethnicity') {
                        this.setState({ ethnicityOptions: formattedList })
                    } else if (type == 'Country') {
                        this.setState({ countryOptions: formattedList })
                    }
                    else if (type == 'Email') {
                        this.setState({ emailOptions: formattedList })
                    }
                    else if (type == 'Phone') {
                        this.setState({ phoneOptions: formattedList })
                    } else if (type == 'Social') {
                        this.setState({ socialOptions: formattedList })
                    } else if (type == 'AGENCY_LOCATION') {
                        this.setState({ locationOptions: formattedList })
                    }
                }
            }, err => {
                console.log("error from lookup")
            })

    }

    formatNewProductionDetailsResponse = (response) => {
        if (response?.aliases?.length === 0) {
            response?.aliases.push(JSON.parse(JSON.stringify({ ...aliasList })));
        }
        if (response?.production_companies?.length === 0) {
            response?.production_companies.push(JSON.parse(JSON.stringify({ ...companiesList })));
        }
        return response;
    }

    onAddressChange(id, key, value) {
        if (key == "address") {
            let addressList = this.state?.representativeList?.address?.length > 0 ? JSON.parse(JSON.stringify(this.state?.representativeList?.address)) : [];
            let addressIndex = addressList?.findIndex(i => i.performer_deal_representative_address_id == id);
            if (key == 'is_primary') {
                addressList?.map(i => i.is_primary = 0);
                addressList[addressIndex][key] = 1;
            } else if (key == 'is_delete') {
                addressList[addressIndex][key] = 1;
            } else if (id) {
                addressList[addressIndex] = value;
            } else {
                value.performer_deal_representative_address_id = Date.now();
                value.type = "new"
                let newAddressList = addressList.filter(item => !item.is_delete)
                if (newAddressList?.length === 0) {
                    value["is_primary"] = 1
                } else {
                    if (value["is_primary"] === 1) {
                        addressList?.map(i => i.is_primary = 0);
                    }
                }
                addressList?.push(value);
            }
            this.handleGeneralEdit('address', addressList);
        } else if (key == "agency_address") {
            let addressList = this.state?.representativeList?.agency_address_list?.length > 0 ? JSON.parse(JSON.stringify(this.state?.representativeList?.agency_address_list)) : [];
            addressList[0] = value
            this.handleGeneralEdit('agency_address_list', addressList)
        }
    }

    addRemoveObject(list, value) {
        let objectList = [...this.state?.representativeList?.[list]];
        objectList = objectList?.filter((i, index) => index != 0);
        let initObjectConfig = JSON.parse(JSON.stringify(initialObjectConfig));

        value.type = 'new'
        value.object_id = Date.now();
        let newObjectList = objectList.filter(item => item.is_delete === 0)
        if (newObjectList?.length === 0) {
            value["is_primary"] = 1
        }
        objectList = [initObjectConfig, ...objectList, value]

        this.handleGeneralEdit(list, objectList)
    }

    onChangeObject(list, id, key, value) {
        let objectList = [...this.state?.representativeList?.[list]];
        let objectIndex = id ? objectList?.findIndex(i => i.object_id == id) : 0;
        if (key == 'is_primary') {
            objectList?.map(i => i.is_primary = 0);
            objectList[objectIndex][key] = 1;
        } else if (key == 'is_delete') {
            objectList[objectIndex][key] = 1;
        } else {
            objectList[objectIndex][key] = value;
        }
        this.handleGeneralEdit(list, objectList)
    }

    getEditRepresentativeDetails = () => {
        this.setState({ fetchingRepDetails: true });
        CasterService.getData(Constants.CasterServiceBaseUrl + `/casterFeaturePerformerDealContact?project_deal_id=${this.props?.dealId}&performer_deal_representative_id=${this.props?.repId}&type=REPRESENTATIVE`, this.props.userContext?.active_tenant?.tenant_id)
            .then(response => {
                console.log("response", response)
                let representativeList = response?.data?.[0];
                let phoneInitObject = JSON.parse(JSON.stringify(initialObjectConfig));
                let initObjectConfig = JSON.parse(JSON.stringify(initialObjectConfig));
                if (representativeList?.phone?.length > 0) {
                    let formattedObj = representativeList?.phone?.map(i => {
                        return {
                            object_id: i?.performer_deal_representative_phone_id,
                            object_type: i?.phone_type,
                            value: i?.phone,
                            is_primary: i.is_primary,
                            is_delete: 0,
                            ext: i?.ext
                        }
                    })
                    representativeList.phone = [phoneInitObject, ...formattedObj]
                } else {
                    representativeList.phone = [];
                    representativeList.phone.push(phoneInitObject);
                }
                if (representativeList.email?.length > 0) {
                    let formattedObj = representativeList?.email?.map(i => {
                        return {
                            object_id: i.performer_deal_representative_email_id,
                            object_type: i.email_type,
                            value: i.email,
                            is_primary: i.is_primary,
                            is_delete: 0
                        }
                    })
                    representativeList.email = [initObjectConfig, ...formattedObj]
                } else {
                    representativeList.email.push(initObjectConfig);
                }
                if (representativeList.agency_address_list?.length > 0) {
                    let formattedObj = representativeList?.agency_address_list?.map(i => {
                        return {
                            address: i?.agency_address,
                            address_type: i.agency_address_type,
                            country: i.agency_country,
                            state: i.agency_state,
                            zip: i.agency_zip,
                            city: i.agency_city,
                            agency_id: i.agency_id
                        }
                    })
                    representativeList.agency_address_list = formattedObj
                }
                if (representativeList?.agency_phone?.length > 0) {
                    let formattedObj = representativeList?.agency_phone?.map(i => {
                        return {
                            object_id: i?.agency_location_phone_id,
                            object_type: i?.phone_type,
                            value: i?.phone,
                            is_primary: i.is_primary,
                            is_delete: 0,
                            ext: i?.ext
                        }
                    })
                    representativeList.agency_phone = formattedObj
                }
                let selectedRep = { value: representativeList?.agency_name, text: representativeList?.agency_name, id: representativeList?.agency_id }
                this.setState({
                    representativeList: representativeList,
                    fetchingRepDetails: false,
                    selectedRep: selectedRep
                }, () => {
                    CasterService.getData(Constants.CasterServiceBaseUrl + `/casterFeatureAgencyDetails?id=${selectedRep?.id}`, this.props.userContext?.active_tenant?.tenant_id)
                        .then(response => {
                            if (response.data.error) {
                                console.log("Agency Data error");
                            }
                            else {
                                let agencyDetails = response?.data?.[0]
                                let location = agencyDetails?.location_list
                                let locationOptions = location?.map(i => ({ value: i?.location_name, label: i?.location_name }))
                                this.setState({ locationOptions, currentLocDetails: location, location_agency_id: agencyDetails?.agency_id })
                                console.log("res", this.state.representativeList)
                            }
                        })
                });

            },
                (err) => {
                    console.log("Error in fetching Production Details:", err)
                })
    }

    handleGeneralEdit = (field, value) => {
        let newValue = value;
        if (typeof value === "boolean") {
            newValue = value ? 1 : 0;
        }
        this.setState(
            (prevState) => ({
                representativeList: {
                    ...prevState.representativeList,
                    [field]: newValue,
                },
                postInitiated: false,
            }),
            () => {
                if (field === 'location') {
                    let currentLocDetails = this.state.currentLocDetails
                    let addressItem = currentLocDetails?.find(item => item.location_name === value)
                    let agency_address_list = [{
                        address_type: addressItem?.address_type,
                        address: addressItem?.address,
                        country: addressItem?.country,
                        state: addressItem?.state,
                        zip: addressItem?.zip,
                        city: addressItem?.city,
                        agency_id: this.state.location_agency_id
                    }]
                    // let address = `${addressItem?.address ? addressItem?.address : ''}${addressItem?.city ? '-' + addressItem?.city : ''}${addressItem?.zip ? '-' + addressItem?.zip : ''}${addressItem?.state ? '-' + addressItem?.state : ''}${addressItem?.country ? '-' + addressItem?.country : ''}`
                    this.handleGeneralEdit('agency_address_list', agency_address_list)

                }
                // this.props.setModalList(this.state.representativeDetails);
                this.props?.fieldChanged(true, "change");
            }
        );
    };


    handleListEdit = (list, field, value, index) => {
        let newProductionList = this.state.newProductionList;
        let akaRadio = this.state.akaRadio
        switch (list) {
            case 'aliases':
                let akalist = newProductionList[list][index];
                if (field === "is_primary") {
                    newProductionList[list]?.map((item, aIndex) => {
                        if (aIndex != index) {
                            newProductionList[list][aIndex].is_primary = 0
                            newProductionList['akaRadio'] = 0
                        }
                        else {
                            newProductionList[list][aIndex].is_primary = value === 1 ? 1 : 0
                        }
                    })
                } else {
                    let aka_item = {
                        ...akalist,
                        [field]: value
                    };
                    newProductionList[list][index] = aka_item;
                }
                break;
            case 'production_companies':
                let prodList = newProductionList[list][index];
                let prod_item = {
                    ...prodList,
                    [field]: value
                };
                newProductionList[list][index] = prod_item;
                break;
        }

        this.setState({ newProductionList: newProductionList, postInitiated: false, formEdited: true, akaRadio });
        // this.props.fieldChanged(this.state.formEdited, "change");
    }


    addAdditionalField = (listName, index) => {
        this.setState({ formEdited: true })
        // this.props.fieldChanged(this.state.formEdited, "change");
        let newProductionList = this.state.newProductionList;
        switch (listName) {
            case 'aliases':
                if (newProductionList.aliases[index].alias_name) {
                    let akaArray = JSON.parse(JSON.stringify({ ...aliasList }));
                    let aka_list = [...newProductionList.aliases];
                    akaArray.index = this.state.akaIndex;
                    aka_list.push(akaArray);
                    newProductionList.aliases = aka_list;
                    this.setState({ akaIndex: this.state.akaIndex + 1, newProductionList: newProductionList, postInitiated: false });
                }
                break;
            case 'production_companies':
                if (newProductionList.production_companies[index].production_company) {
                    let initialProductionArray = JSON.parse(JSON.stringify({ ...companiesList }));
                    let production_list = [...newProductionList.production_companies];
                    initialProductionArray.index = this.state.productionIndex;
                    production_list.push(initialProductionArray);
                    newProductionList.production_companies = production_list;
                    this.setState({
                        productionIndex: this.state.productionIndex + 1,
                        newProductionList: newProductionList,
                        postInitiated: false
                    });
                }
                this.setState({ showFields: true });
                break;
        }
    }
    removeField = (listName, callbackItem) => {
        this.setState({
            formEdited: true,
            listName: listName, callbackItem: callbackItem, postInitiated: false
        })
        // this.props.fieldChanged(this.state.formEdited, "change");
        switch (listName) {
            case 'aliases':
                if (callbackItem.project_alias_id) {
                    this.showMessage(listName, callbackItem);
                } else {
                    this.deleteRow(listName, callbackItem);
                }
                break;

            case 'production_companies':
                if (callbackItem.project_production_company_id) {
                    this.showMessage(listName, callbackItem);
                } else {
                    this.deleteRow(listName, callbackItem);
                }
                break;
        }
    }
    showPopup(type, id, data = {}) {
        let htmlElement = document.getElementById(id);
        if (type == "agencyAddress") {
            this.setState({ setAgencyPopover: htmlElement })
        } else {
            this.setState({ setPopOver: htmlElement })
        }
        this.setState({ [`${type}Selected`]: { ...data }, }, () => {
            console.log("addressSelected", this.state.addressSelected)
            console.log("agencyAddressSelected", this.state.agencyAddressSelected)
        })
        switch (type) {
            case "agencyAddress":
                this.setState({ showAgencyAddressPopup: true })
                break;
            case "address":
                this.setState({ showAddressPopup: true })
                break;
            default:
                break;
        }
    }

    onClosePopup(type) {
        if (type == "agencyAddress") {
            this.setState({ setAgencyPopover: null })
        } else {
            this.setState({ setPopOver: null })
        }
        this.setState({ [`${type}Selected`]: {} })
        switch (type) {
            case "agencyAddress":
                this.setState({ showAgencyAddressPopup: false })
                break;
            case "address":
                this.setState({ showAddressPopup: false })
                break;
            default:
                break;
        }
    }


    onAddressSave(address) {
        console.log(address);
        this.onClosePopup('address')
        this.onAddressChange(this.state.addressSelected?.performer_deal_representative_address_id, 'address', address)
    }

    onAgencyAddressSave(address) {
        console.log(address);
        this.onClosePopup('agencyAddress')
        this.onAddressChange(this.state.addressSelected?.agency_id, 'agency_address', address)
    }

    handleSelectedRep = (newValue) => {
        console.log("newValue in handleSelectedRep",newValue);
        if (newValue) {
            if (newValue?.optionType === "new") {
                this.setState({ showAgencyPopup: true,showNewAgencyPopup: { agency_id: newValue?.value, agency_name: newValue?.value },locationOptions:[] })
            }
            let selectedRepData = this.state.company_options?.find(item => item.value === newValue.value);
            this.setState({ selectedRep: newValue, selectedRepData: selectedRepData,loadingLocation:true }, () => {
                this.handleGeneralEdit('agency_name', newValue?.text)
                this.handleGeneralEdit('agency_id', newValue?.id)
                this.getAgencyAddressLocation(newValue?.id)
            })
        }
        else {
            this.setState({ selectedRep: null,loadingLocation:false,locationOptions:[] })
        }
    }

    getAgencyAddressLocation = (id) => {
        CasterService.getData(Constants.CasterServiceBaseUrl + `/casterFeatureAgencyDetails?id=${id}`, this.props.userContext?.active_tenant?.tenant_id)
            .then(response => {
                if (response.data.error) {
                    console.log("Agency Data error");
                }
                else {
                    let agencyDetails = response?.data?.[0]
                    let location = agencyDetails?.location_list
                    let locationOptions = location?.map(i => ({ value: i?.location_name, label: i?.location_name }))
                    this.setState({ locationOptions, currentLocDetails: location, loadingLocation:false })
                    let findPrimaryLocation = location?.find(item => item.is_primary === 1)
                    if (findPrimaryLocation) {
                        this.handleGeneralEdit('location', findPrimaryLocation?.location_name)
                        let agency_address_list = [{
                            address_type: findPrimaryLocation?.address_type,
                            address: findPrimaryLocation?.address,
                            country: findPrimaryLocation?.country,
                            state: findPrimaryLocation?.state,
                            zip: findPrimaryLocation?.zip,
                            city: findPrimaryLocation?.city,
                            agency_id: agencyDetails?.agency_id
                        }]
                        // let address = `${findPrimaryLocation?.address ? findPrimaryLocation?.address : ''}${findPrimaryLocation?.city ? '-' + findPrimaryLocation?.city : ''}${findPrimaryLocation?.zip ? '-' + findPrimaryLocation?.zip : ''}${findPrimaryLocation?.state ? '-' + findPrimaryLocation?.state : ''}${findPrimaryLocation?.country ? '-' + findPrimaryLocation?.country : ''}`
                        this.handleGeneralEdit('agency_address_list', agency_address_list)
                    } else {
                        this.handleGeneralEdit('location', location[0]?.location_name)
                        let default_agency_address_list = [{
                            address_type: location[0]?.address_type,
                            address: location[0]?.address,
                            country: location[0]?.country,
                            state: location[0]?.state,
                            zip: location[0]?.zip,
                            city: location[0]?.city,
                            agency_id: agencyDetails?.agency_id
                        }]
                        // let address = `${location[0]?.address ? location[0]?.address : ''}${location[0]?.city ? '-' + location[0]?.city : ''}${location[0]?.zip ? '-' + location[0]?.zip : ''}${location[0]?.state ? '-' + location[0]?.state : ''}${location[0]?.country ? '-' + location[0]?.country : ''}`
                        this.handleGeneralEdit('agency_address_list', default_agency_address_list)
                    }

                    console.log("agencyDetails", agencyDetails)
                }
            },
                (err) => {
                    // this.setState({ isFetchingAgencyDetails: false, agencyDetails: [] });
                    console.log("Error in fetching Agency Details:", err)
                })
    }

    // onAddressChange(id, key, value) {
    //     let addressList = this.state?.representativeList?.address?.length > 0 ? JSON.parse(JSON.stringify(this.state?.representativeList?.address)) : [];
    //     let addressIndex = addressList?.findIndex(i => i.performer_deal_representative_address_id == id);
    //     if (key == 'is_primary') {
    //         addressList?.map(i => i.is_primary = 0);
    //         addressList[addressIndex][key] = 1;
    //     } else if (key == 'is_delete') {
    //         addressList[addressIndex][key] = 1;
    //     } else if (id) {
    //         addressList[addressIndex] = value;
    //     } else {
    //         value.performer_deal_representative_address_id = Date.now();
    //         value.type = "new"
    //         let newAddressList = addressList.filter(item => !item.is_delete)
    //         if (newAddressList?.length === 0) {
    //             value["is_primary"] = 1
    //         } else {
    //             if (value["is_primary"] === 1) {
    //                 addressList?.map(i => i.is_primary = 0);
    //             }
    //         }
    //         addressList?.push(value);
    //     }
    //     this.handleGeneralEdit('address', addressList);
    // }


    // showMessage = () => {
    //     this.setState({ isOpen: true });
    // }

    deleteRow = (listName, callbackItem) => {
        let newProductionList = this.state.newProductionList;
        switch (listName) {
            case 'aliases':
                let aka_list = newProductionList[listName].filter(item =>
                    (item.project_alias_id !== callbackItem.project_alias_id) || (item.index !== callbackItem.index)
                );
                if (aka_list.length === 0) {
                    newProductionList[listName] = [{ ...aliasList }];
                } else {
                    newProductionList[listName] = aka_list;
                }
                break;
            case 'production_companies':
                let list = newProductionList[listName].filter(item =>
                    (item.project_production_company_id !== callbackItem.project_production_company_id) || (item.index !== callbackItem.index)
                );
                if (list.length === 0) {
                    newProductionList[listName] = [{ ...companiesList }];
                } else {
                    newProductionList[listName] = list;
                }
                break;
        }
        this.setState({
            newProductionList: newProductionList, listName: null, callbackItem: null, postInitiated: false, isOpen: false
        });
    }

    // setProductionDetailsUniqueEntries = (postJson) => {
    //     let checkPostJson = {...postJson}
    //     checkPostJson.aka = postJson.aka.filter((tag, index, array) =>
    //         (array.findIndex(t => t.aka == tag.aka) == index) && tag.aka);


    //         checkPostJson.production_companies_list = postJson.production_companies_list.filter((tag, index, array) =>
    //         (array.findIndex(t => t.production_company_id == tag.production_company_id
    //             && t.union_id == tag.union_id && t.production_company_signators_id == tag.production_company_signators_id) == index) &&
    //         tag.union_id && tag.production_company_id && tag.production_company_signators_id);
    //     return checkPostJson
    // }



    getFullAddress(item) {
        let { address, city, state, country, zip } = item;
        if (state?.includes('-')) {
            let splitArray = state?.split("-")
            state = splitArray[0]
        }
        return <div>
            {address ? <p> {address} </p> : ''}
            {<p>{`${city ? city : ''} ${state ? city ? ', ' + state : state : ''} ${zip ? state ? ' ' + zip : city ? ', ' + zip : zip : ''}`}</p>}
            {country ? (country !== "United States" && country !== "USA" && country !== "US") ? <p> {country} </p> : '' : ''}
        </div>
        // return `${address ? address + '\n' : ''}  ${city ? city + ',' : ''} ${state ? state + ',' : ''} ${zip ? zip + '\n' : ''} ${zip ? country ? country : '' :  country ?  '\n' + country  : ''}`;
    }


    handleSubmit = () => {
        this.setState({ postingAgency: true })
        let representativeList = JSON.parse(JSON.stringify(this.state.representativeList));
        representativeList.email = representativeList?.email?.slice(1) || [];
        representativeList.phone = representativeList?.phone?.slice(1) || [];
        if (representativeList?.email?.length > 0) {
            representativeList.email = representativeList.email?.reduce((acc, i) => {
                i.object_id = i?.type == 'new' ? null : i?.object_id
                if (i?.object_id || (!i?.object_id && !i?.is_delete))
                    acc = [...acc, {
                        performer_deal_representative_email_id: i?.object_id,
                        email_type: i?.object_type,
                        email: i?.value,
                        is_delete: i?.is_delete || 0,
                        is_primary: i?.is_primary

                    }]
                return acc;
            }, [])
        }
        if (representativeList.phone?.length > 0) {
            representativeList.phone = representativeList.phone?.reduce((acc, i) => {
                i.object_id = i?.type == 'new' ? null : i?.object_id
                if (i?.object_id || (!i?.object_id && !i?.is_delete))
                    acc = [...acc, {
                        performer_deal_representative_phone_id: i?.object_id,
                        phone_type: i?.object_type,
                        phone: i?.value,
                        is_delete: i?.is_delete || 0,
                        is_primary: i?.is_primary,
                        ext: i?.ext ? parseInt(i?.ext) : null

                    }]
                return acc;
            }, [])
        }
        if (representativeList.agency_address_list?.length > 0) {
            representativeList.agency_address_list = representativeList.agency_address_list?.map(item => {
                return {
                    agency_address_type: item?.address_type,
                    agency_address: item?.address,
                    agency_country: item?.country,
                    agency_state: item?.state,
                    agency_zip: item?.zip,
                    agency_city: item?.city,
                    agency_id: item?.agency_id
                }
            })
        }
        // representativeList.email = representativeList?.email?.filter(item => item.value !== null)
        // representativeList.email = representativeList?.email?.map(item => {
        //     return {
        //         performer_deal_representative_email_id: item.type === 'new' ? null : item.object_id,
        //         email_type: item.object_type,
        //         email: item.value,
        //         is_primary: item.is_primary,
        //         is_delete: item.is_delete
        //     }
        // })
        // representativeList.phone = representativeList?.phone?.filter(item => item.value !== null)
        // representativeList.phone = representativeList?.phone?.map(item => {
        //     return {
        //         performer_deal_representative_phone_id: item.type === 'new' ? null : item.object_id,
        //         phone_type: item.object_type,
        //         phone: item.value,
        //         is_primary: item.is_primary,
        //         is_delete: item.is_delete,
        //         ext: item.ext
        //     }
        // })
        representativeList.address = representativeList?.address?.map(item => {
            return {
                performer_deal_representative_address_id: item.type === 'new' ? null : item.performer_deal_representative_address_id,
                address_type: item.address_type,
                address: item.address,
                city: item.city,
                zip: item.zip,
                state: item.state,
                country: item.country,
                is_primary: item.is_primary ? 1 : 0,
                is_delete: item.is_delete ? item.is_delete : 0
            }
        })
        // let representativeDetailsPostJson = this.checkIfPrimaryIsChecked(this.setRepresentativeUniqueEntries());
        this.setState({ postInitiated: true }, () => {
            // if (this.validateErrorFlags(representativeList)) {
            //this.editPhoneNumbers(talentDetailsPostJson);
            this.postRepresentativeDetails(representativeList)
            // } else {
            //     console.log("Submit Fail")
            // }
        })
    }

    postRepresentativeDetails = (representativeDetails) => {
        this.setState({ isPosting: true })
        CasterService.postDataParams(Constants.CasterServiceBaseUrl + '/casterFeaturePerformerDealContact', representativeDetails, this.props.userContext?.active_tenant?.tenant_id, 1)
            .then((response) => {
                if (response.data.error) {
                    //Add your error message to alert box from response.data.error
                    this.props.fieldChanged(true, "fail");
                    this.setState({ isPosting: false })
                } else {
                    // if (this.props.isProfileChanged) {
                    //     this.props.uploadProfile(response.data[0].representative_id);
                    // }
                    // this.props.fieldChanged(false, "error");
                    this.props?.toggleRefreshPage('ContactsAndPaymentsTabRep');
                    this.props.fieldChanged(true, "success");
                    this.setState({ isPosting: false })
                    setTimeout(() => this.props?.onClose(), 1800)

                    // if (this.props?.handleTalentCreation) {
                    //     let newTalent = {
                    //         'id': response?.data[0]?.talent_id
                    //     }
                    //     this.props?.handleTalentCreation(newTalent)
                    // }
                }
            }, (err) => {
                this.props.fieldChanged(true, "fail");
                this.setState({ isPosting: false })
                console.log("Post Edit Rep details error: " + err);
            });
    }

    validateFields = (postJson) => {
        let submit = (
            (postJson.project_name && postJson.studio_name))
        //         && postJson.sag_id &&
        //         postJson.name && postJson.distribution_id && postJson.start_date && postJson.wrap_date) &&
        //     (postJson.gl_code.length <= sizeLimits.nameCharacterLimit) &&
        //     (postJson.sag_id.length <= sizeLimits.nameCharacterLimit) &&
        //     (postJson.name.length <= sizeLimits.addressCharacterLimit) &&
        //     (postJson.aka.length === 0 ? true : postJson.aka.every(aka =>
        //         (aka.aka.length <= sizeLimits.nameCharacterLimit))) &&
        //     (new Date(postJson.start_date) < new Date(postJson.wrap_date))
        // );
        return submit;
    }

    showLocationPopover = (id, isEdit) => {
        let editLocationConfig = JSON.parse(JSON.stringify({ ...locationConfig }))
        if (isEdit) {
            editLocationConfig.agency_location_id = null,
            editLocationConfig.location_name = this.state.representativeList?.location,
            editLocationConfig.address_type = this.state.representativeList?.agency_address_list[0]?.address_type,
            editLocationConfig.city = this.state.representativeList?.agency_address_list[0]?.city,
            editLocationConfig.zip = this.state.representativeList?.agency_address_list[0]?.zip,
            editLocationConfig.actual_address = this.state.representativeList?.agency_address_list[0]?.address,
            editLocationConfig.state =  {"label": this.state.representativeList?.agency_address_list[0]?.state, "text": this.state.representativeList?.agency_address_list[0]?.state }
            editLocationConfig.phone_list = this.state.representativeList?.agency_phone
        }
        let htmlElement = document.getElementById(id);
        this.setState({ openLocationPopover: true, setLocationPopover: htmlElement, isLocationEdit: isEdit, locationConfig: isEdit === true ? editLocationConfig : JSON.parse(JSON.stringify({ ...locationConfig })) })
    }

    closeLocationPopover = () => {
        this.setState({ openLocationPopover: false, isEdit: null })
    }

    onLocationSave = (locationDets) => {
        let representativeList = this.state.representativeList
        let locationOptions = this.state.locationOptions
        locationDets.address = locationDets.actual_address
        representativeList.location = locationDets.location_name
        locationOptions.push({ label: locationDets?.location_name, value: locationDets?.location_name });
        representativeList.agency_address_list = []
        representativeList.agency_address_list?.push(locationDets)
        representativeList.add_location = this.state.isEdit ? 0 : 1
        this.setState({ representativeList: representativeList , locationOptions: locationOptions })
    }
    setNewAgency(agencyObj,agencyIdNew=null) {
        console.log("agencyObj", agencyObj);
        console.log("selectedRep", this.state.selectedRep);
        console.log("representativeList", this.state.representativeList);
        this.setState(
            (prevState) => ({
                selectedRep: {
                    ...prevState.selectedRep,
                    ["value"]: agencyIdNew||null,
                    ["text"]: agencyObj?.text ? agencyObj?.text : agencyObj?.agency_name ? agencyObj?.agency_name:null,
                },
                postInitiated: false,
                loadingLocation:true,
            }), () => this.getAgencyAddressLocation(agencyIdNew?agencyIdNew:null))
        this.setState(
            (prevState) => ({
                representativeList: {
                    ...prevState.representativeList,
                    ["agency_id"]: agencyIdNew||null,
                    ["agency_name"]: agencyObj?.text ? agencyObj?.text : agencyObj?.agency_name ? agencyObj?.agency_name:null,

                },
                postInitiated: false,
                loadingLocation:true,
            }), () => this.getAgencyAddressLocation(agencyIdNew?agencyIdNew:null))

    }
    handleCloseAgencyModal=()=>{
        this.setState({ showAgencyPopup: null },()=>{
            //this.getRepresentativeDetails();
        })
    }
    render() {
        // const { classes } = this.props;
        // let isLoading = this.state.isProductionDetails || this.state.isFetchingFeatureTypes || this.state.isFetchingDistributionTypes
        //     || this.state.isFetchingIntendedMarket
        return (
            // <div>
            //     <span>new production</span>
            // </div>
            <div>
                <MDBContainer className="editRepresentative">
                    {this.state?.fetchingRepDetails ?
                        <div className="ContentLoader">
                            <CircularProgress color="inherit" />
                        </div> :
                        <>
                            <MDBRow className="titleRep">
                                <span className="titleRepSpan">{this.state.representativeList?.representative} (AKA {this.state.representativeList?.selected_alias_name})</span>
                            </MDBRow>
                            <MDBRow>
                                <MDBCol md={6}>
                                    <SelectField
                                        value={this.state.representativeList?.representative_occupation || ""}
                                        label={"Occupation"}
                                        options={this.state.occupationOptions}
                                        onChange={(e) => {
                                            this.handleGeneralEdit('representative_occupation', e.target.value)
                                        }}
                                    />
                                </MDBCol>
                            </MDBRow>
                            <MDBRow className="companyRow">
                                <MDBCol md={4} className="company">
                                    {/* <SearchSelectField
                                        id={"alias"}
                                        label={"Company"}
                                        options={this.state?.company_options || []}
                                        width={'100%'}
                                        detail_selected={this.state.selectedRep}
                                        valueSelected={(e, value) => this.handleSelectedRep(value)}
                                        searchText={(e) => this.getRepresentativesList("Agency", e.target.value)}
                                        multiple={false}
                                        searchSelect={true}
                                        placeholder={"- Search By Alias or Company to Add"}
                                    /> */}
                                    <BasicLabel text={"Company"} />
                                    <SearchFieldWithAddValue
                                        key={"company"}
                                        id={"company"}
                                        placeholder={"- Search By Alias or Company to Add"}
                                        searchSelect={true}
                                        detail_selected={this.state.selectedRep}
                                        options={this.state?.company_options || []}
                                        //onChange={(e, value) => this.props?.handleSelectedCrew(e, value, item, index)}
                                        onChange={(e, value) => this.handleSelectedRep(value)}
                                        searchText={ev => {
                                            if (ev.target.value !== "" && ev.target.value !== null) {
                                                this.getRepresentativesList("Agency", ev.target.value)
                                            }
                                            // else {
                                            //     this.setState({ isCrewFetch: false })
                                            // }
                                        }}
                                        // label={index == 0 ? "Assistants": ""}
                                        multiple={false}
                                        //showAsLabel={!this.props.isEditing}
                                        newLabel={'Company'}
                                    />
                                </MDBCol>
                                <MDBCol md={4}>
                                    {this.state?.loadingLocation ?
                                            <CircularProgress className="LocationLoader" />
                                            :
                                        <SelectField
                                            value={this.state.representativeList?.location || ""}
                                            label={"Location"}
                                            options={this.state.locationOptions}
                                            onChange={(e) => {
                                                this.handleGeneralEdit('location', e.target.value)
                                            }}
                                        />
                                    }
                                </MDBCol>
                                {/* <MDBCol md={4}>
                                    <BasicLabel text={"Address"} />
                                    {this.state.representativeList?.agency_address_list?.map((item, index) => (
                                        <>
                                            <MDBRow className='addressRow'>
                                                <MDBCol md={4}>
                                                    <div className='spanWithLeftBorder'>{item?.address_type}</div>
                                                </MDBCol>
                                                <MDBCol md={6}>
                                                    <div className={`spanWithLeftBorder ${item?.is_primary == 1 ? 'primary' : ''}`}>{this.getFullAddress(item)}</div>
                                                </MDBCol>
                                                <MDBCol md={2} className='noPadding iconContainer'> */}
                                                    {/* {this.props?.isEditing ? ( */}
                                                    {/* <>
                                                        <MDBIcon icon="pen" onClick={() => this.showPopup('agencyAddress', 'agencyAddressEditIcon', item)} id='agencyAddressEditIcon' /> */}
                                                        {/* <input
                                                            name='addressPrimary'
                                                            id={`radioAddress_${item?.performer_deal_representative_address_id}`}
                                                            size="small"
                                                            type="radio"
                                                            value={item?.performer_deal_representative_address_id}
                                                            defaultChecked={item?.is_primary == 1 ? true : false}
                                                            onChange={(e) => this.onAddressChange(item?.performer_deal_representative_address_id, 'is_primary', e)}
                                                        />
                                                        <MDBIcon icon="times" onClick={() => this.onAddressChange(item?.performer_deal_representative_address_id, 'is_delete', 1)} /> */}
                                                    {/* </> */}
                                                    {/* )  */}
                                                    {/* : <div><span className={(!this.props?.isEditing && item?.is_primary === 1) ? "primaryStarIcon" : ""}></span></div>} */}
                                                {/* </MDBCol>
                                            </MDBRow>
                                        </>
                                    ))} */}
                                    {/* <div className="addressSpan">{this.state.representativeList?.agency_address}</div> */}
                                {/* </MDBCol> */}
                            </MDBRow>
                            <MDBRow className="contactSection">
                                <MDBCol md={5}>
                                    <span className='addLabel location-pop' id="newLocationBtn" onClick={() => this.showLocationPopover('newLocationBtn', false)}>
                                        <MDBIcon icon="plus-circle" />
                                        Add Address
                                    </span>
                                    {this.state.representativeList?.agency_address_list?.map((item, index) => (
                                        <>
                                            <MDBRow className='addressRow'>
                                                <MDBCol md={4}>
                                                    <div className='spanWithLeftBorder'>OFFICE</div>
                                                </MDBCol>
                                                <MDBCol md={6}>
                                                    <div className={`spanWithLeftBorder ${item?.is_primary == 1 ? 'primary' : ''}`}>{this.getFullAddress(item)}</div>
                                                </MDBCol>
                                                <MDBCol md={2} className='noPadding iconContainer'>
                                                    <>
                                                        <MDBIcon icon="pen" onClick={() => this.showLocationPopover('agencyAddressEditIcon', true)} id='agencyAddressEditIcon' />
                                                    </>
                                                </MDBCol>
                                            </MDBRow>
                                        </>
                                    ))}
                                </MDBCol>
                                <>
                                {/* <MDBCol md={5}>
                                    <span className='addLabel' id="newAddressBtn"
                                        onClick={() => this.showPopup('address', 'newAddressBtn', 'new')}
                                    >
                                        <MDBIcon icon="plus-circle" />
                                        Add Address
                                    </span>
                                    {this.state.representativeList?.address?.filter(i => i?.is_delete == 0 || !i?.is_delete)?.map((item, index) => (
                                        <>
                                            <MDBRow className='addressRow'>
                                                <MDBCol md={3}>
                                                    <div className='spanWithLeftBorder'>{item?.address_type}</div>
                                                </MDBCol>
                                                <MDBCol md={6}>
                                                    <div className={`spanWithLeftBorder ${item?.is_primary == 1 ? 'primary' : ''}`}>{this.getFullAddress(item)}</div>
                                                </MDBCol>
                                                <MDBCol md={3} className='noPadding iconContainer'>
                                                    <>
                                                        <MDBIcon icon="pen" onClick={() => this.showPopup('address', 'newAddressBtn', item)} />
                                                        <input
                                                            name='addressPrimary'
                                                            id={`radioAddress_${item?.performer_deal_representative_address_id}`}
                                                            size="small"
                                                            type="radio"
                                                            value={item?.performer_deal_representative_address_id}
                                                            defaultChecked={item?.is_primary == 1 ? true : false}
                                                            onChange={(e) => this.onAddressChange(item?.performer_deal_representative_address_id, 'is_primary', e)}
                                                        />
                                                        <MDBIcon icon="times" onClick={() => this.onAddressChange(item?.performer_deal_representative_address_id, 'is_delete', 1)} />
                                                    </>
                                                </MDBCol>
                                            </MDBRow>
                                        </>
                                    ))}
                                </MDBCol> */}
                                </>
                                <MDBCol md={4}>
                                    {this.state.representativeList?.phone?.filter(i => i?.is_delete == "0")?.map((item, index) => (
                                        <PhoneComponent
                                            radioGroupName='representativePhoneRadio'
                                            // showAsLabel={!this.props?.isEditing}
                                            isEditing={true}
                                            objectItem={item}
                                            label={index == 0 ? "Phone" : ""}
                                            ext={true}
                                            inValidInput={"Please enter valid phone"}
                                            addCallback={(obj) => this.addRemoveObject('phone', obj)}
                                            removeCallback={(id, key, value) => this.onChangeObject('phone', id, key, value)}
                                            editCallback={(id, key, value) => this.onChangeObject('phone', id, key, value)}
                                            selectOptions={this.state?.phoneOptions}
                                            showAdd={index == 0 ? true : false}
                                            showRemove={index != 0 ? true : false}
                                            showRadio={index != 0 ? true : false}
                                        // inputValidateMethod={validateAllPhone}
                                        />
                                    ))}
                                </MDBCol>
                                <MDBCol md={3}>
                                    {this.state?.representativeList?.email?.filter(i => i?.is_delete == 0)?.map((emailItem, index) => (
                                        <InputSelectRadio
                                            radioGroupName='representativeEmailRadio'
                                            inputPlaceholder="Enter Email"
                                            // showAsLabel={!this.props?.isEditing}
                                            placeHolderText="Email Type"
                                            isEditing={true}
                                            label={index == 0 ? "Email" : ""}
                                            objectItem={emailItem}
                                            addCallback={(emailObj) => this.addRemoveObject('email', emailObj)}
                                            removeCallback={(id, key, value) => this.onChangeObject('email', id, key, value)}
                                            editCallback={(id, key, value) => this.onChangeObject('email', id, key, value)}
                                            selectOptions={this.state?.emailOptions}
                                            showAdd={index == 0 ? true : false}
                                            showRemove={index != 0 ? true : false}
                                            showRadio={index != 0 ? true : false}
                                            inputValidateMethod={validateEmail}
                                            hideActions={false}
                                        />
                                    ))}
                                </MDBCol>
                            </MDBRow>
                            <MDBRow className='justify-content-end topMargin'>
                                <MDBCol md={2} className="repSubmit">
                                    <BasicButton
                                        // text={"Add"}
                                        text={this.state.isPosting ? <CircularProgress color="inherit" size={20} />
                                            : "Save"}
                                        icon="save"
                                        type="inline"
                                        onClick={() => this.handleSubmit()}
                                    />
                                </MDBCol>
                                <MDBCol md={2}>
                                    <BasicButton
                                        variant="outlined"
                                        text="Cancel"
                                        icon="times"
                                        onClick={() => this.props?.onClose()}
                                    />
                                </MDBCol>
                            </MDBRow>
                        </>}
                </MDBContainer>
                {
                    this.state?.openLocationPopover && 
                    <LocationComponent 
                        openLocationPopOver={this.state?.openLocationPopover}
                        setPopOver={this.state.setLocationPopover}
                        isEditing={true}
                        popOverClose={() => this.closeLocationPopover()}
                        locationConfig={this.state.locationConfig}
                        onLocationSave={this.onLocationSave?.bind(this)}
                        fromRep={true}
                    />
                }
                {this.state?.showAddressPopup &&
                    <AddressComponent
                        open={this.state?.showAddressPopup}
                        setPopOver={this.state?.setPopOver}
                        handleClose={this.onClosePopup.bind(this, 'address')}
                        onSave={(address) => { this.onAddressSave(address) }}
                        mode={this.state.addressSelected?.performer_deal_representative_address_id ? 'edit' : 'new'}
                        id={this.state.addressSelected?.performer_deal_representative_address_id || null}
                        selectedAddress={this.state?.addressSelected}
                    />
                }
                {this.state?.showAgencyAddressPopup &&
                    <AddressComponent
                        open={this.state?.showAgencyAddressPopup}
                        setPopOver={this.state?.setAgencyPopover}
                        handleClose={this.onClosePopup.bind(this, 'agencyAddress')}
                        onSave={(address) => { this.onAgencyAddressSave(address) }}
                        mode={'edit'}
                        id={this.state.agencyAddressSelected?.agency_id || null}
                        selectedAddress={this.state?.agencyAddressSelected}
                    //className={"agencyAddressPopover"}
                    />
                }

                {/* {this.state?.openAgencyPopOver && <NewAgency
                    id={'newAgencyFromadvancesea'}
                    open={this.state.openAgencyPopOver}
                    setPopOver={this.state.openAgencyPopOver}
                    handleClose={() => this.setState({ openAgencyPopOver: null })}
                    handleCloseAgencyPopOver={() => this.setState({ openAgencyPopOver: null })}
                    agencySelected={this.state?.selectedRep}
                    setObjectSelected={(type, value) => { this.setState({ showAgencyPopup: { agency_id: value?.value, agency_name: value?.text } }) }}
                    setNewObjectSelected={(type, value) => { this.setState({ showAgencyPopup: null, openAgencyPopOver: null }) }}
                    setNewAgency={this.setNewAgency.bind(this)}
                />} */}
                {/* {
                    this.state.showAgencyPopup &&
                    <ModalComponent
                        id={this.state.showAgencyPopup?.agency_id}
                        agencyId={{ value: this.state.showAgencyPopup?.agency_id }}
                        open={this.state.showAgencyPopup || false}
                        handleClose={this.handleCloseAgencyModal.bind(this)}
                        tabList={agencyNavList}
                        headerName={this.state?.showAgencyPopup?.agency_name}
                        headerText={"Agency"}
                        mode="edit"
                        isProfileDiv={true}
                        addButtonText={"New Agency"}
                        objectType={"AGENCY"}
                        akas={this.state.showAgencyPopup?.aka || null}
                        navFuns={this.functions}
                        dataUrl='casterFeatureAgencyDetails'
                        setNewAgency={this.setNewAgency.bind(this)} />
                } */}
                {
                    this.state.showAgencyPopup &&
                    <ModalComponent
                        id={this.state.showNewAgencyPopup?.agency_id}
                        agencyId={{ value: this.state.showNewAgencyPopup?.agency_id }}
                        open={this.state.showNewAgencyPopup || false}
                        handleClose={this.handleCloseAgencyModal.bind(this)}
                        tabList={agencyNavList}
                        headerName={this.state?.showNewAgencyPopup?.agency_name}
                        headerText={"Agency"}
                        mode="new"
                        isProfileDiv={true}
                        addButtonText={"New Agency"}
                        objectType={"AGENCY"}
                        akas={this.state.showNewAgencyPopup?.aka || null}
                        navFuns={this.functions}
                        dataUrl='casterFeatureAgencyDetails'
                        setNewAgency={this.setNewAgency.bind(this)} />
                }
            </div>
        );
    }
}

export default withUserContext(EditRepresentative);
